class Intake {

    constructor(
        index,
        dose,
        protocolDose,
        doctorDose,
        doseType,
        volume,
        observationTime,
        unit
    ) {
        this.index = index,
        this.dose = `${dose} ${unit}`;
        this.protocolDose = protocolDose;
        this.doctorDose = `${doctorDose} ${unit}`;
        this.doseType = doseType;
        this.volume = (volume == null || volume.length == 0) ? "-" : volume;
        this.observationTime = `${observationTime} minutos`;
    }

}

module.exports = Intake;
