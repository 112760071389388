import centerDetailTemplate from "./center-detail.template";
import centerDetailController from "./center-detail.controller";

var centerDetailStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-app.center-detail",
        url: "/center/:id",
        template: centerDetailTemplate,
        controller: centerDetailController,
    });
};

centerDetailStates.$inject = ["$stateProvider"];

export default centerDetailStates;
