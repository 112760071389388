import angular from "angular";

const PROTOCOL_EDITOR_MODULE = "protocolEditorModule";

import { PROTOCOL_EDITOR_SERVICE, ProtocolEditorService } from "./protocolEditor.service";

angular.module(PROTOCOL_EDITOR_MODULE, [])
    .factory(PROTOCOL_EDITOR_SERVICE, ProtocolEditorService);

export { PROTOCOL_EDITOR_MODULE, PROTOCOL_EDITOR_SERVICE };
