const HOSPITALARY_DOSE_SERVICE = "hospitalaryDoseService";

const hospitalaryDoseService = function () {

    return {
    };

};

hospitalaryDoseService.$inject = [];

export { HOSPITALARY_DOSE_SERVICE, hospitalaryDoseService };
