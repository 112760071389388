import comparativeEditIntakeTemplate from "./comparative-edit-intake.template";
import comparativeEditIntakeController from "./comparative-edit-intake.controller";

const COMPARATIVE_EDIT_INTAKE_COMPONENT = "comparativeEditIntake";

const comparativeEditIntakeComponent = {
    template: comparativeEditIntakeTemplate,
    controller: comparativeEditIntakeController,
    bindings: {
        currentTreatment: "<",
        intake: "<",
        dayToEdit: "<",
        patient: "<",
        onPatientRefreshNeeded: "&",
    }
};

export { COMPARATIVE_EDIT_INTAKE_COMPONENT, comparativeEditIntakeComponent };
