const LOGGER_SERVICE = "logger";

function LoggerService($log) {
    var loggerService = {};

    loggerService.log = function(...args){
        $log.log(generateLog("LOG", args));
    };

    loggerService.info = function(...args){
        $log.info(generateLog("INFO", args));
    };

    loggerService.warn = function(...args){
        $log.warn(generateLog("WARN", args));
    };

    loggerService.error = function(...args){
        $log.error(generateLog("ERROR", args));
    };

    loggerService.debug = function(...args){
        $log.debug(generateLog("DEBUG", args));
    };

    function generateLog(type, args) {
        return `(${type}) ${getPrefix()} -> [${args.join("][")}]`;
    }

    function getPrefix(){
        let t = new Date();
        return `${t.toLocaleDateString()} - ${t.toLocaleTimeString()}:${t.getMilliseconds()}`;
    }

    return loggerService;
}

LoggerService.$inject = ["$log"];

export { LOGGER_SERVICE, LoggerService };
