import "angular-ui-bootstrap";

class ProtocolsEditorController {
    constructor() {
        this.borde = {
            "border-style": "none"
        };

        this.control = {
            allergen: false,
            type: false,
            observationTime: false,
            selectedUnit: false,
        };
        this.newProtocol = {
            allergen: "",
            type: "",
            controlType: "none",
            observationTime: 0,
            selectedUnit: {},
            weeks: []
        };

        this.selectedDoseUnit;
        this.units = ["mg", "ml", "unidad"];

        this.style = {
            "has-error": true,
            "has-feedback": true
        };

        this.allergens = [];

        this.$onChanges();
    }

    $onChanges() {
        this.allergens = this.protocols ? this.filterUniqueques(this.protocols) : [];

        if (this.allergens.length > 0) {
            this.allergens = this.allergens.sort((a, b) => {
                return a.allergen.localeCompare(b.allergen);
            });
        }
    }

    filterUniqueques(array) {
        var hash = {};
        let uniqueques = array.filter((current) => {
            let exists = !hash[current.allergen] || false;
            hash[current.allergen] = true;
            return exists;
        });
        return uniqueques;
    }

    validateHead() {
        this.newProtocol.controlType = this.newProtocol.type === "" ? "has-error" : "has-success";
    }
}

ProtocolsEditorController.$inject = [];

export default ProtocolsEditorController;
