export default {
    chartTypes:[
        {
            text: "Tarta",
            id: "pie"
        },
        {
            text: "Barras",
            id: "bar"
        },
    ],
    groupOptions: [
        {
            id: "allergen",
            text: "Alérgeno",
        },
        {
            id: "protocol",
            text: "Protocolo",
        },
        {
            id: "doctor",
            text: "Doctor",
        },
        {
            id: "gender",
            text: "Sexo",
        },
    ],    
    chartOptions: {
        legend: {
            display: true,
            position: "right",
            fullWidth: true,
            label: {
                boxWidth: 80,
                fontSize: 16,
                padding: 20,
            }
        }
    },
}