import "angular-ui-bootstrap";
import moment from "moment";

import timmerTemplate from "./timmer-modal.template";
import doseTimeTemplate from "./dose-time.template";
import doseTemplate from "./dose-modal.template";
import reactionsEditorTemplate from "./reactions-editor-modal.template";
import constantsTemplate from "./constants-modal.template";
import medicinesTable from "./medicines-table-modal.template";
import clipboardMessage from "./clipboard-modal.template";
import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { UTILS_DATE_SERVICE } from "services/utils";

const INTAKE_MODAL_SERVICE = "intakeModalService";

var IntakeModalService = function ($uibModal, $state, dateUtils, reactionsService) {
    var intakeModalService = {};

    intakeModalService.choseDose = function (callback) {
        let handled = false;
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: doseTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    //TODO get dosesList
                    $scope.dosesList = ["0.2 ml", "2 ml"];
                };
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    console.log($scope.dose);
                    callback(true, $scope.dose);
                    //callback(true);
                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) { callback(false); }},
            () => { if (!handled) { callback(false); }}
        );
    };

    intakeModalService.setTimmer = function (callback) {
        let handled = false;
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: timmerTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    $scope.timmer = dateUtils.getCurrentDate();
                    //$scope.timmer.setHours(0);
                    //$scope.timmer.setMinutes(0);
                };

                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true, $scope.timmer);
                    //callback(true);
                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) { callback(false); }},
            () => { if (!handled) { callback(false); }}
        );
    };

    intakeModalService.setDoseTime = function (callback) {
        let handled = false;
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: doseTimeTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    $scope.lastIntake = dateUtils.getCurrentDate();
                };
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true, $scope.lastIntake);
                    //callback(true);
                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) { callback(false); }},
            () => { if (!handled) { callback(false); }}
        );
    };

    intakeModalService.setReactions = function (intakeData, callback) {
        let handled = false;
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: reactionsEditorTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    const reactionsOptions = reactionsService.getSymptomsNames();

                    $scope.view = {
                        reactionsOptions: reactionsOptions,
                        reactionsState: [],
                        reactions: [],
                        medicinesState: [],
                        medicines: [],
                        notes: "",
                        reactionTime:{},
                    };

                    $scope.data = intakeData;
                    $scope.lastIntake = dateUtils.getCurrentDate();

                    $scope.view.reactionTime = $scope.data.intake.reactDate
                        ? moment($scope.data.intake.reactDate).utc().toDate()
                        : dateUtils.getCurrentDate();

                    if ($scope.data.intake.reactions) {
                        for (const reactId in $scope.data.intake.reactions) {
                            $scope.view.reactionsState[$scope.data.intake.reactions[reactId]] = true;
                            $scope.view.reactions.push($scope.data.intake.reactions[reactId]);
                        }
                    }

                    $scope.view.notes = intakeData.intake.notes;
                };

                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true, $scope.view);
                };

                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false, $scope.view);
                };

                $scope.selectReaction = (reaction) => {
                    if (!$scope.view.reactionsState[reaction]) {
                        $scope.view.reactions.push(reaction);
                        $scope.view.reactionsState[reaction] = true;
                    }
                };

                $scope.unSelectReaction = (reaction, $event) => {
                    $event.preventDefault();
                    $event.stopPropagation();

                    const pos = $scope.view.reactions.indexOf(reaction);

                    $scope.view.reactionsState[reaction] = false;
                    if (pos >= 0) {
                        $scope.view.reactions.splice(pos, 1);
                    }
                };

                $scope.selectMedicine = (medicine) => {
                    if (!$scope.view.medicinesState[medicine]) {
                        $scope.view.medicines.push(medicine);
                        $scope.view.medicinesState[medicine] = true;
                    }
                };

                $scope.unSelectMedicine = (medicine, $event) => {
                    $event.preventDefault();
                    $event.stopPropagation();

                    const pos = $scope.view.medicines.indexOf(medicine);

                    $scope.view.medicinesState[medicine] = false;
                    if (pos >= 0) {
                        $scope.view.medicines.splice(pos, 1);
                    }
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) { callback(false, intakeData); }},
            () => { if (!handled) { callback(false); }}
        );
    };

    intakeModalService.setConstants = function (constants, patientConst, callback) {
        let handled = false;
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: constantsTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    $scope.constants=[];
                    constants.forEach(function(constante) {
                        let c;

                        if(patientConst) {
                            c = patientConst.find( consta => consta.name === constante.name);
                        }

                        if(constante.value!=""){
                            $scope.constants.push({
                                name: constante.name,
                                value: c?c.value:"",
                                unit: constante.unit
                            });
                        }
                    });
                };

                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true, $scope.constants);
                };

                $scope.decline = () => {
                    modal.close();
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) { callback(false, constants); }},
            () => { if (!handled) { callback(false); }}
        );
    };

    intakeModalService.showMedicinesTable = function (patientId, reacAsis) {
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: medicinesTable,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                    $scope.reacAsis = reacAsis;
                };
                $scope.accept = () => {
                    modal.close();
                    $state.go("app.admin-medical.control-tomas-detail", { id: patientId });
                };
                $scope.decline = () => {
                    modal.close();
                };
            }],
        });
        modal.result.then(
            () => { },
            () => { }
        );
    };

    intakeModalService.showClipBoardMessage = function () {
        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: clipboardMessage,
            size: "md",
            controller: ["$scope", function ($scope) {
                this.$onInit = function () {
                };
                $scope.accept = () => {
                    modal.close();
                };
                $scope.decline = () => {
                    modal.close();
                };
            }],
        });
        modal.result.then(
            () => { },
            () => { }
        );
    };

    return intakeModalService;
};

IntakeModalService.$inject = [
    "$uibModal",
    "$state",
    UTILS_DATE_SERVICE,
    REACTION_ALGORITHM_SERVICE,
];

export { INTAKE_MODAL_SERVICE, IntakeModalService };
