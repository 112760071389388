import { TOKEN_SERVICE } from "services/token";

class AppController {
    constructor($scope, $rootScope, $state, tokenService, __env) {

        handleRoleStatus();
        $rootScope.$on("$stateChangeStart", onStateChangeStart);

        function handleRoleStatus() {
            loadRoleAndMainState();

            if (!__env.ensureNavigation) return;

            const state = $state.current.name;
            const avaibleStates = __env.avaibleStatesPerRole[$scope.role];

            if (!$scope.role || !avaibleStates) {
                return $state.go("app.signin");
            }

            if (avaibleStates.indexOf(state) < 0) {
                $state.go($scope.mainState);
            }
        }

        function onStateChangeStart(event, toState) {
            loadRoleAndMainState();

            if (!__env.ensureNavigation) return;

            const avaibleStates = __env.avaibleStatesPerRole[$scope.role];

            if (!avaibleStates && toState.name == "app.signin") {
                return;
            }

            if (avaibleStates.indexOf(toState.name) < 0) {
                event.preventDefault();
            }
        }

        function loadRoleAndMainState() {
            $scope.role = tokenService.get("role");
            $scope.mainState = __env.avaibleStatesPerRole[$scope.role]
                ? __env.avaibleStatesPerRole[$scope.role][0]
                : "app.signin";
        }

    }
}

AppController.$inject = ["$scope", "$rootScope", "$state", TOKEN_SERVICE, "__env"];

export default AppController;
