import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { DOSE_DATA_COMPONENT, doseDataComponent } from "./dose-data.component";
import { DOSE_DATA_SERVICE, doseDataService } from "./dose-data.service";
import { PROTOCOL_MODAL_MODULE } from "services/modal/protocol";

const DOSE_DATA_MODULE = "doseDataModule";

angular.module(DOSE_DATA_MODULE, [
    "ui.router",
    API_CLIENT,
    PROTOCOL_MODAL_MODULE,
])
.component(DOSE_DATA_COMPONENT, doseDataComponent)
.factory(DOSE_DATA_SERVICE, doseDataService);

export default DOSE_DATA_MODULE;
