import angular from "angular";
import "angular-ui-bootstrap";

import Auth from "models/Auth";
import Intake from "models/Intake";

import { DOSE_SERVICE, USERS_SERVICE } from "services/api-client";
import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { UTILS_DATE_SERVICE } from "services/utils";
import { UTILS_PROTOCOL_SERVICE } from "services/utils";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

class adaptationDoseController {

    constructor(
        confirmationService,
        doseService,
        $errorModal,
        userService,
        dateUtils,
        protocolUtils,
        treatmentUtils
    ) {
        this.$errorModal = $errorModal;
        this.confirmationService = confirmationService;
        this.doseService = doseService;
        this.userService = userService;
        this.dateUtils = dateUtils;
        this.protocolUtils = protocolUtils;
        this.treatmentUtils = treatmentUtils;

        this.day = undefined;
        this.editedDay = undefined;
        this.unit = undefined;
        this.adaptationEdited = false;
        this.sentToIntakeControl = undefined;
    }

    $onChanges(changes) {
        if (changes.patient || changes.currentTreatment) {
            this.initPatientData();
        }
    }

    initPatientData() {
        this.initCurrentTreatmentData();
        this.loadCurrentIntraDay();
        this.adaptationEdited = false;
    }

    initCurrentTreatmentData() {
        this.unit = this.treatmentUtils.getTreatmentUnit(this.currentTreatment);

        if (this.currentTreatment.inIntakeControl || this.currentTreatment.toreview) {
            this.onEnterEditMode();
        }
    }

    loadCurrentIntraDay() {
        const untakenIntraDays = this.currentTreatment.intra.days
            .filter(day => !day.discharged);

        if (untakenIntraDays.length) {
            this.day = untakenIntraDays[0];
            this.editedDay = this.initEditedDay(this.day);
        } else {
            this.day = undefined;
            this.editedDay = undefined;
        }
    }

    hasCurrentTreatmentEnd() {
        return this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    validateForm() {
        let formIsValid = true;
        for (let i = 0; i < this.editedDay.intakes.length; i++) {
            const intake = this.editedDay.intakes[i];
            const valueIsValid = this.protocolUtils.validateVolume(intake.volume);
            if (!valueIsValid) {
                formIsValid = false;
            }
        }

        return formIsValid;
    }

    submitTreatmentAdaptation() {
        if (!this.editedDay) {
            return;
        }

        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (accepted) {
                this.updateTreatment(authData);
            }
        });
    }

    updateTreatment(data) {
        const parsedDay = this.parseDayIntakes(this.editedDay);

        parsedDay.auth = new Auth(data.user, data.pass);

        this.doseService.set(this.patient._id, parsedDay, (err) => {
            if (err) {
                this.$errorModal.show("Error", err.data.data[0].Error);
            } else {
                this.$errorModal.show("", "Las tomas del paciente han sido actualizadas");
            }

            this.onPatientRefreshNeeded();
        });
    }

    parseDayIntakes(day) {
        const newDay = angular.copy(day);

        for (let i = 0; i < newDay.intakes.length; i++) {
            if (this.day.intakes[i]) {
                const protocolDose = this.day.intakes[i].protocolDose && this.day.intakes[i].protocolDose.length
                    ? this.day.intakes[i].protocolDose
                    : this.day.intakes[i].dose;

                newDay.intakes[i] = new Intake(
                    i,
                    newDay.intakes[i].dose,
                    protocolDose,
                    newDay.intakes[i].dose,
                    "hospitalary",
                    newDay.intakes[i].volume,
                    newDay.intakes[i].observationTime,
                    this.unit
                );

                newDay.intakes[i].doseAltered = day.intakes[i].doseAltered ||
                    (this.currentTreatment.inIntakeControl == 1
                        && newDay.intakes[i].dose != this.day.intakes[i].dose);

                newDay.intakes[i].volumeAltered = day.intakes[i].volumeAltered ||
                    (this.currentTreatment.inIntakeControl == 1
                        && this.protocolUtils.cleanVolumeValue(newDay.intakes[i].volume) != this.protocolUtils.cleanVolumeValue(this.day.intakes[i].volume));
            } else {

                newDay.intakes[i] = new Intake(
                    i,
                    newDay.intakes[i].dose,
                    newDay.intakes[i].dose,
                    newDay.intakes[i].dose,
                    "hospitalary",
                    newDay.intakes[i].volume,
                    newDay.intakes[i].observationTime,
                    this.unit
                );
                newDay.intakes[i].doseAltered = true;
                newDay.intakes[i].volumeAltered = true;
            }
        }

        return newDay;
    }

    adaptationIntakeNeedsAuthorization() {
        return !this.currentTreatment.inIntakeControl
            && this.day
            && this.day.intakes
            && this.day.intakes.find(intake => intake.state != "taken")
            && !this.day.discharged
            && this.day.date <= this.dateUtils.getCurrentTs();
    }

    validateReg(index) {
        if (this.treatmentUtils.getTreatmentUnit(this.currentTreatment) == "unidad") {
            if (this.protocolUtils.validateFractionedDose(this.editedDay.intakes[index].dose.split(" ")[0])) {
                this.formValid = false;
            } else {
                this.formValid = true;
            }
        } else {
            if (this.protocolUtils.validateNormalDose(this.editedDay.intakes[index].dose)) {
                this.formValid = false;
            } else {
                this.formValid = true;
            }
        }

        this.adaptationEdited = true;
    }

    validateAndCleanVolume(intake) {
        if (this.protocolUtils.validateVolume(intake.volume)) {
            const volume = this.protocolUtils.cleanVolumeValue(intake.volume);
            intake.volume = volume != null ? volume : "-";
            intake.volumeInvalid = false;
        } else {
            intake.volumeInvalid = true;
        }

        this.adaptationEdited = true;

        return !intake.volumeInvalid;
    }

    canAddIntakeAfter(index) {
        return !this.editedDay.discharged
            && (index + 1 == this.editedDay.intakes.length
                || this.editedDay.intakes[index + 1].state !== "taken");
    }

    addIntake(index) {
        if (this.canAddIntakeAfter(index)) {
            const newDose = {
                dose: 0,
                volume: "0",
                observationTime: 0
            };

            this.editedDay.intakes.splice(index + 1, 0, newDose);
            this.adaptationEdited = true;
        }
    }

    canRemoveIntake(intake) {
        return this.canRemoveIntakes() && intake.state != "taken";
    }

    canRemoveIntakes() {
        return this.editedDay.intakes.length > 1;
    }

    removeIntake(index) {
        if (!this.canRemoveIntakes()) {
            return;
        }

        const intakes = this.editedDay.intakes;

        if (!intakes[index].state || intakes[index].state === "untaken") {
            intakes.splice(index, 1);
            this.adaptationEdited = true;
        }
    }

    cancelTreatmentAdaptation() {
        this.editedDay = this.initEditedDay(this.day);
        this.adaptationEdited = false;
    }

    initEditedDay(day) {
        const editedDay = angular.copy(day);

        for (let i = 0; i < editedDay.intakes.length; i++) {
            const intake = editedDay.intakes[i];
            intake.dose = intake.dose ? intake.dose.split(" ")[0] : "";
            intake.volume = intake.volume;
            intake.observationTime = intake.observationTime
                ? parseFloat(intake.observationTime.split(" ")[0])
                : undefined;
        }

        if (!editedDay.intakes || !editedDay.intakes.length) {
            editedDay.intakes = [
                {
                    dose: 0,
                    volume: undefined,
                    observationTime: 0,
                }
            ];
        }

        return editedDay;
    }

    authorizeIntake() {
        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (!accepted) {
                return;
            }

            this.sendToIntakeControl(authData);
        });
    }

    sendToIntakeControl(authData) {
        const request = {
            auth: authData,
            type: this.currentTreatment.type,
        };

        this.userService.sendToIntakeControl(this.patient._id, request, (err) => {
            this.sentToIntakeControl = err ? false : true;
        });
    }

    endHospitalaryIntake() {
        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (accepted) {
                this.onDischargePatient({ "authData": authData });
            }
        });
    }

}

adaptationDoseController.$inject = [
    CONFIRMATION_SERVICE,
    DOSE_SERVICE,
    ERROR_MODAL_SERVICE,
    USERS_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_PROTOCOL_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default adaptationDoseController;
