import { APPOINTMENTS_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";
import { UTILS_DATE_SERVICE } from "services/utils";

class CalendarController {

    constructor(
        $scope,
        $state,
        uiCalendarConfig,
        appointmentsService,
        tokenService,
        dateUtils
    ) {
        $scope.token = tokenService.get();
        $scope.appointments = [];
        $scope.events = [];
        $scope.centerName = $scope.token.center;

        this.$onInit = function () {
            $scope.loadEvents();
        };

        $scope.loadEvents = function () {
            appointmentsService.getByCenter($scope.token.centerId, (err, result) => {
                if (err) return console.error(err);
                let citas = result.data;

                citas.forEach(function (cita) {
                    cita.treatments.sort((a, b) => a.starts - b.starts);

                    let currentTreatment = cita.treatments.find(treatment => treatment.active === true);
                    if (cita.user[0] === undefined) {
                        //if (cita.user[0] === undefined || currentTreatment === undefined) {
                        console.log(JSON.stringify(cita));
                    } else {
                        let event = {
                            textColor: "black",
                            title: cita.user[0].fullName,
                            start: new Date(cita.date),
                            allDay: false,
                            ITO: currentTreatment && currentTreatment.allergen ? currentTreatment.allergen : "Sin establecer",
                            currentDose: currentTreatment && currentTreatment.currentDose ? currentTreatment.currentDose : "No especificada",
                            doctor: cita.doctorName,
                            patientId: cita.userId,
                        };
                        if ($scope.token.userId == cita.doctorId) event.color = "#79d28f";
                        if ((new Date(cita.date)) < (dateUtils.getCurrentDate())) event.color = "#bfbfbf";
                        $scope.appointments.push(event);
                    }

                });

                // PARAMETRO DE CONFIGURACION DEL CALENDARIO
                $scope.uiConfig = {
                    calendar: {
                        contentHeight: 650,
                        locale: "es",
                        lang: "es",
                        header: {
                            left: "prev,next, title",
                            center: " ",
                            right: " "
                        },
                        views: {
                            month: {
                                titleFormat: "MMMM YYYY"
                            },
                            agendaWeek: {
                                titleFormat: "D MMM YYYY",
                                slotLabelFormat: "H:mm",
                                displayEventTime: false
                            },
                            agendaDay: {
                                titleFormat: "D MMMM",
                                slotLabelFormat: "H:mm",
                                displayEventTime: false
                            },
                            listMonth: {
                                titleFormat: "MMMM YYYY"
                            }
                        },
                        defaultView: "agendaDay",
                        nowIndicator: true,
                        forceEventDuration: true,
                        defaultTimedEventDuration: "00:30:00",
                        weekends: false,
                        fixedWeekCount: false,
                        slotEventOverlap: false,
                        allDaySlot: false,
                        allDayDefault: false,
                        firstDay: 1,
                        businessHours: {
                            dow: [1, 2, 3, 4, 5],
                            start: "07:00",
                            end: "22:30",
                        },
                        minTime: "07:00",
                        maxTime: "23:00",
                        eventRender: function (event, element, view) {
                            $scope.eventRender(event, element, view);
                        },
                        eventClick: function (event) {
                            $state.go("app.admin-medical.patient-detail", { id: event.patientId });
                        },
                        editable: false,
                        events: [],
                    },
                };
            });
        };

        // PARAMETRO DE CONFIGURACION DEL CALENDARIO TIENE QUE ESTAR POR DUPLICADO PARA EVITAR EFECTOS COLATERALES
        $scope.uiConfig = {
            calendar: {
                contentHeight: 650,
                locale: "es",
                lang: "es",
                header: {
                    left: "prev,next, title",
                    center: " ",
                    right: " "
                },
                views: {
                    month: {
                        titleFormat: "MMMM YYYY"
                    },
                    agendaWeek: {
                        titleFormat: "D MMM YYYY",
                        slotLabelFormat: "H:mm",
                        displayEventTime: false
                    },
                    agendaDay: {
                        titleFormat: "D MMMM",
                        slotLabelFormat: "H:mm",
                        displayEventTime: false
                    },
                    listMonth: {
                        titleFormat: "MMMM YYYY"
                    }
                },
                defaultView: "agendaDay",
                nowIndicator: true,
                forceEventDuration: true,
                defaultTimedEventDuration: "00:30:00",
                weekends: false,
                fixedWeekCount: false,
                slotEventOverlap: false,
                allDaySlot: false,
                allDayDefault: false,
                firstDay: 1,
                businessHours: {
                    dow: [1, 2, 3, 4, 5],
                    start: "07:00",
                    end: "22:30",
                },
                minTime: "07:00",
                maxTime: "23:00",
                eventRender: function (event, element, view) {
                    $scope.eventRender(event, element, view);
                },
                eventClick: function (event) {
                    $state.go("app.admin-medical.patient-detail", { id: event.patientId });
                },
                editable: false,
            },
        };

        $scope.eventRender = function (event, element, view) {
            switch (view.name) {
                case "agendaWeek":
                    element.find(".fc-content").append(`
                        ITO a ${event.ITO} ${event.currentDose}.`);
                    break;

                case "agendaDay":
                    element.find(".fc-content").append(`
                        <p><b>ITO</b> a ${event.ITO}
                        <b>Dosis</b> ${event.currentDose}.
                        <b>Médico</b>: ${event.doctor}</p>`);
                    break;

                default: element.find(".fc-content").append(event.contentMonth);
            }
        };

        $scope.changeView = function (view, calendar) {
            uiCalendarConfig.calendars[calendar].fullCalendar("changeView", view);

            if (view === "agendaDay")
                uiCalendarConfig.calendars.myCalendar1.fullCalendar("gotoDate", dateUtils.getCurrentDate());
        };

        $scope.eventSources = [$scope.appointments];
    }

}

CalendarController.$inject = [
    "$scope",
    "$state",
    "uiCalendarConfig",
    APPOINTMENTS_SERVICE,
    TOKEN_SERVICE,
    UTILS_DATE_SERVICE,
];

export default CalendarController;
