const TREATMENT_SERVICE = "treatmentService";

var TreatmentService = function ($http, $q, __env) {

    const apiUrl = `${__env.apiUrl}/treatments`;

    const treatmentService = {};

    treatmentService.addIntraDay = function (treatmentId, requestData, callback) {
        $http.post(`${apiUrl}/${treatmentId}/intra/days/add`, requestData).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    };

    treatmentService.removeIntraDay = function (treatmentId, requestData, callback) {
        $http.post(`${apiUrl}/${treatmentId}/intra/days/remove`, requestData).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    treatmentService.updateIntraDay = function (treatmentId, requestData) {
        return $http.post(`${apiUrl}/${treatmentId}/intra/days`, requestData)
            .then(handleResponse, handleError);
    };

    treatmentService.updateStartDate = function (treatmentId, requestData) {
        return $http.post(`${apiUrl}/${treatmentId}/start-date`, requestData)
            .then(handleResponse, handleError);
    };

    treatmentService.dischargeIntraDay = function (treatmentId, dayNumber) {
        const body = { dayNumber: dayNumber };
        return $http.post(`${apiUrl}/${treatmentId}/intra/discharge`, body)
            .then(handleResponse, handleError);
    };

    function handleError(error) {
        return $q.reject(error.data);
    }

    function handleResponse(response) {
        return $q.resolve(response.data);
    }

    return treatmentService;
};

TreatmentService.$inject = ["$http", "$q", "__env"];

export { TREATMENT_SERVICE, TreatmentService };
