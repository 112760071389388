import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { UTILS_MODULE } from "services/utils";
import { PDF_MODULE } from "services/pdf";
import { PERSONAL_DATA_COMPONENT, personalDataComponent } from "./personal-data.component";
import { ERROR_MODAL_MODULE } from "services/modal/error";
import { PASSWORD_CHANGE_CONFIRMATION_MODAL_MODULE } from "services/modal/password-change-confirmation";
import { INFO_MODAL_MODULE } from "services/modal/info";
import { PROTOCOL_MODAL_MODULE } from "services/modal/protocol";

const PERSONAL_DATA_MODULE = "personalDataModule";

angular.module(PERSONAL_DATA_MODULE, [
    "ui.router",
    API_CLIENT,
    PDF_MODULE,
    UTILS_MODULE,
    ERROR_MODAL_MODULE,
    PASSWORD_CHANGE_CONFIRMATION_MODAL_MODULE,
    INFO_MODAL_MODULE,
    PROTOCOL_MODAL_MODULE,
])
.component(PERSONAL_DATA_COMPONENT, personalDataComponent);

export default PERSONAL_DATA_MODULE;
