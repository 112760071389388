import moment from "moment";

import { DISCHARGE_ALERT_SERVICE } from "components/discharge-alert";
import { TOKEN_SERVICE } from "services/token";
import { UTILS_DATE_SERVICE } from "services/utils";

class DischargeAlertListController {

    constructor(
        $state,
        $stateParams,
        dateUtils,
        dischargeAlertService,
        tokenService
    ) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.dateUtils = dateUtils;
        this.dischargeAlertService = dischargeAlertService;
        this.tokenService = tokenService;
    }

    $onChanges() {
        if (!this.alerts) {
            return;
        }

        const patientsWithAlert = this.getPatientsWithPendingAlert(this.alerts);

        if (patientsWithAlert.length
            && !this.isCurrentlyInPatientWithAlertProfile(patientsWithAlert)) {
            this.dischargeAlertService.openDischargeModal(patientsWithAlert[0], false);
        }
    }

    getPatientsWithPendingAlert(alerts) {
        return alerts
            .filter(alert => this.alertMustBeShownInPopup(alert))
            .map(alert => this.alertToPatient(alert));
    }

    alertToPatient(alert) {
        return {
            _id: alert.userId,
            fullName: alert.userName,
        };
    }

    alertMustBeShownInPopup(alert) {
        return this.alertIsFromToday(alert) && !alert.postponed;
    }

    alertIsFromToday(alert) {
        const fecha = moment.utc(alert.createDate).startOf("day");
        const today = moment.utc(this.dateUtils.getCurrentTs()).startOf("day");

        return today.valueOf() === fecha.valueOf();
    }

    isCurrentlyInPatientWithAlertProfile(patientsWithAlert) {
        return patientsWithAlert.some(patient =>
            this.isCurrentlyInPatientProfile(patient._id));
    }

    isCurrentlyInPatientProfile(patientId) {
        return this.$state.current.name === "app.admin-medical.patient-detail"
            && this.$stateParams.id === patientId;
    }

    onAlertClicked(alert) {
        const token = this.tokenService.get();

        if (token.role === "Doctor") {
            this.$state.go("app.admin-medical.patient-detail", {
                id: alert.userId,
                prevState: "alert"
            });
        }
    }

}

DischargeAlertListController.$inject = [
    "$state",
    "$stateParams",
    UTILS_DATE_SERVICE,
    DISCHARGE_ALERT_SERVICE,
    TOKEN_SERVICE
];

export default DischargeAlertListController;
