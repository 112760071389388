import angular from "angular";
class FilterController {

    constructor() {}

    $onInit() {
        this.filters = [];
    }

    addFilter() {

        let filter = this.filters.find(f => f.id == this.optSelected.id);

        this.chechAllergenFilter();
        this.manageCurrentFilter(filter);
        this.chechProtocolFilter();

        this.optSelected = undefined;
        this.optValue = undefined;

        this._emitChange(this.filters);
    }

    removeFilter(i) {                
        let filter = this.filters[i];        
        this.filters.splice(i, 1);

        if(filter.id == "allergen"){
            this.resetProtocolFilter();
        }        
        this._emitChange(this.filters);
    }

    _emitChange(data) {
        this.onChange({ data });
    }

    manageCurrentFilter(filter) {
        if (filter) { // replace filter            
            filter.value = this.optValue.value || this.optValue;
            filter.valuetext = this.optValue.key || (this.optValue.toLocaleString && this.optValue.toLocaleString()) || this.optValue;
            if(filter.id == "protocol"){
                this.chechProtocolFilter();
            }
        }
        else {// add new filter
            this.filters.push({
                id: this.optSelected.id,
                text: this.optSelected.text,
                value: this.optValue.value || this.optValue,
                valuetext: this.optValue.key || (this.optValue.toLocaleString && this.optValue.toLocaleString()) || this.optValue,
            });
        }
    }

    chechAllergenFilter() {
        if (this.optSelected.id == "allergen" && !this.filters.find(f => f.id == "protocol")) {
            this.optOriginal = new Array();
            angular.merge(this.optOriginal, this.options);
            
            var newOptions = this.options.find(item => item.id == "protocol");
            var protocolOptions = [];

            newOptions.values.forEach(item => {
                if (item.allergen == this.optValue.key) {
                    protocolOptions.push(item);
                }
            });
            newOptions.values = protocolOptions;            
        }
    }

    chechProtocolFilter() {
        if (this.optSelected.id == "protocol") {
            this.optSelected = this.options.find(opt => opt.id == "allergen");
            var fAllergen = this.filters.find(f => f.id == "allergen");            
            if(fAllergen){
                var idx = this.filters.indexOf(fAllergen);
                this.filters[idx] = {
                    id: "allergen",
                    text: "Alérgeno",
                    value: this.optValue.allergen,
                    valuetext: this.optValue.allergen,
                };
            }else{
                this.filters.push({
                    id: "allergen",
                    text: "Alérgeno",
                    value: this.optValue.allergen,
                    valuetext: this.optValue.allergen,
                });
            }            
        }
    } 
    resetProtocolFilter(){
        let filter = this.filters.find(f => f.id == "protocol");
        if(filter){
            var i = this.filters.findIndex(item => item.id == "protocol");
            this.removeFilter(i);
        }        
        var originalProtOptions = this.optOriginal.find(item => item.id == "protocol");
        var newProtOptions = this.options.find(item => item.id == "protocol");                
        newProtOptions.values = originalProtOptions.values;
    }   
}

FilterController.$inject = [];

export default FilterController;
