import filterTemplate from "./filter.template";
import filterController from "./filter.controller";

const FILTER_COMPONENT = "oitcontrolFilter";

const filterComponent = {
    template: filterTemplate,
    controller: filterController,
    bindings: {
        options: "=",
        onChange: "&",
    }
};

export { FILTER_COMPONENT, filterComponent };
