import appTemplate from "./app.template";
import AppController from "./app.controller";

import { TOKEN_SERVICE } from "services/token";

var appStates = function ($stateProvider, $urlRouterProvider, $locationProvider, $localStorageProvider, __env) {
    $stateProvider.state({
        name: "app",
        abstract: true,
        template: appTemplate,
        controller: AppController
    });

    function otherwiseAction($injector) {
        var $state = $injector.get("$state");
        var token = $injector.get(TOKEN_SERVICE);

        var role = token.get("role");

        if (!role || !__env.avaibleStatesPerRole[role]) {
            token.delete();
            return $state.go("app.signin");
        }

        $state.go(__env.avaibleStatesPerRole[role][0]);
    }

    otherwiseAction.$inject = ["$injector"];

    $urlRouterProvider.otherwise(otherwiseAction);

    $locationProvider.html5Mode(__env.html5Mode);
};

appStates.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$localStorageProvider", "__env"];

export default appStates;
