import editIntraPhaseTemplate from "./edit-intra-phase.template";
import editIntraPhaseController from "./edit-intra-phase.controller";

const EDIT_INTRA_PHASE_COMPONENT = "editIntraPhase";

const editIntraPhaseComponent = {
    template: editIntraPhaseTemplate,
    controller: editIntraPhaseController,
    bindings: {
        currentTreatment: "<",
        onIntraDaysAdded: "&",
        onIntraDaysRemoved: "&",
    }
};

export { EDIT_INTRA_PHASE_COMPONENT, editIntraPhaseComponent };
