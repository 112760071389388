const CENTERS_SERVICE = "centersService";

var CentersService = function ($http, __env) {
    var apiUrl = `${__env.apiUrl}/centers`;

    var centersService = {};

    centersService.create = function (centerData, callback) {
        $http.post(apiUrl, centerData).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    centersService.get = function (id, callback) {
        $http.get(`${apiUrl}/${id}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    centersService.getList = function (params, callback) {
        if (!callback)[params, callback] = [callback, params];

        $http.get(apiUrl, params ? { params: params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    centersService.getUsers = function (id, callback) {
        $http.get(`${apiUrl}/${id}/users`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    centersService.getReacAsis = function (centerId, callback) {
        $http.get(`${apiUrl}/${centerId}/reacasis`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    centersService.updateConstants = function(center, callback){
        $http.put(`${apiUrl}/${center._id}`,center).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    return centersService;
};

CentersService.$inject = ["$http", "__env"];

export { CENTERS_SERVICE, CentersService };
