import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { TOKEN_MODULE } from "services/token";
import DISCHARGE_ALERT_LIST_MODULE from "../discharge-alert-list";
import { HEADER_COMPONENT, webHeaderComponent } from "./web-header.component";
import { PASSWORD_CHANGE_MODAL_MODULE } from "services/modal/password-change";

const HEADER_MODULE = "webHeaderModule";

angular.module(HEADER_MODULE, [
    "ui.router",
    API_CLIENT,
    TOKEN_MODULE,
    PASSWORD_CHANGE_MODAL_MODULE,
    DISCHARGE_ALERT_LIST_MODULE,
])
.component(HEADER_COMPONENT, webHeaderComponent);

export default HEADER_MODULE;
