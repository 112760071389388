import appointmentTemplate from "./appointment.template";
import appointmentController from "./appointment.controller";

const APPOINTMENT_COMPONENT = "appointment";

const appointmentComponent = {
    template: appointmentTemplate,
    controller: appointmentController,
    bindings: {
        canEditExtraordinaryAppointments: "=",
        centerId: "=",
        currentTreatment: "=",
        patientId: "=",
    }
};

export { APPOINTMENT_COMPONENT, appointmentComponent };
