import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import centerListStates from "./center-list.states";

const CENTERLIST_MODULE = "adminAppCenterListModule";

angular.module(CENTERLIST_MODULE, [
    "ui.router",
    API_CLIENT,
    CENTERLIST_MODULE
])
    .config(centerListStates);

export default CENTERLIST_MODULE;
