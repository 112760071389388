import adaptationDoseTemplate from "./adaptation-dose.template";
import adaptationDoseController from "./adaptation-dose.controller";

const ADAPTATION_DOSE_COMPONENT = "adaptationDose";

const adaptationDoseComponent = {
    template: adaptationDoseTemplate,
    controller: adaptationDoseController,
    bindings: {
        currentTreatment: "<",
        onDischargePatient: "&",
        onEnterEditMode: "&",
        onExitEditMode: "&",
        onPatientRefreshNeeded: "&",
        patient: "<",
    }
};

export { ADAPTATION_DOSE_COMPONENT, adaptationDoseComponent };
