import angular from "angular";
import "angular-chart-lb.js";

import { INFORM_PREVISUALIZATION_COMPONENT, informPrevisualizationComponent } from "./inform-previsualization.component";

const INFORM_PREVISUALIZATION_MODULE = "informPrevisualizationModule";

angular.module(INFORM_PREVISUALIZATION_MODULE, [
    "chart-lb.js",
])
.component(INFORM_PREVISUALIZATION_COMPONENT, informPrevisualizationComponent);

export default INFORM_PREVISUALIZATION_MODULE;
