import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import { PROTOCOL_EDITOR_MODULE } from "services/protocols";

import { PROTOCOLS_EDITOR_COMPONENT, protocolsEditorComponent } from "./protocols-editor.component";

const PROTOCOLS_EDITOR_MODULE = "protocols-editorModule";

angular.module(PROTOCOLS_EDITOR_MODULE, [
    "ui.router",
    API_CLIENT,
    PROTOCOL_EDITOR_MODULE,
])
.component(PROTOCOLS_EDITOR_COMPONENT, protocolsEditorComponent);

export default PROTOCOLS_EDITOR_MODULE;
