import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import {  COMPARATIVE_COMPONENT, comparativeComponent } from "./comparative.component";

const COMPARATIVE_MODULE = "comparativeModule";

angular.module(COMPARATIVE_MODULE, [
    "ui.router",
    API_CLIENT,
])
.component(  COMPARATIVE_COMPONENT, comparativeComponent );

export default COMPARATIVE_MODULE;
