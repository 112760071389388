import angular from "angular";

const UTILS_MODULE = "utilsModule";

import { UTILS_SERVICE, UtilsService } from "./utils.service";
import { UTILS_APPOINTMENT_SERVICE, UtilsAppointmentService } from "./utils.appointments";
import { UTILS_DATE_SERVICE, UtilsDateService } from "./utils.dates";
import { UTILS_NUMBERS_SERVICE, UtilsNumbersService } from "./utils.numbers";
import { UTILS_PROTOCOL_SERVICE, UtilsProtocolsService } from "./utils.protocols";
import { UTILS_STRINGS_SERVICE, StringUtilsService } from "./utils.string";
import { UTILS_TREATMENT_SERVICE, UtilsTreatmentService } from "./utils.treatments";

angular.module(UTILS_MODULE, [
])
    .factory(UTILS_SERVICE, UtilsService)
    .factory(UTILS_APPOINTMENT_SERVICE, UtilsAppointmentService)
    .factory(UTILS_DATE_SERVICE, UtilsDateService)
    .factory(UTILS_NUMBERS_SERVICE, UtilsNumbersService)
    .factory(UTILS_PROTOCOL_SERVICE, UtilsProtocolsService)
    .factory(UTILS_STRINGS_SERVICE, StringUtilsService)
    .factory(UTILS_TREATMENT_SERVICE, UtilsTreatmentService);

export {
    UTILS_MODULE,
    UTILS_SERVICE,
    UTILS_APPOINTMENT_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_NUMBERS_SERVICE,
    UTILS_PROTOCOL_SERVICE,
    UTILS_STRINGS_SERVICE,
    UTILS_TREATMENT_SERVICE,
};
