import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import personalStates from "./personal-medico.states";

const PERSONAL_MODULE = "personalCenterModule";

angular.module(PERSONAL_MODULE, [
    "ui.router",
    API_CLIENT,
    PERSONAL_MODULE,
])
    .config(personalStates);

export default PERSONAL_MODULE;
