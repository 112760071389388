const UTILS_SERVICE = "utilsService";

var UtilsService = function ($q) {

    var camposMinimos = ["fullName", "birthDate", "history", "address", "phone", "email", "weight"];

    var utilsService = {};

    utilsService.checkMinimos = function (patient) {
        var errorMsg = [];
        var errorFields = [];

        camposMinimos.forEach((atr) => {
            if (!patient[atr]) {
                errorMsg.push(this.missingAtr(atr));
                errorFields.push(atr);
            }
        });

        return $q.resolve({
            mensajes: errorMsg,
            atributos: errorFields
        });
    };

    this.missingAtr = function (atr) {
        switch (atr) {
        case "fullName":
            return "Introduce el nombre";
        case "birthDate":
            return "Introduce la fecha de nacimiento";
        case "history":
            return "Introduce el # de expediente/hitorial";
        case "address":
            return "Introduce la dirección de residencia";
        case "phone":
            return "Introduce un nº de teléfono o móvil";
        case "email":
            return "Introduce el email";
        case "weight":
            return "Introduce el peso";
        default:
            return "";
        }
    };

    return utilsService;
};

UtilsService.$inject = ["$q"];

export { UTILS_SERVICE, UtilsService };
