import protocolsHeadTemplate from "./protocols-head.template";
import protocolsHeadController from "./protocols-head.controller";

const PROTOCOLS_HEAD_COMPONENT = "protocolsHead";

const protocolsHeadComponent = {
    template: protocolsHeadTemplate,
    controller: protocolsHeadController,
    bindings: {
        newProtocol: "<head",
        protocols: "=",
        onChange: "&",
    }
};

export { PROTOCOLS_HEAD_COMPONENT, protocolsHeadComponent };
