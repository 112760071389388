class WeekSeparator {

    constructor(currentTreatmentType, nextTreatmentType) {
        this.separator = true;
        this.originProtocol = currentTreatmentType;
        this.destProtocol = nextTreatmentType;
    }

}

export default WeekSeparator;
