import angular from "angular";
import "angular-ui-router";

import adminAppStates from "./admin-app.states";

const ADMINAPP_MODULE = "adminAppModule";

import CENTERLIST_MODULE from "./center-list";
import CENTER_MODULE from "./center-detail";

angular.module(ADMINAPP_MODULE, [
    "ui.router",
    CENTERLIST_MODULE,
    CENTER_MODULE,
])
    .config(adminAppStates);

export default ADMINAPP_MODULE;
