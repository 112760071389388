import angular from "angular";
import "ngstorage";

import { TOKEN_MODULE } from "services/token";

const API_CLIENT = "apiClientService";

import { AUTH_SERVICE, AuthService } from "./auth.service";
import { CENTERS_SERVICE, CentersService } from "./centers.service";
import { USERS_SERVICE, UsersService } from "./users.service";
import { PROTOCOLS_SERVICE, ProtocolsService } from "./protocols.service";
import { INFORMS_SERVICE, InformsService } from "./informs.service";
import { APPOINTMENTS_SERVICE, AppointmentsService } from "./appointments.service";
import { MAPPER_SERVICE, MapperService } from "./mapper.service";
import { DOSE_SERVICE, DoseService } from "./dose.service";
import { ALERT_SERVICE, AlertService } from "./alert.service";
import { INTAKE_CONTROL_SERVICE, IntakeControlService } from "./intakecontrol.service";
import { TREATMENT_SERVICE, TreatmentService } from "./treatment.service";
import { CLINICAL_TRIAL_SERVICE, ClinicalTrialService } from "./clinical-trial.service";

angular.module(API_CLIENT, [
    "ngStorage",
    TOKEN_MODULE,
])
    .factory(AUTH_SERVICE, AuthService)
    .factory(CENTERS_SERVICE, CentersService)
    .factory(USERS_SERVICE, UsersService)
    .service(PROTOCOLS_SERVICE, ProtocolsService)
    .factory(INFORMS_SERVICE, InformsService)
    .factory(APPOINTMENTS_SERVICE, AppointmentsService)
    .factory(MAPPER_SERVICE, MapperService)
    .factory(DOSE_SERVICE, DoseService)
    .factory(ALERT_SERVICE, AlertService)
    .factory(INTAKE_CONTROL_SERVICE, IntakeControlService)
    .factory(TREATMENT_SERVICE, TreatmentService)
    .factory(CLINICAL_TRIAL_SERVICE, ClinicalTrialService);

export {
    API_CLIENT,
    AUTH_SERVICE,
    CENTERS_SERVICE,
    USERS_SERVICE,
    PROTOCOLS_SERVICE,
    INFORMS_SERVICE,
    APPOINTMENTS_SERVICE,
    MAPPER_SERVICE,
    DOSE_SERVICE,
    ALERT_SERVICE,
    INTAKE_CONTROL_SERVICE,
    TREATMENT_SERVICE,
    CLINICAL_TRIAL_SERVICE
};
