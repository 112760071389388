import comparativeMultiIntakeTemplate from "./comparative-multi-intake.template";
import comparativeMultiIntakeController from "./comparative-multi-intake.controller";

const COMPARATIVE_MULTI_INTAKE_COMPONENT = "comparativeMultiIntake";

const comparativeMultiIntakeComponent = {
    template: comparativeMultiIntakeTemplate,
    controller: comparativeMultiIntakeController,
    bindings: {
        onEnterEditMode: "&",
        currentTreatment: "<",
        dayToEdit: "<",
        patient: "<"
    }
};

export { COMPARATIVE_MULTI_INTAKE_COMPONENT, comparativeMultiIntakeComponent };
