import { PROTOCOLS_SERVICE } from "services/api-client";

import Protocol from "models/Protocol";
class ProtocolsController {
    constructor(protocols) {
        this.reg = /^(\s*\d+\s*\/\s*\d+\s*|\s*\d+\s*|\s*0\s*|)$/;
        this.regVol = /^\d+(|(\.\d*)|(\s*:\s*\d+))$/;

        this.protocolService = protocols;
        this.botonCrearProtocolo = {
            "btn": true,
            "btn-primary": true,
            ".active": false,
        };

        this.clon = false;
        this.onEdit = false;
        this.listAlergens = [];
        this.listProtocols = [];

        this.newProtocol2 = new Protocol();
        this.selectedAllergen;
        this.selectedProtocol;
        this.protocols = [];
    }

    $onInit() {
        this.loadProtocolsList();
    }

    loadProtocolDetail(id) {
        if (!this.clon) {
            this.onEdit = false;
            this.botonCrearProtocolo.active = false;

            this.protocolService.get(id, (err, protocol) => {
                if (err) {
                    this.protocol = protocol;
                    // TODO do something
                    return console.error(err);
                }
                this.protocol = protocol.data;
                this.protocol.observationTime = this.inHours(this.protocol.observationTime);
            });
        }
    }

    loadProtocolsList() {
        this.protocolService.getList((err, protocols) => {
            if (err) {
                return console.error(err);
            }

            this.protocols = protocols.data;
            for (let i = 0; i < this.protocols.length; i++) {
                const allergen = this.protocols[i].allergen;
                if (!this.listAlergens.includes(allergen)) {
                    this.listAlergens.push(allergen);
                }
            }
        });
    }

    inHours(mili) {
        return mili / 3600000;
    }

    inMinutes(mili) {
        return mili / 60000;
    }

    editProtocol() {
        this.newProtocol = new Protocol();

        this.newProtocol2 = new Protocol();

        this.onEdit = !this.onEdit;
        this.botonCrearProtocolo.active = !this.botonCrearProtocolo.active;
        this.protocol = null;
    }

    remove(index) {
        this.newProtocol.weeks.splice(index, 1);
        this.control.splice(index, 1);
    }

    clone(id) {
        this.clon = true;
        this.editProtocol();

        this.protocolService.get(id, (err, protocol) => {
            if (err) {
                this.protocol = protocol;
                this.clon = false;

                return console.error(err);
            }
            this.inicializeControl(protocol.data);
            this.newProtocol.allergen = protocol.data.allergen;
            this.newProtocol.type = "COPIA DE " + protocol.data.type;
            this.newProtocol.observationTime = this.inMinutes(protocol.data.observationTime);

            this.newProtocol2.allergen = protocol.data.allergen;
            this.newProtocol2.type = "COPIA DE " + protocol.data.type;
            this.newProtocol2.observationTime = this.inMinutes(protocol.data.observationTime);
            if (protocol.data.intra) {
                this.newProtocol2.intra = protocol.data.intra;
                this.inicializeControlIntra(this.newProtocol2.intra);
            }

            this.clon = false;
            this.onEdit = true;
            this.protocol = null;
            this.changeTab();
        });
    }

    changeTab() {
        $("[href='#Edicion']").tab("show");
    }

    validateReg(protocol, index) {
        const week = protocol.weeks[index];
        const control = protocol.control[index];

        if (this.reg.test(week.dose)) {
            week.doseBorder = { "border": "none" };
            this.updateControl(control.dose, false, true, false);
        } else {
            week.doseBorder = null;
            this.updateControl(control.dose, true, false, false);
        }
    }

    createControl(err, succ, feed) {
        var dose = { "has-error": err, "has-success": succ, "has-feedback": feed };
        var icon = { "has-error": err, "has-success": succ, "has-feedback": feed };

        return { dose, icon };
    }

    inicializeControl(protocol) {
        if (protocol) {
            protocol.weeks.forEach(this.addRow2, this);

            if (protocol.weeks[0].dose) {
                this.newProtocol2.selectedDoseUnit = protocol.weeks[0].dose.split(" ")[1];
                this.newProtocol2.selectedUnit = protocol.weeks[0].dose.split(" ")[1];
            }

            if (protocol.intra) {
                this.newProtocol2.intra = protocol.intra;
            }
        }
    }

    updateControl(node, err, succ, none) {
        node["has-error"] = err;
        node["has-success"] = succ;
        node["none"] = none;
    }


    addRow2(week, index) {
        this.newProtocol2.weeks.push({ weekNumber: this.newProtocol2.weeks.length + 1, dose: week.dose.split(" ")[0], doseBorder: { "border-style": "none" } });
        this.newProtocol2.control.push(this.createControl(true, false, true));
        this.validateReg(this.newProtocol2, index);
    }

    updateProtocolsList() {
        this.listProtocols = [];
        for (let i = 0; i < this.protocols.length; i++) {
            if (this.protocols[i].allergen == this.selectedAllergen) {
                this.listProtocols.push(this.protocols[i].type);
            }
        }

    }

    //################################## INTRA HOSPITALARIO ############################################
    validateRegIntra(dayIndex, index, protocol) {
        const intake = protocol.days[dayIndex].intakes[index];
        const row = protocol.control[dayIndex].intakes[index];

        if (this.reg.test(intake.dose)) {
            this.updateControlIntra(row.dose, false, true, false);
        } else {
            this.updateControlIntra(row.dose, true, false, false);
        }

        if (this.regVol.test(intake.volume)) {
            this.updateControlIntra(row.volume, false, true, false);
        } else {
            if (intake.volume.length === 0) {
                this.updateControlIntra(row.volume, false, false, true);
            }
            else {
                this.updateControlIntra(row.volume, true, false, false);
            }
        }

        if (this.reg.test(intake.observationTime)) {
            intake.obsBorder = { "border": "none" };
            this.updateControlIntra(row.obs, false, true, false);
        } else {
            intake.obsBorder = null;
            this.updateControlIntra(row.obs, true, false, false);
        }


        if (row.dose["has-success"] & (row.volume["has-success"] || row.volume["none"]) & row.obs["has-success"]) {
            this.updateControlIntra(row.icon, false, true, false);
        }
        else {
            this.updateControlIntra(row.icon, true, false, false);
        }
    }

    createControlIntra(err, succ, feed, none) {
        var dose = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        var icon = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        var volume = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        var obs = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };

        return { dose, icon, volume, obs };
    }

    updateControlIntra(node, err, succ) {
        node["has-error"] = err;
        node["has-success"] = succ;
        node["none"] = node;
    }

    inicializeControlIntra(protocol) {
        if (protocol) {
            protocol.control = [];
            if (protocol.days.length > 0 && protocol.days[0].intakes && protocol.days[0].intakes.length > 0) {
                const intake = protocol.days[0].intakes[0];
                protocol.selectedDoseUnit = intake.dose.split(" ")[1];
                protocol.selectedVolumeUnit = intake.volume.split(" ")[1];
                protocol.selectedTimeUnit = intake.observationTime.split(" ")[1];
                for (let i = 0; i < protocol.days.length; i++) {
                    const day = protocol.days[i];

                    for (let x = 0; x < day.intakes.length; x++) {
                        const intake = day.intakes[x];
                        this.initValues(intake);
                    }

                    this.addRowIntra(protocol.days[i], i, protocol);
                }
            }
        }
    }

    initValues(intake) {
        intake.dose = intake.dose.split(" ")[0];
        intake.volume = intake.volume.split(" ")[0];
        intake.observationTime = intake.observationTime.split(" ")[0];
    }

    addRowIntra(day, index, protocol) {
        if (!protocol.control[index]) {
            protocol.control.push({ show: false, intakes: [] });
        }

        for (let d = 0; d < day.intakes.length; d++) {
            protocol.control[index].intakes.push(this.createControlIntra(false, false, true, true));
            this.validateRegIntra(index, d, protocol);
        }
    }

}

ProtocolsController.$inject = [PROTOCOLS_SERVICE];

export default ProtocolsController;
