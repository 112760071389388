const UTILS_NUMBERS_SERVICE = "utilsNumbersService";

var UtilsNumbersService = function () {

    const utilsNumbersService = {};

    utilsNumbersService.fixToRealPrecission = function(number, maxPrecission) {
        const decimalPart = number ? parseFloat(number).toFixed(maxPrecission).split(".")[1] : 0;
        const significativeDecimals = countSignificativeDecimals(decimalPart);

        return parseFloat(number).toFixed(significativeDecimals);
    };

    function countSignificativeDecimals(number) {
        return number.length - countTrailingZeros(number);
    }

    function countTrailingZeros(number) {
        let trailingZeros = 0;

        for (let i = number.length - 1; i >= 0; i--) {
            if (number[i] != "0") {
                return trailingZeros;
            }
            trailingZeros++;
        }

        return trailingZeros;
    }

    return utilsNumbersService;

};

UtilsNumbersService.$inject = [];

export { UTILS_NUMBERS_SERVICE, UtilsNumbersService };
