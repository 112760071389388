import patientTemplate from "./patient.template";
import patientController from "./patient.controller";

var patientStates = function ($stateProvider) {
    $stateProvider
        .state({
            name: "app.admin-medical.patient-create",
            url: "/paciente",
            template: patientTemplate,
            controller: patientController,
            controllerAs: "$ctrl",
        })
        .state({
            name: "app.admin-medical.patient-detail",
            url: "/paciente/:id",
            params: {
                prevState: undefined
            },
            template: patientTemplate,
            controller: patientController,
            controllerAs: "$ctrl",
        });
};

patientStates.$inject = ["$stateProvider"];

export default patientStates;
