import { CENTERS_SERVICE, USERS_SERVICE } from "services/api-client";

class MainController {
    constructor($scope, $window, centersService, usersService) {
        $scope.newCenterForm = {};
        $scope.newCenterForm.reacAsis = {};
        $scope.centerForm;
        $scope.centeriD;
        $scope.addAdmCenterChk = false;
        $scope.applicationModeList = ["Oral", "Inhalado", "Intramuscular", "Sublingual"];
        $scope.constantes = [
            { name: "TAS", unit:"mmHg", selected:false},
            { name: "TAD", unit:"mmHg", selected:false},
            { name: "Frecuencia cardíaca", unit:"lpm", selected: false },
            { name: "Peak Flow", unit:"L/min", selected: false },
            { name: "Sat O2", unit:"%",selected: false },
        ];

        function loadCenters() {
            centersService.getList((err, res) => {
                if (err) return console.error(err);

                $scope.centers = res.data;
            });
        };
        loadCenters();

        $scope.openCreateCenter = function () {
            $scope.newCenterForm = {};
            $scope.centerForm.$setPristine();
            $window.$("#newCenterModal").modal("toggle");
        };

        $scope.submitCreateCenter = function () {
            $scope.centerForm.$setSubmitted();
            if ($scope.centerForm.$invalid) {
                return;
            }
            let afternoon = $scope.newCenterForm.centerOpenAfternoon || "";
            let constants = $scope.constantes.filter((constant) => constant.selected == true);
            constants = constants.map(constant => {
                return { name: constant.name, unit: constant.unit};
            });
            let createParams = {
                name: $scope.newCenterForm.centerName,
                address: $scope.newCenterForm.centerAddress,
                phone: $scope.newCenterForm.centerPhone,
                openingTime: $scope.newCenterForm.centerOpenAfternoon ? `M: ${$scope.newCenterForm.centerOpenMorning}, T: ${afternoon}` : `${$scope.newCenterForm.centerOpenMorning}`,
                centerCoords: "",
                constants: constants
            };

            centersService.create(createParams, (err, res) => {
                if (err) {
                    $scope.errorMessage = "Error al crear el centro";
                    return;
                }
                loadCenters();

                if (!$scope.addAdmCenterChk) {
                    return $window.$("#newCenterModal").modal("toggle");
                }
                let centerId = res.data;
                let createCeterAdmin = {
                    fullName: $scope.newCenterForm.userName,
                    email: $scope.newCenterForm.email,
                    nick: $scope.newCenterForm.nick,
                    pass: $scope.newCenterForm.userPass,
                    profiles: [
                        {
                            role: "CenterAdmin",
                            centerId: centerId,
                            active: true
                        }
                    ]
                };
                usersService.create(createCeterAdmin, (err, res) => {
                    if (err) {
                        $scope.errorMessage = "Error al crear administrador del centro";
                        return;
                    }
                    $window.$("#newCenterModal").modal("toggle");
                });

            });
        };

        $scope.showAdmin = function () {
            if ($scope.addAdmCenterChk)
                $window.$(".nav-tabs a[href='#administrador']").tab("show");
            else
                $window.$(".nav-tabs a[href='#constantes']").tab("show");
        };
    }
}

MainController.$inject = ["$scope", "$window", CENTERS_SERVICE, USERS_SERVICE];

export default MainController;
