import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import INFORM_PREVISUALIZATION_MODULE from "./components/inform-previsualization";

import FILTER_MODULE from "components/filter";

import informsStates from "./informs.states";

const INFORMS_MODULE = "informsModule";

angular.module(INFORMS_MODULE, [
    "ui.router",
    API_CLIENT,
    FILTER_MODULE,
    INFORM_PREVISUALIZATION_MODULE,
])
.config(informsStates);

export default INFORMS_MODULE;
