import "angular-ui-bootstrap";
import moment from "moment";
import {
    USERS_SERVICE,
    PROTOCOLS_SERVICE,
    INFORMS_SERVICE
} from "services/api-client";
import { UTILS_DATE_SERVICE } from "services/utils";

import filterOptions from "./filter.options";

class informsController {

    constructor(
        $scope,
        $rootScope,
        informsService,
        userService,
        protocolService,
        dateUtils
    ) {
        $scope.result = [];
        $scope.graphic = {};

        this.$onInit = function () {
            $scope.filterOptions = filterOptions;
            $scope.setDinamicFilterOptions();
        };

        $scope.search = function () {
            if (!$scope.graphic.groupOption)
                $scope.graphic.groupOption = "allergen";

            informsService.getDataGraphics($scope.graphic.groupOption, $scope.filters, (err, result) => {
                if (err) return console.error(err);

                $scope.result = result;

                $scope.graphic.chartData = [];
                $scope.graphic.chartLabels = [];

                for (let i = 0; i < $scope.result.length; i++) {
                    $scope.graphic.chartData.push($scope.result[i].count);
                    var titulo = $scope.result[i]._id == "male" ? "Varones" : $scope.result[i]._id;
                    titulo = $scope.result[i]._id == "female" ? "Mujeres" : titulo;
                    $scope.graphic.chartLabels.push(titulo);
                }

                $rootScope.$emit("cambioDeFiltroEvent");
            });

            informsService.getData($scope.filters, (err, result) => {
                $scope.datos = result;
                $scope.datos.forEach(function (item) {
                    item.birthDate = (new Date(dateUtils.getCurrentTs() - item.birthDate)).getFullYear() - 1970;
                });
            });

        };

        $scope.exportCSV = function () {
            informsService.getData($scope.filters, (err, result) => {
                if (err) return console.error(err);
                /*TODO control de array vacio
                if (result.length < 1) {
                    confirmationService.update("mensaje de error o algo"
                        , (accepted, ok) => {
                            return;
                        });
                    return;
                }*/
                var csvData = createCSV(result);
                if (!csvData) {
                    return;
                }
                downloadCSV(csvData);
            });
        };

        function createCSV(data) {
            var result, ctr;
            var keys = ["Alérgeno", "Protocolo", "Doctor", "Sexo", "Edad"];

            if (!data || !keys.length) {
                return null;
            }
            var columnDelimiter = ";";
            var lineDelimiter = "\n";

            result = "";
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            data.forEach(function (item) {
                ctr = 0;
                keys.forEach(function (key) {
                    if (ctr > 0)
                        result += columnDelimiter;

                    result += parseData(item, key);

                    ctr++;
                });

                result += lineDelimiter;
            });
            return result;
        }

        function parseData(item, key) {
            switch (key) {
                case "Alérgeno":
                    return item.treatment.allergen;
                case "Protocolo":
                    return item.treatment.type;
                case "Doctor":
                    return item.doctor;
                case "Sexo":
                    return item.gender === "male" ? "Hombre" : "Mujer";
                case "Edad":
                    return (new Date(dateUtils.getCurrentTs() - item.birthDate)).getFullYear() - 1970;
                default:
                    return "";
            }
        }

        function downloadCSV(inputData) {
            let fecha = moment(dateUtils.getCurrentTs()).utc().format("YYYYMMMDD");
            let exportFileName = "exportar_informes_" + fecha + ".csv";

            if (!inputData.match(/^data:text\/csv/i)) {
                inputData = "data:text/csv;charset=utf-8," + inputData;
            }

            $scope.csv = encodeURI(inputData);

            if (isEdgeBrowser() && window.navigator.msSaveOrOpenBlob) {
                downloadCsvForEdgeBrowser(exportFileName);
            } else {
                var item = document.createElement("a");
                item.setAttribute("href", $scope.csv);
                item.setAttribute("target", "_blank");
                item.setAttribute("download", exportFileName);
                document.body.appendChild(item);
                item.click();
                document.body.removeChild(item);
            }
        }

        function isEdgeBrowser() {
            return navigator.appVersion.indexOf("Edge") > -1;
        }

        function downloadCsvForEdgeBrowser(exportFile) {
            var csvBlobObject = new Blob([$scope.csv]);

            window.navigator.msSaveOrOpenBlob(csvBlobObject, exportFile);
        }

        $scope.filterChange = function (data) {
            $scope.filters = $scope.parseParams(data);
            $scope.search();
        };

        $scope.parseParams = function (filter) {
            var params = {};
            filter.forEach((item) => {
                switch (item.id) {
                    case "allergen":
                        params["allergen"] = item.value;
                        break;
                    case "protocol":
                        params["protocol"] = item.value;
                        break;
                    case "doctor":
                        params["doctor"] = item.value;
                        break;
                    case "gender":
                        params["gender"] = item.value;
                        break;
                    case "mayor":
                        params["mayor"] = item.value;
                        break;
                    case "menor":
                        params["menor"] = item.value;
                        break;
                }
            });
            return params;
        };

        $scope.setDinamicFilterOptions = function () {
            if (!filterOptions.find(option => "allergen" == option.id)) {
                protocolService.getAllergens((err, allergens) => {
                    if (err) return console.error(err);

                    var valores = [];

                    allergens.data.forEach((val) => {
                        valores.push({
                            key: val,
                            value: val
                        });
                    });

                    var obj = {
                        id: "allergen",
                        text: "Alérgeno",
                        type: "select",
                        values: valores
                    };
                    $scope.filterOptions.push(obj);
                });
            }


            if (!filterOptions.find(option => "protocol" == option.id)) {
                protocolService.getList((err, protocols) => {
                    if (err) return console.error(err);

                    var valores = [];
                    protocols.data.forEach((val) => {
                        valores.push({
                            key: val.type,
                            value: val.type,
                            allergen: val.allergen
                        });
                    });
                    var obj = {
                        id: "protocol",
                        text: "Protocolo",
                        type: "select",
                        values: valores
                    };
                    $scope.filterOptions.push(obj);
                });
            }

            if (!filterOptions.find(option => "doctor" == option.id)) {
                userService.getList({
                    role: "Doctor"
                }, (err, doctors) => {
                    if (err) return console.error(err);

                    var valores = [];
                    doctors.data.forEach((val) => {
                        valores.push({
                            key: val.fullName,
                            value: val._id
                        });
                    });
                    var obj = {
                        id: "doctor",
                        text: "Doctor",
                        type: "select",
                        values: valores
                    };
                    $scope.filterOptions.push(obj);
                });
            }
        };
    }

}

informsController.$inject = [
    "$scope",
    "$rootScope",
    INFORMS_SERVICE,
    USERS_SERVICE,
    PROTOCOLS_SERVICE,
    UTILS_DATE_SERVICE
];

export default informsController;
