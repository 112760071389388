import oitImg from "images/pdf/oit.b64";

import reac_1a from "images/pdf/reacciones/reac_1a.b64";
import reac_1b from "images/pdf/reacciones/reac_1b.b64";
import reac_2 from "images/pdf/reacciones/reac_2.b64";
import reac_3 from "images/pdf/reacciones/reac_3.b64";
import reac_4a from "images/pdf/reacciones/reac_4a.b64";
import reac_4b from "images/pdf/reacciones/reac_4b.b64";
import reac_5 from "images/pdf/reacciones/reac_5.b64";
import reac_6 from "images/pdf/reacciones/reac_6.b64";
import reac_7 from "images/pdf/reacciones/reac_7.b64";
import reac_8 from "images/pdf/reacciones/reac_8.b64";
import reac_9 from "images/pdf/reacciones/reac_9.b64";
import reac_10 from "images/pdf/reacciones/reac_10.b64";

class PdfTemplateFactory {
    constructor() {
    }

    getReactionsTemplate(treatments) {
        return {
            content: [
                // LOGOS
                {
                    columns: [
                        {
                            image: oitImg,
                            alignment: "right",
                        },
                    ],
                    style: "header"
                },

                // TITLE
                {
                    text: "Información necesaria en reacciones durante la inmunoterapia oral",
                    style: "title"
                },

                // SUBTITLE
                {
                    text: "Recomendaciones generales",
                    style: "subtitle"
                },

                // Ps
                {
                    text: [
                        "Debe tener ",
                        { text: "impreso y accesible este documento siempre", style: "important" },
                        ", ya que en caso de reacción debería poder acceder a esta información incluso aunque su dispositivo móvil esté apagado.",
                        "\n\n",
                        "En caso de duda póngase en contacto con su médico y ante cualquier eventualidad acuda a Urgencias. El teléfono de emergencias, si lo precisa, recuerde que es el ",
                        { text: "112", style: "important" },
                        ".",
                        "\n\n",
                        "En caso de que los síntomas se modifiquen o agraven actúe aplicando el tratamiento correspondiente a la nueva situación como si fuera una nueva reacción.",
                        "\n\n",
                        "A continuación se detallan los tratamientos y recomendaciones a seguir en los distintos tipos de reacciones en base a la medicación prescrita por su médico:",
                        "\n\n"
                    ]
                },

                // UL medicines list
                {
                    ul: [
                        {
                            text: [
                                { text: "Antihistamínico: ", bold: true },
                                `${treatments.antihistamine.medicine} ${treatments.antihistamine.dose} ${treatments.antihistamine.application}`
                            ],
                            style: "ulElem",
                        },
                        {
                            text: [
                                { text: "Corticoide oral: ", bold: true },
                                `${treatments.corticoid.medicine} ${treatments.corticoid.dose} ${treatments.corticoid.application}`
                            ],
                            style: "ulElem"
                        },
                        {
                            text: [
                                { text: "Inhalador broncodilatador: ", bold: true },
                                `${treatments.bronchodilator.medicine} ${treatments.bronchodilator.dose} ${treatments.bronchodilator.application}`
                            ],
                            style: "ulElem"
                        },
                        {
                            text: [
                                { text: "Adrenalina: ", bold: true },
                                `${treatments.adrenalin.medicine} ${treatments.adrenalin.dose} ${treatments.adrenalin.application}`
                            ],
                            style: "ulElem"
                        }
                    ],
                    margin: [30, 0, 0, 0],
                    pageBreak: "after"
                },

                // LOGOS AGAIN
                {
                    columns: [
                        {
                            // image: cunImg,
                        },
                        {
                            image: oitImg,
                            alignment: "right",
                        },
                    ],
                    style: "header"
                },
                {
                    table: {
                        /*headerRows: 1,*/
                        body: [
                            [
                                { text: "REACCIÓN", style: "tableHeader" },
                                { text: "DESCRIPCIÓN DE LA REACCIÓN", style: "tableHeader" },
                                { text: "TRATAMIENTO", style: "tableHeader" },
                                { text: "RECOMENDACIONES POSTERIORES", style: "tableHeader" }
                            ],
                            [
                                { image: reac_1a, width: 50, alignment: "center" },
                                { text: "Picor en la boca suave", style: "important", margin: [0, 17, 0, 0] },
                                { text: "Beba agua y manténgase tranquilo", alignment: "center", margin: [0, 4, 0, 0] },
                                { text: "Si dura más de 15 minutos tome 1 dosis de antihistamínico.", margin: [0, 10, 0, 0] }
                            ],
                            [
                                { image: reac_1b, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Picor en la boca, ronchas alrededor de la boca o ligero hinchazón de labios", style: "important" },
                                { text: "Antihistamínico", alignment: "center", margin: [0, 19, 0, 0] },
                                { text: "Si no mejora en 1-2 horas acuda a Urgencias.", margin: [0, 13, 0, 0] }
                            ],
                            [
                                { image: reac_2, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Picor o enrojecimiento en la cara, ronchas en la cara o hinchazón de labios o párpados", style: "important" },
                                { text: "Antihistamínico", alignment: "center", margin: [0, 19, 0, 0] },
                                { text: "Si no mejora en 1-2 horas acuda a Urgencias.", margin: [0, 13, 0, 0] }
                            ],
                            [
                                { image: reac_3, width: 50, alignment: "center", margin: [0, 10, 0, 0] },
                                { text: "Picor generalizado, enrojecimiento o ronchas por el cuerpo, hinchazón de manos/pies", style: "important" },
                                { text: "Antihistamínico + corticoide", alignment: "center", margin: [0, 18, 0, 0] },
                                { text: "Tras la toma de la medicación debe acudir de forma inmediata a Urgencias", margin: [0, 11, 0, 0] }
                            ],
                            [
                                { image: reac_5, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Congestión nasal, lagrimeo, picor de ojos, ojos rojos, estornudos o mucosidad nasal", style: "important" },
                                { text: "Antihistamínico", alignment: "center", margin: [0, 19, 0, 0] },
                                { text: "Si aparecen otros síntomas debe reconsiderar el tratamiento.", margin: [0, 13, 0, 0] }
                            ],
                            [
                                { image: reac_4a, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Dolor/molestia abdominal suave", style: "important", margin: [0, 13, 0, 0] },
                                { text: "Antihistamínico", alignment: "center", margin: [0, 19, 0, 0] },
                                { text: "Si el dolor dura más de 45-60min, administre adrenalina intramuscular y acuda de forma inmediata a Urgencias." }
                            ],
                            [
                                { image: reac_4b, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Dolor abdominal intenso, náuseas, vómitos o diarrea", style: "important", margin: [0, 5, 0, 0] },
                                { text: "Adrenalina", alignment: "center", margin: [0, 19, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo adrenalina."
                                    ]
                                }
                            ],
                            [
                                { image: reac_6, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Picor y/o opresión en la garganta, dificultad para tragar", style: "important", margin: [0, 5, 0, 0] },
                                { text: "Adrenalina", alignment: "center", margin: [0, 19, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
                                    ]
                                }
                            ],
                            [
                                { image: reac_7, width: 50, alignment: "center", margin: [0, 10, 0, 0] },
                                { text: "Ronquera, tos perruna, falta de aire, pitidos en el pecho", style: "important", margin: [0, 14, 0, 0] },
                                { text: "Adrenalina + Inhalador broncodilatador", alignment: "center", margin: [0, 14, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina y de nuevo el broncodilatador."
                                    ]
                                }
                            ],
                            [
                                { image: reac_8, width: 50, alignment: "center", margin: [0, 10, 0, 0] },
                                { text: "Sensación de debilidad o mareo, dolor/opresión en el pecho, palpitaciones, tensión baja, desmayo", style: "important" },
                                { text: "Adrenalina", alignment: "center", margin: [0, 19, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
                                    ]
                                }
                            ],
                            [
                                { text: " " },
                                { text: " " },
                                { text: " " },
                                { text: " " }
                            ],
                            [
                                { image: reac_9, width: 50, alignment: "center", margin: [0, 3, 0, 0] },
                                { text: "Dos o más reacciones que no sean picor en la boca", style: "important", margin: [0, 5, 0, 0] },
                                { text: "Adrenalina", alignment: "center", margin: [0, 19, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
                                    ]
                                }
                            ],
                            [
                                { image: reac_10, width: 50, alignment: "center", margin: [0, 10, 0, 0] },
                                { text: "Dos o más reacciones, siendo una de ellas ronquera, tos, falta de aire, pitidos en el pecho", style: "important", margin: [0, 9, 0, 0] },
                                { text: "Adrenalina + Inhalador broncodilatador", alignment: "center", margin: [0, 14, 0, 0] },
                                {
                                    text: [
                                        { text: "Debe acudir de forma inmediata a Urgencias.", bold: true },
                                        "Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina y de nuevo el broncodilatador."
                                    ]
                                }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: "#ffffff",
                        hLineColor: "#ffffff",
                    },
                    pageBreak: "after"
                },
                // SUBTITLE
                {
                    text: "Instrucciones para ajustar la dosis:",
                    style: "subtitle"
                },

                // Ps
                {
                    text: [
                        "Si por algún motivo interrumpe la toma del alimento durante tres o más días se pondrá en contacto con su alergólogo para administrar la dosis en el hospital.",
                        "\n\n",
                        "En caso de que no pueda acudir a la visita programada en el hospital para subir la dosis del alimento, se mantendrá la misma dosis de éste hasta la fecha en que pueda acudir al hospital para dicho aumento.",
                        "\n\n",
                        "Deberá disminuir la dosis del alimento a la mitad (por ejemplo: si está tomando 20 ml en esta semana, pasaría a tomar 10 ml y volvería a la dosis habitual de 20 ml una vez superada la enfermedad), en caso de presentar alguna de las siguientes situaciones:",
                        "\n\n"
                    ],
                },
                {
                    ul: [
                        {
                            text: [
                                { text: "Gastroenteritis" },
                            ],
                            style: "ulElem",
                        },
                        {
                            text: [
                                { text: "Crisis de asma" },
                            ],
                            style: "ulElem",
                        },
                        {
                            text: [
                                { text: "Fiebre (>38ºC axilar)" },
                            ],
                            style: "ulElem",
                        },
                    ]
                },
                {
                    text: [
                        "Si el día anterior ha presentado una reacción moderada: ronchas/inflamación por el cuerpo (no exclusivamente en la cara), dolor abdominal/náuseas o vómitos que se resolvieron con antihistamínico, o estornudos/lloriqueo o picor de nariz/ojos tomará la dosis que anteriormente toleraba (por ejemplo, si la reacción la tuvo tomando 30 ml, bajaría al anterior escalón de 25 ml).",
                        "\n\n",
                        "Si la reacción ha precisado adrenalina o ha consistido en dolor abdominal muy intenso, opresión de garganta, falta de aire/tos/pitidos o sensación de mareo, se pondrá en contacto con nosotros. Si le toca tomar la próxima dosis del alimento disminuirá la dosis a la mitad (por ejemplo, si está tomando 20 ml pasaría a tomar 10 ml) hasta que reciba nuevas indicaciones por parte de personal médico implicado en su tratamiento.",
                        "\n\n",
                        "En caso de duda se pondrá en contacto con nosotros por teléfono."
                    ],
                }
            ],

            // FOOTER
            footer: (currentPage, pageCount) => {
                return {
                    text: `${currentPage}/${pageCount}`,
                    alignment: "right",
                    margin: [10, 10, 10, 10],
                };
            },

            // COMMON STYLES
            styles: {
                header: {
                    margin: [0, -25, 0, 30]
                },
                title: {
                    fontSize: 28,
                    color: "#41c6ef",
                    margin: [0, 0, 0, 5] //[left, top, right, bottom]
                },
                subtitle: {
                    fontSize: 20,
                    margin: [0, 10, 0, 10]
                },
                important: {
                    color: "#41c6ef",
                    bold: true
                },
                ulElem: {
                    margin: [10, 0, 0, 15],
                },
                tableHeader: {
                    fillColor: "#41c6ef",
                    color: "#ffffff",
                    bold: true,
                }
            }
        };
    }

    getPasswordTemplate(user, pass) {
        return {
            content: [
                {
                    columns: [
                        {
                            image: oitImg,
                            alignment: "right",
                        },
                    ],
                    style: "header"
                }, {
                    text: "\n Información de acceso al App OITControl",
                    style: "title"
                },
                {
                    text: ["\n\n", `Usuario: ${user}`, "\n", `Contraseña: ${pass}`]
                },
                {
                    text: ["\n\n", "Recuerde restablecer su contraseña después de iniciar sesión en la App."]
                },
                {
                    text: ["\n", "Las credenciales de acceso a la App móvil de OITcontrol se establecen para cada " +
                        "paciente sometido a inmunoterapia oral. Sólo se emite un usuario y contraseña por paciente, del que deberán " +
                        "responsabilizarse de forma compartida los familiares o tutores legales que vayan a utilizar la App durante el tratamiento del paciente."]
                },
            ],
            styles: {
                header: {
                    margin: [0, -25, 0, 30]
                },
                title: {
                    fontSize: 24,
                    margin: [0, 0, 0, 5] //[left, top, right, bottom]
                },
                important: {
                    color: "#41c6ef",
                    bold: true
                }
            }
        };
    }
}

export default PdfTemplateFactory;
