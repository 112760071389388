import { Howl } from "howler";
import moment from "moment";
import sound from "sounds/alarm.mp3";

import { APPOINTMENTS_SERVICE } from "./appointments.service";
import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { TREATMENT_SERVICE } from "./treatment.service";
import { USERS_SERVICE } from "./users.service";
import { UTILS_DATE_SERVICE } from "services/utils";
import { UTILS_STRINGS_SERVICE } from "services/utils";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

const INTAKE_CONTROL_SERVICE = "intakeControlService";

var IntakeControlService = function (
    $http,
    $state,
    $interval,
    __env,
    appointmentsService,
    reactionsService,
    treatmentService,
    userService,
    datesUtils,
    stringUtils,
    treatmentUtils
) {
    const apiUrlCenters = `${__env.apiUrl}/centers`;
    const intakeControlService = {};
    const player = new Howl({
        src: [sound],
        onend: function () {
            makeSound = false;
        }
    });
    const soundTime = 12000;
    const timers = [];

    let makeSound;
    let patients = [];
    let patientsDischarged = [];
    let timersInterval;
    let patientsCache = undefined;

    function resetPatientsCache() {
        patientsCache = undefined;
    }

    function getTimers() {
        return timers;
    }

    function startTimersRefresh() {
        if (!timersInterval) {
            timersInterval = $interval(() => refreshTimers(), 1000);
        }
    }

    function addTimer(patientId, intake) {
        if (intake) {
            const hasNoIgnorableReactions = reactionsService.intakeHasNoIgnorableReactions(intake);
            const observationTime = parseFloat(intake.observationTime.split(" ")[0]) * 60000;

            const endTime = hasNoIgnorableReactions
                ? intake.reactDate + observationTime
                : intake.date + observationTime;

            if (timers[patientId] !== undefined) {
                timers[patientId].endTime = endTime;
            } else {
                timers[patientId] = {
                    endTime: endTime
                };
            }
        }
    }

    function removeTimer(patientId) {
        delete timers[patientId];
    }

    function getPatients() {
        return patients;
    }

    function getPatientsDischarged() {
        return patientsDischarged;
    }

    function purgaExpiredPatients(newPatientsDischarged, sDate) {
        const purgedDischargedPatients = [];
        const ts = sDate != undefined ? sDate : datesUtils.getCurrentTs();
        const now = moment(ts).utc().startOf("day").toDate();

        for (let i = 0; i < newPatientsDischarged.length; i++) {
            const patient = newPatientsDischarged[i];

            if (patient && patient.toArchive && now > patient.toArchive && !patient.toreview) {
                userService.takeOutFromIntakeControl(patient._id, datesUtils.getCurrentTs(), "archive", () => {
                });
            } else {
                purgedDischargedPatients.push(patient);
            }
        }

        return purgedDischargedPatients;
    }

    function getPatientById(id) {
        const patient = patients.find(patient => patient._id === id);
        if (patient) {
            return patient;
        }

        return patientsDischarged.find(patient => patient._id === id);
    }

    function refreshTimers() {
        const t = Object.keys(timers);
        const now = datesUtils.getCurrentTs();

        t.forEach(function (patientId) {
            const timer = timers[patientId];
            timer.time = Math.abs(timer.endTime - now);

            if (timer.endTime < now) {
                const patient = patients.find(pat => pat._id === patientId);
                if (patient) {
                    const day = getTreatmentDay(patient.treatments, now);
                    day.expired = true;

                    if (patient.intakesTaken == day.intakes.length) {
                        day.state = "finalizado";
                    }

                    if (patient.intakesTaken > 0 && !day.intakes[patient.intakesTaken - 1].expired) {
                        day.intakes[patient.intakesTaken - 1].expired = true;
                        userService.updateIntraIntakes(patient._id, day.date, day.intakes[patient.intakesTaken - 1], function (err) {
                            if (err) {
                                patientsCache = undefined;
                            }
                        });
                    } else {
                        const diff = (now - timer.endTime);
                        if (diff > 0 && diff < soundTime && !makeSound) {
                            makeSound = true;
                            player.play();
                        }
                    }
                }
            }
        });
    }

    function stop() {
        if (timersInterval !== undefined) {
            $interval.cancel(timersInterval);
        }
    }

    function timerExists(patientId) {
        return timers.find(t => t === patientId) !== undefined;
    }

    function timerIsFinished(patientId, now) {
        return timerExists(patientId)
            && getEndTime(patientId) < now;
    }

    function getEndTime(patientId) {
        const t = timers.find(t => t === patientId);

        return t ? t.endTime : 0;
    }

    function load(callback) {
        const today = datesUtils.getCurrentDate();

        getList({ startDate: today.getTime() }, (err, result) => {
            if (result && result.patients) {
                const parsedPatients = parseIntakeControlPatients(result.patients);
                const newPatients = parsedPatients.filter(patient => patient.inIntakeControl == 1 && !patient.discharged);
                const newPatientsDischarged = parsedPatients.filter(patient => patient.discharged == 1);

                patients = newPatients;
                patientsDischarged = purgaExpiredPatients(newPatientsDischarged, result.sdate);

                parsePatients(today);
                parsePatientsDischarged();
                setTimers(today);

                callback(null, patients, patientsDischarged);
            } else {
                callback(err);
            }
        });
    }

    function parsePatients(today) {
        patients.forEach(function (patient) {
            const treatmentDay = getTreatmentDay(patient.treatments, today.getTime());

            patient.dayIndex = 0;

            for (; patient.dayIndex < patient.treatments.days.length; patient.dayIndex++) {
                if (treatmentDay == undefined || patient.treatments.days[patient.dayIndex].date == treatmentDay.date) {
                    break;
                }
            }

            if (patient.state === "finalizado") {
                treatmentDay.state = "finalizado";
            } else if (patient.intakesTaken == 0) {
                treatmentDay.state = "revision";
            } else {
                treatmentDay.state = "pendingIntake";
            }
        });
    }

    function parsePatientsDischarged() {
        patientsDischarged.forEach(function (patient) {
            if (patient.treatments.currentDay) {
                appointmentsService.getNextAppointment(patient._id, patient.treatments.days[patient.treatments.currentDay].date, function (err, citas) {
                    if (err || !citas || citas.length === 0) {
                        return;
                    }

                    citas = citas.sort((a, b) => b.date - a.date);
                    patient.nextAppointment = formatAppointmentDateTime(new Date(citas[0].date));
                });
            }
        });
    }

    function setTimers(today) {
        patients.forEach(function (patient) {
            const treatmentDay = getTreatmentDay(patient.treatments, today.getTime());

            if (patient.intakesTaken > 0) {
                const lastTakenIntake = treatmentDay.intakes[patient.intakesTaken - 1];
                addTimer(patient._id, lastTakenIntake);
            }

            refreshTimers();
        });
    }

    function formatAppointmentDateTime(date) {
        return moment(date).utc().locale("es").format("DD [de] MMMM YYYY[ a las ]HH:mm");
    }

    function formatDateTimeLocalized(date) {
        return moment(date).utc().locale("es").format("DD [de] MMMM YYYY[, ]HH:mm");
    }

    function formatDateLocalized(date) {
        return moment(date).utc().locale("es").format("DD [de] MMMM YYYY");
    }

    function getList(params, callback) {
        if (patientsCache && patientsCache.patients.length > 0) {
            callback(null, patientsCache);
        } else {
            if (!callback) {
                [params, callback] = [callback, params];
            }

            userService.getIntakeControl(params).then(
                (result) => {
                    patientsCache = result.data;
                    callback(null, result.data);
                },
                (err) => {
                    patientsCache = undefined;
                    callback(err);
                }
            );
        }
    }

    function getConstants(centerId, callback) {
        $http.get(apiUrlCenters + "/" + centerId + "/constants").then(
            (result) => {
                callback(null, result.data);
            },
            (err) => callback(err)
        );
    };

    function parseIntakeControlPatients(patientsData) {
        return patientsData.map(parseIntakeControlPatient);
    }

    function parseIntakeControlPatient(patientData) {
        const treatments = patientData.treatments;
        const patientTreatment = treatments[treatments.length - 1];

        if (isIntraFinished(patientTreatment)) {
            const now = datesUtils.getStartOfCurrentDate().getTime();
            const week = getTreatmentWeek(patientTreatment, datesUtils.getCurrentTs());
            const day = getTreatmentDay(week, datesUtils.getCurrentTs());
            const treatmentObservationTime = patientTreatment.observationTime / 60000 + " minutos";
            const constants = [
                { name: "TAS", unit: "mmHg", value: null },
                { name: "TAD", unit: "mmHg", value: null },
                { name: "Frecuencia cardíaca", unit: "lpm", value: null },
                { name: "Peak Flow", unit: "L/min", value: null },
                { name: "Sat O2", unit: "%", value: null }
            ];

            if (day != undefined && now == day.date && day.intakes.length > 0) {
                if (!day.observationTime && !day.discharged) {
                    day.intakes[0].observationTime = treatmentObservationTime;
                }

                patientTreatment.intra = {
                    _id: patientTreatment._id,
                    isIntra: false,
                    starts: patientTreatment.starts,
                    doctorDoses: patientTreatment.doctorDoses,
                    days: [day]
                };
            } else {
                patientTreatment.intra = {
                    _id: patientTreatment._id,
                    isIntra: false,
                    starts: patientTreatment.starts,
                    doctorDoses: patientTreatment.doctorDoses,
                    days: [
                        {
                            day: 1,
                            observationTime: treatmentObservationTime,
                            date: now,
                            constants: (day != undefined && now == day.date)
                                ? day.constants
                                : constants,
                            state: "revision",
                            intakes: [
                                {
                                    dose: patientTreatment.currentDose,
                                    doseAltered: patientTreatment.doctorDoses && patientTreatment.doctorDoses.length
                                        ? patientTreatment.doctorDoses[patientTreatment.doctorDoses.length - 1].doseAltered
                                        : false,
                                    protocolDose: week && week.dose ? week.dose : patientTreatment.currentDose,
                                    volume: week && week.volume ? week.volume : " - ",
                                    observationTime: treatmentObservationTime
                                }
                            ],
                            date: now
                        }
                    ]
                };
            }
        } else {
            patientTreatment.intra._id = patientTreatment._id;
            patientTreatment.intra.isIntra = true;
            patientTreatment.intra.doctorDoses = patientTreatment.doctorDoses;
            patientTreatment.intra.starts = patientTreatment.starts;
        }

        const patient = {
            _id: patientData.userId,
            allergen: patientTreatment.allergen,
            type: patientTreatment.type,
            fullName: patientData.fullName ? patientData.fullName : "sin nombre",
            gender: patientData.gender,
            state: "En curso",
            intakesTaken: 0,
            nextIntakeTime: patientData.date,
            treatments: patientTreatment.intra,
            reacAsis: patientTreatment.reacAsis,
            inIntakeControl: patientTreatment.inIntakeControl,
            discharged: patientTreatment.discharged,
            toArchive: patientTreatment.toArchive,
            starts: patientTreatment.starts,
        };

        const treatmentDay = getTreatmentDay(patient.treatments, datesUtils.getCurrentTs());
        if (treatmentDay) {
            if (patient.treatments.days.length == 1) {
                patient.treatments.currentDay = 0;
            } else {
                patient.treatments.currentDay = treatmentDay.day > 0 ? treatmentDay.day - 1 : 0;
            }

            const alreadyTakenIntakes = treatmentDay.intakes.filter(intake => intake.state === "taken");
            if (alreadyTakenIntakes) {
                patient.intakesTaken = alreadyTakenIntakes.length;
                if (patient.intakesTaken != 0) {
                    patient.state = patient.intakesTaken === treatmentDay.intakes.length
                        ? "finalizado"
                        : "pendingIntake";
                }
            } else {
                patient.state = "revision";
            }
        }

        return patient;
    }

    function isIntraFinished(patientTreatment) {
        return !patientTreatment.intra ||
            (isIntraClosed(patientTreatment.intra) &&
                (!patientTreatment.toArchive || !isIntraDayToArchive(patientTreatment)));
    }

    function isIntraDayToArchive(treatment) {
        if (!treatment.intra || !treatment.intra.days) {
            return false;
        }

        return undefined != treatment.intra.days
            .find(day => day.date == treatment.toArchive);
    }

    function isIntraClosed(intra) {
        if (intra && intra.days && intra.days.length > 0) {
            return undefined == intra.days
                .find(day => !isDayDischarged(day));
        }

        return true;
    }

    function isDayDischarged(day) {
        return day.discharged;
    }

    function getUserDetail(id) {
        if (patientsCache == undefined) {
            $state.go("app.admin-medical.control-tomas");
        } else {
            return patientsCache.patients.find(user => user.userId === id);
        }
    }

    function getTreatmentDay(treatmentWeek, untilDate) {
        if (!treatmentWeek || !treatmentWeek.days) {
            return null;
        }

        const pendingDays = treatmentWeek.days
            .filter(day => day.date <= untilDate && !day.discharged)
            .filter(day => !dayIsExtrahospitalary(day));

        if (treatmentWeek.isIntra && pendingDays.length > 0) {
            return pendingDays[0];
        }

        const startOfDay = moment(untilDate).utc().startOf("day").valueOf();
        for (let i = 0; i < pendingDays.length; i++) {
            const day = pendingDays[i];
            if (datesUtils.isStartOfTheSameDay(day.date, startOfDay)) {
                return day;
            }
        }

        if (pendingDays.length > 0) {
            return pendingDays[0];
        }

        // Si la toma se da por terminada se deja para que se modifique por parte de la enfermera
        const daysToReview = treatmentWeek.days
            .filter(day => day.date <= untilDate)
            .filter(day => day.discharged && day.toreview);

        return daysToReview[daysToReview.length - 1];
    }

    function dayIsExtrahospitalary(day) {
        return day.intakes
            && day.intakes.length > 0
            && (day.intakes[0].intakeType == "extrahospitalary"
                || day.intakes[0].isExtrahospitalary === true);
    }

    function getTreatmentWeek(treatment, date) {
        const weeks = treatment.weeks.filter(week => week.date < date);

        return weeks.pop();
    }

    function delayTreatmentStartDateIfNeeded(weekIsIntra, currentWeek, treatmentStarts, treatmentId) {
        if (!treatmentStartMustBeDelayed(weekIsIntra, currentWeek, treatmentStarts)) {
            return Promise.resolve(false);
        }

        const updateRequest = {
            newDate: datesUtils.getStartOfCurrentDate().valueOf(),
        };

        return treatmentService.updateStartDate(treatmentId, updateRequest)
            .then(() => {
                return Promise.resolve(true);
            });
    }

    function treatmentStartMustBeDelayed(weekIsIntra, currentWeek, treatmentStarts) {
        const hasDischargedDays = treatmentUtils.weekHasAnyDischargedDay(currentWeek);
        const hasIntakes = treatmentUtils.weekHasAnyTakenIntake(currentWeek);
        const treatmentStartsToday = datesUtils.isToday(treatmentStarts);

        return weekIsIntra && !hasDischargedDays && !hasIntakes && !treatmentStartsToday;
    }

    function delayIntraDayIfNeeded(day, treatmentId) {
        if (datesUtils.isToday(day.date)) {
            return Promise.resolve();
        }

        const updateRequest = {
            oldDate: day.date,
            newDate: datesUtils.getStartOfCurrentDate().valueOf(),
        };

        return treatmentService.updateIntraDay(treatmentId, updateRequest);
    }

    intakeControlService.resetPatientsCache = resetPatientsCache;
    intakeControlService.getTimers = getTimers;
    intakeControlService.startTimersRefresh = startTimersRefresh;
    intakeControlService.addTimer = addTimer;
    intakeControlService.removeTimer = removeTimer;
    intakeControlService.getPatients = getPatients;
    intakeControlService.getPatientsDischarged = getPatientsDischarged;
    intakeControlService.getPatientById = getPatientById;
    intakeControlService.stop = stop;
    intakeControlService.timerExists = timerExists;
    intakeControlService.timerIsFinished = timerIsFinished;
    intakeControlService.load = load;
    intakeControlService.formatDateLocalized = formatDateLocalized;
    intakeControlService.formatDateTimeLocalized = formatDateTimeLocalized;
    intakeControlService.getConstants = getConstants;
    intakeControlService.getUserDetail = getUserDetail;
    intakeControlService.getTreatmentDay = getTreatmentDay;
    intakeControlService.getTreatmentWeek = getTreatmentWeek;
    intakeControlService.delayIntraDayIfNeeded = delayIntraDayIfNeeded;
    intakeControlService.delayTreatmentStartDateIfNeeded = delayTreatmentStartDateIfNeeded;

    return intakeControlService;
};

IntakeControlService.$inject = [
    "$http",
    "$state",
    "$interval",
    "__env",
    APPOINTMENTS_SERVICE,
    REACTION_ALGORITHM_SERVICE,
    TREATMENT_SERVICE,
    USERS_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_STRINGS_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export { INTAKE_CONTROL_SERVICE, IntakeControlService };
