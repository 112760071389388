import "bootstrap/dist/css/bootstrap.css";
import "titatoggle/dist/titatoggle-dist.css";
import "fullcalendar/dist/fullcalendar.css";
import "./sass/styles";

import "jquery";
import "bootstrap";
import "moment";
import angular from "angular";
import "angular-ui-router";
import "angular-ui-bootstrap";
import "angular-ui-calendar";
import "angular-chart-lb.js";
import "fullcalendar";

import appStates from "./app/app.states";
import appEnv from "./app/app.env";
import appLogging from "./app/app.logging";

import HEADER_MODULE from "./components/web-header";
import SIGNIN_MODULE from "./views/signin";
import ADMINAPP_MODULE from "./views/admin-app";
import ADMINCENTER_MODULE from "./views/admin-center";
import ADMINMEDICAL_MODULE from "./views/admin-medical";
import AUTH_INTERCEPTOR_MODULE from "./configs/auth";

import { TOKEN_MODULE } from "services/token";
import { UTILS_MODULE } from "services/utils";

angular.module("sotima-admin", [
    "ui.router",
    "ui.bootstrap",
    "ui.calendar",
    "chart-lb.js",
    SIGNIN_MODULE,
    HEADER_MODULE,
    ADMINAPP_MODULE,
    ADMINCENTER_MODULE,
    ADMINMEDICAL_MODULE,
    AUTH_INTERCEPTOR_MODULE,
    TOKEN_MODULE,
    UTILS_MODULE,
])
    .constant("__env", appEnv)
    .config(appStates)
    .config(appLogging);

