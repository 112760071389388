import DischargeAlertListController from "./discharge-alert-list.controller";
import dischargeAlertListTemplate from "./discharge-alert-list.template";

const DISCHARGE_ALERT_LIST_COMPONENT = "dischargeAlertList";

const dischargeAlertListComponent = {
    bindings: {
        alerts: "<",
    },
    controller: DischargeAlertListController,
    template: dischargeAlertListTemplate,
};

export { DISCHARGE_ALERT_LIST_COMPONENT, dischargeAlertListComponent };
