import protocolsTemplate from "./protocols.template";
import protocolsController from "./protocols.controller";

const PROTOCOLS_COMPONENT = "protocolsComponent";

const protocolsComponent = {
    template: protocolsTemplate,
    controller: protocolsController,
};

export { PROTOCOLS_COMPONENT, protocolsComponent };
