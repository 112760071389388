const USERS_SERVICE = "usersService";

var UsersService = function ($http, __env) {
    var apiUrl = `${__env.apiUrl}/users`;
    var usersService = {};

    usersService.create = function (userData, callback) {
        $http.post(apiUrl, userData).then(
            (result) => callback(null, result),
            (err) => callback(err)
        );
    };

    usersService.update = function (id, userData, callback) {
        $http.put(`${apiUrl}/${id}`, userData).then(
            (result) => callback(null, result),
            (err) => callback(err)
        );
    };

    usersService.modifyUser = function (id, userData, callback) {
        $http.put(`${apiUrl}/${id}`, userData).then(
            (result) => callback(null, result),
            (err) => callback(err)
        );
    };

    usersService.deleteUser = function (id, callback) {
        $http.put(`${apiUrl}/${id}/disable`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.activateUser = function (id, callback) {
        $http.put(`${apiUrl}/${id}/enable`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.get = function (id, callback) {
        $http.get(`${apiUrl}/${id}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.searchPatient = function (texto, callback) {
        $http.get(`${apiUrl}/find`, { params: { text: texto } }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.addBasalConstants = function (id, isIntra, date, constants, callback) {
        $http.put(`${apiUrl}/${id}/constants`, { isIntra: isIntra, date: date, constants: constants }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.updateIntake = function (id, intake, callback) {
        $http.put(`${apiUrl}/${id}/intakes`, intake).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.updateIntraIntakes = function (id, date, intake, callback) {
        $http.put(`${apiUrl}/${id}/intraIntakes`, { day: date, intake: intake }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.getList = function (params, callback) {
        if (!callback) {
            [params, callback] = [callback, params];
        }

        $http.get(apiUrl, params ? { params: params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.endTreatment = function (patientId, info, callback) {
        $http.put(`${apiUrl}/${patientId}/treatment/ends`, info).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.getIntakeControl = function (params) {
        return $http.get(`${apiUrl}/intakecontrol`, params ? { params } : undefined);
    };

    usersService.sendToIntakeControl = function (patientId, info, callback) {
        $http.put(`${apiUrl}/${patientId}/treatment/intakecontrol`, info).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.takeOutFromIntakeControl = function (patientId, dayDate, action, callback) {
        $http.put(`${apiUrl}/${patientId}/treatment/takeoutintakecontrol`, { date: dayDate, action: action }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.cancelIntake = function(patientId, callback) {
        $http.put(`${apiUrl}/${patientId}/treatment/cancel`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.discharge = function (patientId, info, callback) {
        $http.put(`${apiUrl}/${patientId}/treatment/dicharge`, info).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    usersService.generatePassword = function (id, password, callback) {
        $http.put(`${apiUrl}/pass`, { userId: id, newPass: password }).then(
            (result) => callback(null, result),
            (err) => callback(err)
        );
    };

    function randomString(chars, length) {
        return Array
            .apply(null, new Array(length))
            .map(() => chars[Math.floor(Math.random() * chars.length)])
            .join("");
    }

    usersService.randomPass = function () {
        return [
            randomString("ABDCDEFGHAIJKLMNOPQRSTUVWXYZ", 6),
            randomString("0123456789", 4),
        ].join("");
    };

    usersService.getCofactors = function () {
        return [
            { id: "ejercicio", name: "Ejercicio físico" },
            { id: "ayunas", name: "Ayunas" },
            { id: "antiinflamatorios", name: "Toma de antiinflamatorios" },
            { id: "estres", name: "Estrés" },
            { id: "infeccion", name: "Infección" },
        ];
    };

    usersService.getReasons = function () {
        return [
            { id: "REACTION_LESS_SEVERE", name: "La reacción de ayer considero que fue menos grave de lo que considera la App." },
            { id: "REACTION_MORE_SEVERE", name: "La reacción de ayer considero que fue más grave de lo que considera la App." },
            { id: "DOCTOR_CONTACT", name: "He contactado con el médico y me ha indicado que tome otra dosis." },
            { id: "DOCTOR_INDICATION", name: "Según las indicaciones de mi médico debo tomar otra dosis." },
            { id: "PATIENT_DECISION", name: "Considero que debo tomar otra dosis por no encontrarme en una situación adecuada para la toma de la dosis prescrita." },
            { id: "PATIENT_MISTAKE", name: "He tomado otra dosis por error." },
            { id: "APP_INCORRECT_DOSE", name: "La App no me indica la dosis correcta." },
        ];
    };


    usersService.getSuggestings = function () {
        return ["Adrenalina", "Antihistamínico", "Broncodilatador", "Corticoide"];
    };

    return usersService;
};

UsersService.$inject = ["$http", "__env"];

export { USERS_SERVICE, UsersService };
