import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import { ERROR_MODAL_MODULE } from "services/modal/error";

import { APPOINTMENT_COMPONENT, appointmentComponent } from "./appointment.component";

import { UTILS_MODULE } from "services/utils";

const APPOINTMENT_MODULE = "appointmentModule";

angular.module(APPOINTMENT_MODULE, [
    "ui.router",
    API_CLIENT,
    UTILS_MODULE,
    ERROR_MODAL_MODULE
])
    .component(APPOINTMENT_COMPONENT, appointmentComponent);

export default APPOINTMENT_MODULE;
