import angular from "angular";

const PASSWORD_CHANGE_MODAL_MODULE = "passwordChangeModalModule";

import { PASSWORD_CHANGE_MODAL_SERVICE, PasswordChangeModalService } from "./password-change.service";

angular.module(PASSWORD_CHANGE_MODAL_MODULE, [])
    .factory(PASSWORD_CHANGE_MODAL_SERVICE, PasswordChangeModalService);

export { PASSWORD_CHANGE_MODAL_MODULE, PASSWORD_CHANGE_MODAL_SERVICE };
