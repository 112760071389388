import angular from "angular";
import "angular-ui-router";

import adminCenterStates from "./admin-center.states";

const ADMINCENTER_MODULE = "adminCenterModule";
import PERSONAL_MODULE from "./personal-medico";

angular.module(ADMINCENTER_MODULE, [
    "ui.router",
    PERSONAL_MODULE
])
    .config(adminCenterStates);

export default ADMINCENTER_MODULE;
