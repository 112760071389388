import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { PDF_MODULE } from "services/pdf";
import { UTILS_MODULE } from "services/utils";
import { REACTION_ALGORITHM_MODULE } from "services/reaction-algorithm";

import ADAPTATION_DOSE_MODULE from "./components/adaptation-dose";
import APPOINTMENT_MODULE from "./components/appointment";
import COMPARATIVE_MODULE from "./components/comparative";
import COMPARATIVE_MULTI_INTAKE_MODULE from "./components/comparative-multi-intake";
import COMPARATIVE_EDIT_INTAKE_MODULE from "./components/comparative-edit-intake";
import DOSE_DATA_MODULE from "./components/dose-data";
import EDIT_INTRA_PHASE_MODULE from "./components/edit-intra-phase";
import GRAPHIC_MODULE from "./components/graphic";
import HOSPITALARY_DOSE_MODULE from "./components/hospitalary-dose";
import PERSONAL_DATA_MODULE from "./components/personal-data";
import REACTIONS_MODULE from "./components/reaction-list";

import patientStates from "./patient.states";

const PATIENT_MODULE = "patientModule";

angular.module(PATIENT_MODULE, [
    "ui.router",
    API_CLIENT,
    PDF_MODULE,
    UTILS_MODULE,
    REACTION_ALGORITHM_MODULE,
    PERSONAL_DATA_MODULE,
    ADAPTATION_DOSE_MODULE,
    DOSE_DATA_MODULE,
    HOSPITALARY_DOSE_MODULE,
    APPOINTMENT_MODULE,
    REACTIONS_MODULE,
    COMPARATIVE_MODULE,
    COMPARATIVE_MULTI_INTAKE_MODULE,
    COMPARATIVE_EDIT_INTAKE_MODULE,
    GRAPHIC_MODULE,
    EDIT_INTRA_PHASE_MODULE,
]).config(patientStates);

export default PATIENT_MODULE;
