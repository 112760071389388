import adminAppTemplate from "./admin-app.template";

var adminAppStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-app",
        url: "/admin-app",
        abstract: true,
        template: adminAppTemplate,
    });
};

adminAppStates.$inject = ["$stateProvider"];

export default adminAppStates;
