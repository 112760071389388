import angular from "angular";
import "angular-ui-bootstrap";

import Appointment from "models/Appointment";
import AppointmentControllerViewModel from "models/AppoinmentControllerView";

import { TOKEN_SERVICE } from "services/token";
import { APPOINTMENTS_SERVICE } from "services/api-client";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { UTILS_DATE_SERVICE, UTILS_APPOINTMENT_SERVICE, UTILS_TREATMENT_SERVICE } from "services/utils";

class appointmentController {

    constructor(
        appointmentsService,
        tokenService,
        $errorModal,
        datesUtilsService,
        utilAppointmentService,
        treatmentUtils
    ) {
        this.token = tokenService.get();
        this.role = this.token.role;
        this.appointments = new AppointmentControllerViewModel();

        this.nextOrdinaryDateBackup = undefined;
        this.nextExtraordinaryDateBackup = undefined;

        this.errorModal = $errorModal;
        this.appointmentsService = appointmentsService;
        this.dateUtilsService = datesUtilsService;
        this.utilAppointmentService = utilAppointmentService;
        this.treatmentUtils = treatmentUtils;

    }

    $onInit() {
        this.loadAppointments();
    }

    loadAppointments() {
        this.appointmentsService.get(this.patientId, (err, res) => {
            const today = this.dateUtilsService.getCurrentTs();
            const patientAppointments = res.data;

            const previousOrdinaryAppointment = this.utilAppointmentService.getLastAppointmentBeforeDate(patientAppointments, ["ordinary"], today);
            const nextOrdinaryAppointment = this.utilAppointmentService.getFirstAppointmentAfterDate(patientAppointments, ["ordinary"], today);
            const nextExtraordinaryAppointments = this.utilAppointmentService.filterAppointmentByType(patientAppointments, ["extraordinary"]);

            this.appointments = new AppointmentControllerViewModel();
            this.appointments.setOrdinaryLastDate(previousOrdinaryAppointment);
            this.appointments.setOrdinaryNextDate(nextOrdinaryAppointment);
            this.appointments.setExtraordinaryNexts(nextExtraordinaryAppointments);
        });
    }

    updateCreateAppointment(appointment, type) {
        const day = this.dateUtilsService.compoundDate(
            new Date(appointment.date),
            new Date(appointment.time)
        );

        if (!day) return;

        if (day.getTime() < this.dateUtilsService.getCurrentTs()) {
            this.errorModal.show("Error", "No se permite crear una cita con fecha en el pasado");
        }

        if (appointment.id) {
            this.updateAppointment(appointment.id, day, type);
        } else {
            this.createAppointment(day, type);
        }

    }

    createAppointment(day, type) {
        const newApppoiment = new Appointment(
            undefined,
            day.getTime(),
            type,
            "stablished",
            this.centerId,
            this.currentTreatment.doctorId,
            this.patientId
        );

        this.appointmentsService.create(newApppoiment, (err) => {
            if (err) {
                this.errorModal.show("Error", "Ha ocurrido un error al crear la cita.");
                return console.error(err);
            }

            this.refreshData();
        });
    }

    updateAppointment(appointmentId, day, type) {
        const newAppointment = new Appointment(
            appointmentId,
            day.getTime(),
            type,
            "modified",
            this.centerId,
            this.currentTreatment.doctorId,
            this.patientId
        );

        this.appointmentsService.update(newAppointment, (err) => {
            if (err) {
                this.errorModal.show("Error", "Ha ocurrido un error al actualizar la cita");
                return console.error(err);
            }

            this.refreshData();
        });
    }

    hasCurrentTreatmentEnd() {
        return this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    refreshData() {
        this.loadAppointments();
        this.cancelEdit(this.appointments.ordinary);
        this.cancelEdit(this.appointments.extraordinary);
    }

    cancelEdit(section) {
        section.onEdit = false;

        this.appointments.setOrdinaryNextDate(this.nextOrdinaryDateBackup);
        this.nextOrdinaryDateBackup = undefined;
        this.appointments.setExtraOrdinaryNextAppointmentDate(undefined);
        this.nextExtraordinaryDateBackup = undefined;
    }

    editOrdinaryAppointment() {
        this.appointments.ordinary.onEdit = true;
        this.nextOrdinaryDateBackup = angular.copy(this.appointments.ordinary.next);
    }

    editExtraordinaryAppointment(item) {
        this.appointments.extraordinary.onEdit = true;
        this.appointments.setExtraOrdinaryNextAppointmentDate(item);
        this.nextExtraordinaryDateBackup = angular.copy(item);
    }

    deleteAppointment(appointment) {
        if (appointment && appointment._id) {
            this.appointmentsService.delete(appointment._id, (err) => {
                if (err) {
                    console.error(err);
                    this.errorModal.show("Error", "El personal de enfermería no puede cancelar una cita.");
                }
                this.refreshData();
            });
        }
    }

    openDatePicker(datepicker) {
        datepicker.onEdit = true;
    }
}

appointmentController.$inject = [
    APPOINTMENTS_SERVICE,
    TOKEN_SERVICE,
    ERROR_MODAL_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_APPOINTMENT_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default appointmentController;
