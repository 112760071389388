import angular from "angular";

const INTAKE_MODAL_MODULE = "intakeActionModule";

import { INTAKE_MODAL_SERVICE, IntakeModalService } from "./intake-modal.service";

angular.module(INTAKE_MODAL_MODULE, [])
    .factory(INTAKE_MODAL_SERVICE, IntakeModalService);

export { INTAKE_MODAL_MODULE, INTAKE_MODAL_SERVICE };
