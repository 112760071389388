import reactionListTemplate from "./reaction.template";
import reactionListController from "./reaction.controller";

const REACTIONS_COMPONENT = "reactionList";

const reactionListComponent = {
    template: reactionListTemplate,
    controller: reactionListController,
    bindings: {
        patient: "=",
        onChange: "&",
        selectedTreatment: "<"
    }
};

export { REACTIONS_COMPONENT, reactionListComponent };
