import angular from "angular";
import "angular-ui-router";
import "angular-chart-lb.js";

import { API_CLIENT } from "services/api-client";

import { GRAPHIC_COMPONENT, graphicComponent } from "./graphic.component";

const GRAPHIC_MODULE = "graphicModule";

angular.module(GRAPHIC_MODULE, [
    "ui.router",
    "chart-lb.js",
    API_CLIENT,
])
.component(  GRAPHIC_COMPONENT, graphicComponent  );

export default GRAPHIC_MODULE;
