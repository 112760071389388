import angular from "angular";
import "angular-ui-bootstrap";
import "angular-chart-lb.js";
import moment from "moment";

import { UTILS_DATE_SERVICE, UTILS_TREATMENT_SERVICE } from "services/utils";
import { DOSE_DATA_SERVICE } from "../dose-data/dose-data.service";
import configuration from "./graphics.config";

const CHART_PATIENT_DATA_INDEX = 0;
const CHART_PROTOCOL_DATA_INDEX = 1;

class graphicController {

    constructor(dateUtils, treatmentUtils, doseDataService) {
        this.patient;
        this.opnFrom = false;
        this.opnTo = false;
        this.config = configuration;
        this.startDate = undefined;
        this.dateUtils = dateUtils;
        this.treatmentUtils = treatmentUtils;
        this.doseDataService = doseDataService;
        this.colors = [
            {
                spanGaps: false,
                backgroundColor: "rgba(247,70,74,0.35)",
                borderColor: "rgb(247,70,74)",
                pointBackgroundColor: "rgb(247,70,74)",
                pointBorderColor: "rgb(247,70,74)",
                tension: 0,
                fill: true
            },
            {
                backgroundColor: "rgba(208,222,250,0.5)",
                borderColor: "rgb(10,40,100)",
                pointBackgroundColor: "rgb(10,40,100)",
                pointBorderColor: "rgb(10,40,100)",
                tension: 0,
                fill: true,
            },
            {
                backgroundColor: "rgba(255, 159, 64,0.5)",
                borderColor: "rgb(255, 159, 64)",
                pointBackgroundColor: "rgb(255, 159, 64)",
                pointBorderColor: "rgb(255, 159, 64)",
                tension: 0,
                fill: true,
            }
        ];
    }

    onClick(e, legendItem) {
        const legends = this.chart.legend;

        const ci = this.chart;
        const meta = ci.getDatasetMeta(legendItem.datasetIndex);

        meta.hidden = meta.hidden === null ? !ci.data.datasets[legendItem.datasetIndex].hidden : null;

        if (legendItem.text[0] === "Paciente") {
            for (let i = 0; i < legends.legendItems.length; i++) {
                if (legends.legendItems[i].text === "") {
                    const ci = this.chart;
                    const meta = ci.getDatasetMeta(i);

                    meta.hidden = meta.hidden === null ? !ci.data.datasets[i].hidden : null;
                }
            }
        }

        this.chart.update();
    }

    generateLabels(chart) {
        const data = chart.data;

        return Array.isArray(data.datasets) ? data.datasets.map(function (dataset, i) {
            return {
                text: dataset.label,
                fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
                hidden: !chart.isDatasetVisible(i),
                lineCap: dataset.borderCapStyle,
                lineDash: dataset.borderDash,
                lineDashOffset: dataset.borderDashOffset,
                lineJoin: dataset.borderJoinStyle,
                lineWidth: dataset.borderWidth,
                strokeStyle: dataset.borderColor,
                pointStyle: dataset.pointStyle,

                // Below is extra data used for toggling the datasets
                datasetIndex: i
            };
        }, this) : [];
    }

    $onChanges() {
        this.today = this.dateUtils.getCurrentDate();
        this.config.dateFilter.options2.maxDate = this.today;
        this.loadInitValues(undefined, undefined);
        this.initFilters();
    }

    initFilters() {
        if (this.patient.treatments.length > 0
            && this.selectedTreatment.intra
            && this.selectedTreatment.intra.days
            && this.selectedTreatment.intra.days.length > 0
        ) {
            const firstIntraDay = this.selectedTreatment.intra.days[0];
            this.fromDate = new Date(firstIntraDay.date);
        } else if (this.weeks !== undefined) {
            if (this.fromDate === undefined) {
                this.weeks = this.selectedTreatment.weeks.sort((a, b) => a.date - b.date);
            }
            this.fromDate = this.weeks ? new Date(this.weeks[0].date) : this.today;

        }
        this.toDate = this.today;
        this.filter();
    }

    loadInitValues(from, to) {
        this.reset();

        this.startDate = this.selectedTreatment.weeks[0].date;

        this.initTreatments(this.selectedTreatment, from, to);
    }

    reset() {
        this.labels = [];
        this.data = [];

        this.configLegendsOptions();

        this.config.graphics.datasetOverride = [{ label: ["Paciente"], lineTension: 0 }];
        this.config.graphics.series = [];
        this.config.graphics.colors = [];
    }

    configLegendsOptions() {
        this.config.graphics.options.legend = {
            display: true,
            onClick: this.onClick,
            labels: {
                generateLabels: this.generateLabels
            }
        };
    }

    initTreatments(treatment, from, to) {
        this.initTreatment(treatment, from, to, 0);
    }

    initTreatment(treatment, from, to, index) {
        const newTreatment = this.desplaceTreatmentGraph(treatment, from);

        this.data.push(angular.copy(newTreatment));

        this.configLineGraph(0, index === 0 ? "Paciente" : "");

        this.data.push(newTreatment);

        this.configLineGraph(index + 1, treatment.type);

        this.weeks = treatment.weeks.sort((a, b) => a.date - b.date);

        const doseUnit = this.getDoseUnit(this.weeks[0].dose);
        if (doseUnit) {
            this.config.graphics.options.scales.yAxes[0].scaleLabel.labelString = `Dosis en ${doseUnit}`;
        }

        const intraDays = treatment.intra.days.sort((a, b) => a.date - b.date);
        if (intraDays.length > 0) {
            this.initDayForIntra(intraDays, from, to);
        }

        const lastIntraDayDate = intraDays[intraDays.length - 1].date;

        for (let i = 0; i < this.weeks.length && this.weeks[i].date <= this.today.getTime(); i++) {
            this.initWeek(this.weeks[i], from, to, treatment.ends, lastIntraDayDate);
        }
    }

    configLineGraph(lineColorIndex, text) {
        const graphics = this.config.graphics;

        graphics.series.push(text);
        graphics.colors.push(this.colors[lineColorIndex]);
    }

    desplaceTreatmentGraph(treatment, from, to) {
        const newTreatment = [];
        const deltaDays = this.calculeDelta(treatment.starts, from, to);

        for (let i = 0; i < deltaDays; i++) {
            newTreatment.push({});
        }

        return newTreatment;
    }

    calculeDelta(start, from, to) {
        if (this.startDate < start) {
            let inicio = moment.utc(this.startDate);
            let fin = moment.utc(start);

            if (this.startDate < from) {
                inicio = moment.utc(from);
            }

            fin = moment.utc(to);

            return fin.diff(inicio) / 86400000;
        }
    }

    initWeek(week, from, to, ends, lastIntraDayDate) {
        for (let i = 0; i < week.days.length; i++) {
            const day = week.days[i];
            if (this.isInsideTimeFilter(day.date, from, to)) {
                if (!lastIntraDayDate || moment(day.date).utc().isAfter(lastIntraDayDate, "day")) {
                    this.initDay(day, week.dose, lastIntraDayDate);
                }
            }
        }
    }

    initDayForIntra(intraDays, from, to) {
        for (let i = 0; i < intraDays.length; i++) {
            const intraDay = intraDays[i];

            if (!this.isInsideTimeFilter(intraDay.date, from, to)) {
                continue;
            }

            const unit = this.treatmentUtils.getTreatmentUnit(this.selectedTreatment);
            const lastEndureDose = this.doseDataService.getDayLastEnduredDose(intraDay, true, unit);

            if (lastEndureDose == undefined) {
                this.data[CHART_PATIENT_DATA_INDEX].push(0);
            } else {
                this.data[CHART_PATIENT_DATA_INDEX].push(eval(lastEndureDose));
            }

            const lastIntakeOfDay = this.getLastIntakeOfTheDay(intraDay);
            if (lastIntakeOfDay == undefined) {
                this.data[CHART_PROTOCOL_DATA_INDEX].push(0);
            } else {
                this.data[CHART_PROTOCOL_DATA_INDEX].push(this.getDoseValue(lastIntakeOfDay.protocolDose));
            }

            const correctedIntakeDate = intraDay.date + this.dateUtils.getTimezoneCorrector();
            const parsedIntakeDate = this.parseDate(correctedIntakeDate);
            this.labels.push(`${parsedIntakeDate} (*)`);
        }
    }

    initDay(day, dose) {
        const lastIntakeOfDay = this.getLastIntakeOfTheDay(day);

        if (!lastIntakeOfDay || lastIntakeOfDay.state == "notTaken") {
            this.data[CHART_PATIENT_DATA_INDEX].push(0);
        } else if (lastIntakeOfDay) {
            this.data[CHART_PATIENT_DATA_INDEX].push(this.getDoseValue(lastIntakeOfDay.patientIntake));
        }

        this.data[CHART_PROTOCOL_DATA_INDEX].push(this.getDoseValue(dose));

        this.labels.push(this.parseDate(day.date + this.dateUtils.getTimezoneCorrector()));
    }

    isInsideTimeFilter(date, from, to) {
        return (
            (from === undefined && (to === undefined || date <= to)) ||
            (to === undefined && date >= from) ||
            (to !== undefined && date <= to && from !== undefined && date >= from)
        );
    }

    getLastIntakeOfTheDay(day) {
        if (day.intakes && day.intakes.length > 0) {
            return day.intakes[day.intakes.length - 1];
        } else {
            return undefined;
        }
    }

    getDoseValue(literal) {
        return literal && literal !== "outOfProtocol"
            ? eval(literal.split(" ")[0])
            : undefined;
    }

    getDoseUnit(literal) {
        return literal && literal !== "outOfProtocol"
            ? literal.split(" ")[1]
            : undefined;
    }

    parseDate(date) {
        return moment.utc(date).format("DD / MM / YY");
    }

    filter() {
        if (!this.fromDate && !this.toDate) {
            this.loadInitValues();
        }

        const fromMillis = this.getFromDateFilterInMillis();
        const toMillis = this.getToDateFilterInMillis();

        if (fromMillis > toMillis) {
            return;
        }

        this.loadInitValues(fromMillis, toMillis);
    }

    getFromDateFilterInMillis() {
        return this.fromDate
            ? this.fromDate.getTime()
            : undefined;
    }

    getToDateFilterInMillis() {
        if (!this.toDate) {
            return undefined;
        }

        const toDate = this.toDate;
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);

        const toDateInMillis = toDate.getTime();
        const todayInMillis = this.today.getTime();

        return toDateInMillis > todayInMillis
            ? todayInMillis
            : toDateInMillis;
    }

    openDatepicker1() {
        this.opnFrom = true;
    }

    openDatepicker2() {
        this.opnTo = true;
    }

}

graphicController.$inject = [
    UTILS_DATE_SERVICE,
    UTILS_TREATMENT_SERVICE,
    DOSE_DATA_SERVICE
];

export default graphicController;
