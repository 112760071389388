import "angular-ui-bootstrap";

import { TOKEN_SERVICE } from "services/token";
import { UTILS_DATE_SERVICE } from "services/utils";

class comparativeMultiIntakeController {
    constructor(
        $window,
        tokenService,
        dateUtils
    ) {
        this.token = tokenService.get();
        this.role = this.token.role;
        this.window = $window;
        this.dateUtils = dateUtils;
    }

    $onChanges(changes) {
        if (changes) {
            this.dayEditForm = Object.assign({}, this.dayToEdit);
            this.intakes = this.dayEditForm.intakes;
        }
    }

    editIntake(intake) {
        this.onEnterEditMode({intake: intake});
    }

    isMultiIntake(day) {
        return day.intakes && day.intakes.length > 1;
    }
}

comparativeMultiIntakeController.$inject = [
    "$window",
    TOKEN_SERVICE,
    UTILS_DATE_SERVICE
];

export default comparativeMultiIntakeController;
