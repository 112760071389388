import { LOGGER_SERVICE } from "services/logger";

const TOKEN_SERVICE = "tokenService";

var TokenService = function (logger, __env, $sessionStorage) {
    var tokenService = {};

    tokenService.get = function (key) {
        if (!$sessionStorage[__env.tokenLocalStorage]) return;

        let codedPayload = $sessionStorage[__env.tokenLocalStorage].split(".")[1];
        if (!codedPayload) return;

        try {
            let payload = JSON.parse(atob(codedPayload));
            return key ? payload[key] : payload;
        }
        catch (e) {
            logger.debug("services/token/token.service.js", `tokenService.get("${key}")`, "Invalid token. Can't be parsed", codedPayload);
            return;
        }
    };

    tokenService.getRaw = function () {
        return $sessionStorage[__env.tokenLocalStorage];
    };

    tokenService.set = function (token) {
        $sessionStorage[__env.tokenLocalStorage] = token;
    };

    tokenService.delete = function () {
        delete $sessionStorage[__env.tokenLocalStorage];
    };

    return tokenService;
};

TokenService.$inject = [LOGGER_SERVICE, "__env", "$sessionStorage"];

export { TOKEN_SERVICE, TokenService };
