import { CENTERS_SERVICE, USERS_SERVICE } from "services/api-client";

class CenterController {
    constructor($scope, $window, $stateParams, centersService, userService) {
        $scope.newCenterForm = {};
        $scope.userCenterForm;
        $scope.centerId = $stateParams.id;

        centersService.get($scope.centerId, (err, res) => {
            if (err) return console.error(err);
            $scope.center = res.data;
        });

        function loadAdmins() {
            centersService.getUsers($scope.centerId, (err, res) => {
                if (err) return console.error(err);
                $scope.users = res.data;
            });
        };

        loadAdmins();

        $scope.openUserModal = function (user) {
            $scope.userForm = Object.assign({}, user);
            $window.$("#UserModal").modal("toggle");
        };

        $scope.submitGuardarUser = function () {
            $scope.userCenterForm.$setSubmitted();
            if ($scope.userCenterForm.$invalid) {
                return;
            }

            let createCeterAdmin = {
                fullName: $scope.userForm.name,
                email: $scope.userForm.email,
                nick: $scope.userForm.nick,
                pass: $scope.userForm.password,
                profiles: [
                    {
                        role: "CenterAdmin",
                        centerId: $scope.center._id,
                        active: true
                    }
                ]
            };
            console.log(createCeterAdmin);
            userService.create(createCeterAdmin, (err, res) => {
                if (err) {
                    $scope.errorMessage = "Error al crear administrador del centro";
                    return;
                }
                //centersService.asignAdmin(res.id);
                loadAdmins();
                $window.$("#UserModal").modal("toggle");
            });
        };
    }
}

CenterController.$inject = ["$scope", "$window", "$stateParams", CENTERS_SERVICE, USERS_SERVICE];

export default CenterController;
