import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import { PROTOCOLS_HEAD_COMPONENT, protocolsHeadComponent } from "./protocols-head.component";

const PROTOCOLS_HEAD_MODULE = "protocols-headModule";

angular.module(PROTOCOLS_HEAD_MODULE, [
    "ui.router",
    API_CLIENT,
])
.component(PROTOCOLS_HEAD_COMPONENT, protocolsHeadComponent);

export default PROTOCOLS_HEAD_MODULE;
