import angular from "angular";

const PDF_MODULE = "pdfModule";

import { PDF_SERVICE, PdfService } from "./pdf.service";

angular.module(PDF_MODULE, [])
    .factory(PDF_SERVICE, PdfService);

export { PDF_MODULE, PDF_SERVICE };
