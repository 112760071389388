import informPrevisualizationTemplate from "./inform-previsualization.template";
import InformPrevisualizationController from "./inform-previsualization.controller";

const INFORM_PREVISUALIZATION_COMPONENT = "informPrevisualization";

const informPrevisualizationComponent = {
    template: informPrevisualizationTemplate,
    controller: InformPrevisualizationController,
    bindings: {
        graphic: "<data",
        filters: "<filters",
    }
};

export { INFORM_PREVISUALIZATION_COMPONENT, informPrevisualizationComponent };
