import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import {  COMPARATIVE_EDIT_INTAKE_COMPONENT, comparativeEditIntakeComponent } from "./comparative-edit-intake.component";

const COMPARATIVE_EDIT_INTAKE_MODULE = "comparativeEditIntakeModule";

angular.module(COMPARATIVE_EDIT_INTAKE_MODULE, [
    "ui.router",
    API_CLIENT,
])
.component(  COMPARATIVE_EDIT_INTAKE_COMPONENT, comparativeEditIntakeComponent );

export default COMPARATIVE_EDIT_INTAKE_MODULE;
