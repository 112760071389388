const MAPPER_SERVICE = "mapperService";

const MapperService = function () {
    const mapService = {};

    mapService.toPatientFront = function (userFromBack) {
        const patientFront = {
            history: userFromBack.history,
            nick: userFromBack.nick,
            firstName: userFromBack.firstName != undefined
                ? userFromBack.firstName
                : userFromBack.fullName,
            lastName1: userFromBack.lastName1,
            lastName2: userFromBack.lastName2,
            pass: userFromBack.pass,
            fullName: userFromBack.fullName,
            email: userFromBack.email,
            address: userFromBack.address,
            phone: userFromBack.phone,
            birthDate: userFromBack.birthDate,
            gender: userFromBack.gender,
            weight: userFromBack.weight,
            profiles: userFromBack.profiles,
        };

        patientFront.treatments = undefined;
        //delete patientFront.treatments;

        if (userFromBack.treatments.length > 0) {
            userFromBack.treatments.sort((a, b) => a.starts - b.starts);
            let treatment = userFromBack.treatments.find(treatment => !!treatment.active);
            if (!treatment) {
                treatment = userFromBack.treatments.pop();
            }
            patientFront.treatments = {
                allergen: treatment.allergen,
                type: treatment.type,
                starts: treatment.starts,
                doctorId: treatment.doctorId,
            };
            patientFront.reacAsis = treatment.reacAsis;
        }

        return patientFront;
    };

    return mapService;
};

export { MAPPER_SERVICE, MapperService };
