import intakeDetailTemplate from "./intake-detail.template";
import intakeDetailController from "./intake-detail.controller";

const INTAKE_USER_DETAIL_COMPONENT = "intakeUserDetail";

const intakeUserDetailComponent = {
    template: intakeDetailTemplate,
    controller: intakeDetailController,
};

export { INTAKE_USER_DETAIL_COMPONENT, intakeUserDetailComponent };
