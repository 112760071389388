class DoseSeparator {

    constructor(oldDose, newDose) {
        this.composed = true;
        this.oldDose = oldDose;
        this.newDose = newDose;
    }

}

export default DoseSeparator;
