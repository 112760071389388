import personalDataTemplate from "./personal-data.template";
import personalDataController from "./personal-data.controller";

const PERSONAL_DATA_COMPONENT = "personalData";

const personalDataComponent = {
    template: personalDataTemplate,
    controller: personalDataController,
    bindings: {
        patient: "=",
        currentTreatment: "=",
        getSelectedTreatments: "&",
        selectedTreatment: "=",
        patientTreatments: "=",
        edicion: "<",
    }
};

export { PERSONAL_DATA_COMPONENT, personalDataComponent };
