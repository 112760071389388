import infoTemplate from "./info.template";

const INFO_MODAL_SERVICE = "infoModalService";

var InfoModalService = function ($uibModal) {
    var infoModalService = {};

    infoModalService.show = function(title, msg, callback = () =>{}) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: infoTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                $scope.title = "Atención";
                if(Array.isArray(msg)){
                    $scope.msg = msg;
                } else {
                    $scope.msg = [msg];
                }

                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };


    return infoModalService;
};

InfoModalService.$inject = ["$uibModal"];

export { INFO_MODAL_SERVICE, InfoModalService };
