import graphicTemplate from "./graphic.template";
import graphicController from "./graphic.controller";

const GRAPHIC_COMPONENT = "graphicChart";

const graphicComponent = {
    template: graphicTemplate,
    controller: graphicController,
    bindings: {
        patient: "=",
        onChange: "&",
        selectedTreatment: "<"
    }
};

export { GRAPHIC_COMPONENT, graphicComponent };
