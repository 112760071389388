import PdfTemplateFactory from "models/pdfs/pdfTemplateFactory";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PDF_SERVICE = "pdfService";

var PdfService = function () {
    var pdfService = {};

    pdfService.createPassword = function (user, pass, download) {
        let pdfTemplateFactory = new PdfTemplateFactory();

        if (download) {
            return pdfMake.createPdf(pdfTemplateFactory.getPasswordTemplate(user,pass)).download("passsword.pdf");
        }

        pdfMake.createPdf(pdfTemplateFactory.getPasswordTemplate(user,pass)).open();
    };

    pdfService.reactionInfo = function (treatments, download) {
        const pdfTemplateFactory = new PdfTemplateFactory();
        const template = pdfTemplateFactory.getReactionsTemplate(treatments);

        if (download) {
            return pdfMake.createPdf(template).download("info-reacciones.pdf");
        }

        pdfMake.createPdf(template).open();
    };

    return pdfService;
};

PdfService.$inject = [];

export { PDF_SERVICE, PdfService };
