class ComparativeWeek {

    constructor(week, weekIndex) {
        this.weekNumber = weekIndex;
        this.dose = week.dose;
        this.doctorDose = week.doctorDoses ? week.doctorDoses[week.doctorDoses.length - 1] : " - ";
        this.volume = week.volume;
        this.date = week.date;
        this.days = [];
    }

    setDose(dose) {
        this.dose = dose;
    }

    addDay(day) {
        this.days.push(day);
    }

}

export default ComparativeWeek;
