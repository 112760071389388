import angular from "angular";
import "angular-ui-bootstrap";
import { CENTERS_SERVICE, USERS_SERVICE, PROTOCOLS_SERVICE, APPOINTMENTS_SERVICE, MAPPER_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";
import { UTILS_TREATMENT_SERVICE } from "services/utils";
import { PDF_SERVICE } from "services/pdf";
import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE } from "services/modal/password-change-confirmation";
import { INFO_MODAL_SERVICE } from "services/modal/info";
import { PROTOCOL_MODAL_SERVICE } from "services/modal/protocol";
import { UTILS_DATE_SERVICE } from "services/utils";

import moment from "moment";
import popupMessage from "models/popupMessage";

class patientDataController {

    constructor(
        $scope,
        $state,
        dateUtils,
        centersService,
        userService,
        protocolService,
        appointmentsService,
        confirmationService,
        mapperService,
        pdfService,
        tokenService,
        passwordChangeConfirmationModal,
        $errorModal,
        $infoModal,
        $protocolModal,
        utilsTreatmentService
    ) {
        this.token = tokenService.get();
        this.role = this.token.role;
        this.editButtons = {};
        this.editAreas = {};
        this.doctors = {};
        this.protocols = {};
        this.form = {};
        this.opnBirth = false;
        this.opnInit = false;
        this.hasDoctorRole = this.role == "Doctor";
        this.hasNurseRole = this.role == "Nurse";
        this.itoSectionIsEditable = false;
        this.editDisable = false;
        this.applicationModeList = ["Oral", "Inhalado", "Intramuscular", "Sublingual"];

        this.changeTreatment = {
            editing: false,
            oldTreatment: "",
            newTreatment: "",
        };

        this.controls = {
            firstName: false,
            lastName1: false,
            lastName2: false,
            birth: false,
            history: false,
            address: false,
            phone: false,
            email: false,
            tipo: false,
            protocolo: false,
            inicioTratamiento: false,
            horaCita: false,
            medico: false,
            sexo: false,
            peso: false,

            antihistaminicoMedicineCenter: false,
            antihistaminicoDoseCenter: false,
            antihistaminicoApplyCenter: false,

            corticoideMedicineCenter: false,
            corticoideDoseCenter: false,
            corticoideApplyCenter: false,

            broncoMedicineCenter: false,
            broncoDoseCenter: false,
            broncoApplyCenter: false,

            adrenalinaMedicineCenter: false,
            adrenalinaDoseCenter: false,
            adrenalinaApplyCenternist: false,

            antihistaminicoMedicineHome: false,
            antihistaminicoDoseHome: false,
            antihistaminicoApplyHome: false,

            corticoideMedicineHome: false,
            corticoideDoseHome: false,
            corticoideApplyHome: false,

            broncoMedicineHome: false,
            broncoDoseHome: false,
            broncoApplyHome: false,

            adrenalinaMedicineHome: false,
            adrenalinaDoseHome: false,
            adrenalinaApplyHome: false
        };

        this.initValues = function () {
            userService.getList({ role: "Doctor", active: true }, (err, doctors) => {
                if (err) {
                    return console.error(err);
                }
                this.doctors = doctors.data;
            });
            protocolService.getAllergens((err, allergens) => {
                if (err) {
                    return console.error(err);
                }
                this.allergens = allergens.data;
            });
            centersService.getReacAsis(this.token.centerId, (err, reacasis) => {
                if (err) {
                    return console.error(err);
                }
                this.form.reacAsis = {};
                this.form.reacAsis.hospital = reacasis.data;
            });
        };

        this.$onInit = function () {
            this.initValues();
        };

        $scope.isInMinimalArray = function (campo) {
            if (!$scope.showMinimals || !$scope.showMinimals.atributos) {
                return false;
            }
            return $scope.showMinimals.atributos.includes(campo);
        };

        this.submitMainData = function () {
            this.patientForm.$setSubmitted();

            if (this.patientForm.$invalid) {
                this.validateForm(this.patientForm, this.controls);
                return;
            }

            if (!this.patient) {
                this.executeCreate();
            } else {
                this.executeUpdate();
            }
        };

        this.executeCreate = function () {
            const patient = angular.copy(this.form);

            if (patient.treatments && patient.treatments.starts) {
                this.setHHMMtoInitTreatment(patient);
            }

            patient.birthDate = new Date(patient.birthDate).getTime();
            patient.profiles = [{
                role: "Patient",
                centerId: this.token.centerId,
                active: true
            }];
            patient.nick = null;
            patient.pass = null;

            const isSensitive = this.isSensitiveData(patient);
            if (isSensitive || (this.hasNurseRole && isSensitive)) {
                confirmationService.doblePlusMedic((accepted, data) => {
                    if (!accepted) {
                        return;
                    }

                    patient.auth = {
                        pass: data.pass,
                        user: data.user
                    };

                    this.doCreatePetition(patient);
                });
            } else {
                this.doCreatePetition(patient);
            }
        };

        this.executeUpdate = function () {
            if (this.patientForm.$invalid) {
                return;
            }

            if (this.form.treatments.starts || this.time) {
                this.setHHMMtoInitTreatment(this.form);
            }

            if (this.form.birthDate) {
                this.form.birthDate = new Date(this.form.birthDate).getTime();
            }

            const preEdition = angular.copy(this.preEdition);
            const postEdition = angular.copy(this.form);
            // Remove autogenerated attributes to compare the two objects
            delete preEdition.profiles;
            delete preEdition.pass;
            delete preEdition.appointments;
            delete postEdition.profiles;
            delete postEdition.age;
            delete postEdition.pass;

            if (angular.equals(postEdition, preEdition)) {
                return;
            }

            if (preEdition.treatments === undefined) {
                // To avoid error in processObject()
                delete preEdition.treatments;
            }

            const modifiedFields = this.processObject(postEdition, preEdition);

            if (Object.keys(modifiedFields).length == 0) { // no changes
                this.hideEdit("mainData");
                this.cancelEditArea("mainData");
                return;
            }

            const isSensitive = this.isSensitiveData(modifiedFields);

            if (isSensitive) {
                modifiedFields.treatments = this.getSensitiveData(modifiedFields).treatments;
            }

            if (modifiedFields.treatments) {
                if (!modifiedFields.treatments.doctorId && preEdition.treatments && preEdition.treatments.doctorId) {
                    modifiedFields.treatments.doctorId = preEdition.treatments.doctorId;
                }

                if (!modifiedFields.treatments.starts && preEdition.treatments && preEdition.treatments.starts) {
                    modifiedFields.treatments.starts = preEdition.treatments.starts;
                }

                if (!modifiedFields.treatments.starts && this.form.treatments && this.form.treatments.starts) {
                    modifiedFields.treatments.starts = this.form.treatments.starts;
                }

                if (modifiedFields.treatments.starts && !modifiedFields.treatments.doctorId) {
                    $errorModal.show("Error", "No se ha creado/modificado los datos del paciente. Para poder crear la primera cita al paciente, es necesario que tenga asignado un médico.");
                    return;
                }
            }

            if (isSensitive) {
                confirmationService.doblePlusMedic((accepted, data) => {
                    if (!accepted) {
                        return;
                    }

                    modifiedFields.auth = {
                        pass: data.pass,
                        user: data.user
                    };

                    this.doUpdatePetition(modifiedFields);
                });
            } else {
                this.doUpdatePetition(modifiedFields);
            }
        };

        this.doCreatePetition = function (patient) {
            if (patient.treatments && patient.treatments.starts && !patient.treatments.doctorId) {
                $errorModal.show("Error", "Para poder crear un paciente con cita es necesario asignarlo a un médico.");
                return;
            }

            if (patient.treatments && !patient.treatments.starts && patient.treatments.doctorId) {
                $errorModal.show("Error", "Para poder asignar un médico al paciente es necesario establecerle una cita.");
                return;
            }

            if (patient.treatments && !patient.treatments.type && !patient.treatments.doctorId) {
                delete patient.treatments;
            }

            userService.create(patient, (err, res) => {
                const message = err
                    ? new popupMessage(err.status, "Se ha producido un error al crear al paciente")
                    : new popupMessage(res.status, "El paciente ha sido creado correctamente");

                this.showMessage($errorModal, message);

                userService.get(res.data.data._id, (err, result) => {
                    if (err) {
                        const message = err
                        ? new popupMessage(err.status, "Se ha producido un error al crear el paciente")
                        : new popupMessage(res.status, "El paciente ha sido creado correctamente");

                        this.showMessage($errorModal, message);

                        return console.error(err);
                    }

                    patient._id = result.data._id;

                    if (patient.treatments) {
                        this.patientTreatments = result.data.treatments.sort((a, b) => a.starts - b.starts);

                        this.createFirstAppointmentForCreatedPatient(patient);

                    } else {
                        this.toTreatmentView(patient._id);
                    }
                });
            });
        };

        /**
         * Creates the first appointment of a new patient.
         *
         * @param {Object} newPatient
         */
        this.createFirstAppointmentForCreatedPatient = function (newPatient) {
            if (newPatient.treatments && newPatient.treatments.starts) {
                const appoiment = {
                    date: newPatient.treatments.starts,
                    type: "ordinary",
                    state: "stablished",
                    centerId: newPatient.profiles[0].centerId,
                    doctorId: newPatient.treatments.doctorId,
                    userId: newPatient._id
                };

                appointmentsService.create(appoiment, (err) => {
                    if (err) {
                        $errorModal.show("Error", "Ha ocurrido un error en la creación de la cita");
                        return console.error(err);
                    }

                    if (this.hasNurseRole) {
                        this.toCalendarView();
                    } else {
                        this.toTreatmentView(newPatient._id);
                    }
                });
            } else {
                this.toTreatmentView(newPatient._id);
            }
        };

        /**
         * Creates the first appointment of an modified Patient.
         *
         * @param {Object} modifiedFields
         */
        this.createFirstAppointmentForUpdatedPatient = function (modifiedFields) {
            if (modifiedFields.treatments && modifiedFields.treatments.starts) {
                const appointment = {
                    date: modifiedFields.treatments.starts,
                    type: "ordinary",
                    state: "stablished",
                    centerId: this.patient.profiles[0].centerId,
                    doctorId: modifiedFields.treatments.doctorId,
                    userId: this.patient._id
                };

                if (this.patient.treatments && this.patient.treatments[0] && this.patient.treatments[0].starts) {
                    appointment.old = this.patient.treatments[0].starts;
                }

                appointmentsService.create(appointment, (err) => {
                    if (err) {
                        $errorModal.show("Error", "Ha ocurrido un error en la creación de la cita");
                        return console.error(err);
                    }

                    this.toTreatmentView(this.patient._id);
                });
            } else {
                this.toTreatmentView(this.patient._id);
            }
        };

        this.toTreatmentView = function (id) {
            $state.go("app.admin-medical.patient-detail", { id: id }, { reload: true });
            this.hideEdit("mainData");
            this.cancelEditArea("mainData");
        };

        this.toCalendarView = function (id) {
            $state.go("app.admin-medical.calendar", { id: id }, { reload: true });
            this.hideEdit("mainData");
            this.cancelEditArea("mainData");
        };

        /**
         * Ensure that if any part of the Patient's name is updated,
         * the rest of parts are also updated.
         *
         * This is necessary for the correct generation of `fullName` in API.
         *
         * @param {Object} modifiedFields
         */
        this.checkForNamesUpdate = function (modifiedFields) {
            if (modifiedFields.firstName || modifiedFields.lastName1 || modifiedFields.lastName2) {
                modifiedFields.firstName = modifiedFields.firstName != undefined
                    ? modifiedFields.firstName
                    : this.patient.firstName;
                modifiedFields.lastName1 = modifiedFields.lastName1 != undefined
                    ? modifiedFields.lastName1
                    : this.patient.lastName1;
                modifiedFields.lastName2 = modifiedFields.lastName2 != undefined
                    ? modifiedFields.lastName2
                    : this.patient.lastName2;
            }

            return modifiedFields;
        };

        this.doUpdatePetition = function (modifiedFields) {
            modifiedFields = this.checkForNamesUpdate(modifiedFields);

            userService.update(this.patient._id, modifiedFields, (err, res) => {
                const message = err
                    ? new popupMessage(err.status, "Se ha producido un error al modificar el paciente")
                    : new popupMessage(res.status, "El paciente se ha modificado correctamente");

                this.showMessage($errorModal, message);

                this.createFirstAppointmentForUpdatedPatient(modifiedFields);
            });
        };

        this.processObject = function (post, pre) {
            const postKeys = Object.keys(post);
            const postVal = Object.values(post);
            const preKeys = Object.keys(pre);
            const preVal = Object.values(pre);
            const newObj = {};

            let i = 0;
            let j = 0;

            while (i < postKeys.length) {
                while (j < preKeys.length) {
                    const postKey = postKeys[i];
                    const postValue = postVal[i];
                    const preKey = preKeys[j];
                    const preValue = preVal[j];

                    if (postKey != preKey) {
                        newObj[postKey] = postValue;
                        i++;
                    } else if (postKey === preKey) {
                        if ((typeof postValue) == "object" && postValue !== null) {
                            if (!angular.equals(postValue, preKey)) {
                                const obj = this.processObject(postValue, preValue);

                                if (Object.keys(obj).length > 0) {
                                    newObj[postKey] = obj;
                                }
                            }
                        } else {
                            if (postValue !== preValue && postValue !== null && postValue !== "") {
                                if (postKey == "medicine" || postKey == "dose" || postKey == "application") {
                                    return post;
                                }
                                newObj[postKey] = postValue;
                            }
                        }

                        i++;
                        j++;
                    }
                }

                if ((i < postKeys.length) && !(j < preKeys.length)) {
                    const postKey = postKeys[i];
                    newObj[postKey] = postVal[i];
                    i++;
                }
            }

            return newObj;
        };

        this.setHHMMtoInitTreatment = function (data) {
            const time = new Date(this.time);
            const previousHour = time.getHours();
            const previousMinute = time.getMinutes();

            const newTime = (typeof (data.treatments.starts) === "number")
                ? new Date(data.treatments.starts)
                : data.treatments.starts;

            newTime.setHours(previousHour);
            newTime.setMinutes(previousMinute);

            data.treatments.starts = newTime.getTime();
        };

        this.generatePass = function () {
            passwordChangeConfirmationModal.show((result) => {
                if (result) {
                    const pass = userService.randomPass();

                    pdfService.createPassword(this.patient.nick, pass, true);

                    userService.generatePassword(this.patient._id, pass, (error) => {
                        if (error) {
                            return console.error(error);
                        }
                    });
                }
            });
        };

        this.reactionPDF = function () {
            if (navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("Edge") > -1) {
                pdfService.reactionInfo(this.currentTreatment.reacAsis, true);
            } else {
                pdfService.reactionInfo(this.currentTreatment.reacAsis);
            }
        };

        this.legalPDF = function () {
            //TODO: COMPONER EL PDF CON LA INFORMACION LEGAL.
            //pdfService.reactionInfo();
        };

        this.endTreatment = function () {
            if (this.currentTreatment.inIntakeControl >= 1 || this.currentTreatment.toreview >= 0) {
                $infoModal.show("Atención", [
                    "Esta intentando finalizar la inducción.",
                    "Para finalizar la inducción asegurese de que el paciente no siga en el control de tomas, que este dado de alta y archivado."
                ]);
            } else {
                confirmationService.doblePlusMedicEndInduction((accepted, data) => {
                    if (!accepted) {
                        return;
                    }

                    var info = {};
                    info.treatment = {
                        type: this.currentTreatment.type
                    };
                    info.auth = {
                        pass: data.pass,
                        user: data.user
                    };
                    userService.endTreatment(this.patient._id, info, (err, result) => {
                        if (err) {
                            $errorModal.show("Error", "Ha ocurrido un error al finalizar el tratamiento");
                            return console.error(err);
                        }

                        this.currentTreatment.ends = result && result.data
                            ? result.data
                            : moment.utc().valueOf();

                        $state.go("app.admin-medical.patient-detail", { id: this.patient._id });
                        this.hideEdit("mainData");
                        this.cancelEditArea("mainData");
                        window.location.reload();
                    });
                });
            }
        };

        this.modifyProtocol = function () {
            if (this.currentTreatment.inIntakeControl >= 1 || this.currentTreatment.toreview >= 0) {
                $infoModal.show("Atención", [
                    "Esta intentando cambiar de protocolo.",
                    "Para cambiar de protocolo asegurese de que el paciente no siga en el control de tomas, que este dado de alta y archivado."
                ]);
            } else {
                $infoModal.show("Atención", [
                    "Con esta acción podrá modificar el protocolo al paciente",
                    "Deberá prescribir la dosis correspondiente en el nuevo protocolo"
                ]);
                this.form.treatments.starts = new Date().valueOf();
                this.changeTreatment.oldTreatment = this.form.treatments.type;
                this.changeTreatment.editing = true;
            }
        };

        this.weekLabel = function (week) {
            return ("dosis: " + week.dose);
        };

        this.protocolLabel = function (protocol) {
            return (protocol.type);
        };

        this.getProtoDescription = function () {
            if (this.form.treatments && this.form.treatments.type) {
                const newProtocol = this.protocols.find(protocol => protocol.type === this.form.treatments.type);

                if (newProtocol) {
                    protocolService.get(newProtocol._id, (err, data) => {
                        if (err) {
                            $errorModal.show("Error", "El protocolo seleccionado presenta algun error");
                            this.changeTreatment.newTreatment = undefined;
                        } else {
                            this.treatmentDetail = data.data;
                            $protocolModal.show("Descripción del protocolo", this.treatmentDetail);
                        }
                    });
                }
            }
        };

        this.hasActiveTreatmentOrHasNotOne = function () {
            const treatments = this.patient.treatments;
            if (treatments.length == 0) {
                return true;
            }
            for (let i = treatments.length - 1; i >= 0; i--) {
                let treatment = treatments[i];
                if (treatment.ends == "") {
                    return true;
                }
            }
            return false;
        };

        this.editArea = function (section) {
            if (!this.hasActiveTreatmentOrHasNotOne()) {
                this.editDisable = true;
            }

            this.form = mapperService.toPatientFront(this.patient);
            this.preEdition = angular.copy(this.form);

            if (this.form.treatments && this.form.treatments.starts) {
                this.time = new Date(this.form.treatments.starts);
            }

            const birthDate = moment(this.form.birthDate);
            const now = moment(dateUtils.getCurrentTs()).utc();
            this.form.age = now.diff(birthDate, "years");

            if (Object.keys(this.protocols).length === 0) {
                this.updateAllergen();
            }

            this.editAreas[section] = true;
        };

        this.openBirthDatepicker = function () {
            this.opnBirth = true;
        };

        this.openStartDatepicker = function () {
            this.opnInit = true;
        };

        this.cancel = function (section) {
            if (this.changeTreatment.editing) {
                this.changeTreatment.editing = false;
            }

            if (!this.patient) {
                $state.go("app.admin-medical.calendar");
            }

            this.cancelEditArea(section);
        };

        this.cancelEditArea = function (section) {
            delete this.editAreas[section];
        };

        this.cancelModifyTreatment = function () {
            this.form.treatments.type = this.changeTreatment.oldTreatment;
            this.changeTreatment.editing = false;
        };

        this.showEdit = function (section) {
            this.editButtons[section] = true;
        };

        this.hideEdit = function (section) {
            delete this.editButtons[section];
        };

        this.updateAllergen = function () {
            if (this.form.treatments && this.form.treatments.allergen) {
                protocolService.getList(this.form.treatments.allergen, (err, protocols) => {
                    if (err) {
                        return console.error(err);
                    }
                    this.protocols = protocols.data;
                });
            }
        };

        this.updateProtocol = function () {
            if (this.changeTreatment.editing) {
                const newProtocol = this.protocols.find(protocol => protocol.type === this.form.treatments.type);
                protocolService.get(newProtocol._id, (err, data) => {
                    if (err) {
                        $errorModal.show("Error", "El protocolo seleccionado presenta algun error");
                        this.changeTreatment.newTreatment = undefined;
                    } else {
                        this.changeTreatment.newTreatment = data.data;
                    }
                });
            } else {
                if (this.form.treatments.type && this.protocols instanceof Array) {
                    const prot = this.protocols.find(protocol => this.form.treatments.type == protocol.type);

                    if (!prot.active) {
                        confirmationService.activeProtocol((accepted) => {
                            if (!accepted) {
                                //TODO: mostrar mensaje de error.
                                return;
                            }

                            protocolService.activeProtocol(prot, function (err) {
                                if (err) {
                                    //TODO: mostrar mensaje de error.
                                    return;
                                }
                                prot.active = true;
                                return true;
                            });
                        });
                    }
                }
            }
        };

        this.protocolChangeIsBlocked = function () {
            if (!this.hasDoctorRole) {
                return true;
            }

            if (this.changeTreatment.editing) {
                return false;
            }

            return this.currentTreatment
                    && this.currentTreatment.type
                    && utilsTreatmentService.hasDosesTaken(this.currentTreatment)
                    && !this.currentTreatment.ends
                    && this.form.treatments;
        };

        this.allergenChangeIsBlocked = function () {
            if (!this.hasDoctorRole) {
                return true;
            }

            return (this.patientTreatments && this.patientTreatments.length > 1) ||
                (this.currentTreatment
                    && this.currentTreatment.allergen
                    && utilsTreatmentService.hasDosesTaken(this.currentTreatment)
                    && this.form.treatments);
        };

        this.selectedTreatments = function (treatment) {
            this.selectedTreatment = treatment;
            this.getSelectedTreatments();

        };

        this.isSelectedTreatment = function (treatment) {
            return this.selectedTreatment == treatment;
        }

        $scope.$watch(() => this.form.birthDate, (newValue) => {
            if (!((typeof (newValue) === "undefined") || (typeof (this.form.birthDate) === "undefined"))) {
                const birthDate = moment(this.form.birthDate);
                const now = moment(dateUtils.getCurrentTs()).utc();
                const diff = now.diff(birthDate, "years");
                this.form.age = diff;
            }
        });
    }

    showMessage(modalFactory, message) {
        if (message.getCode() == -1) {
            modalFactory.show("Error", "Se ha perdido la conexion con el servidor. Por favor revisa que el dispositivo este conectado a internet");
            console.error(message.getText());
        } else if (message.getCode() == 403) {
            modalFactory.show("Error", "No tienes permisos para crear/modificar un paciente. El paciente no se ha creado/modificado");
            console.error(message.getText());
        } else if (message.getCode() == 409) {
            modalFactory.show("Error", "El paciente ya existe en el centro. El paciente no se ha creado");
            console.error(message.getText());
        } else if (message.getCode() == 200) {
            modalFactory.show(undefined, message.getText());
            console.log(message.getText());
        } else {
            modalFactory.show("Error", "Se ha producido un error inesperado");
            console.error(message.getText());
        }
    };

    isSensitiveData(data) {
        return this.hasReacAsisData(data.reacAsis) ||
            this.hasTreatmentTypeData(data.treatments);
    }

    hasReacAsisData(reacAsis) {
        return reacAsis !== undefined && Object.keys(reacAsis).length > 0;
    }

    hasTreatmentTypeData(treatments) {
        return treatments && (treatments.type || treatments.allergen);
    }

    getSensitiveData(data) {
        const sensibleData = {
            treatments: {
                doctorId: undefined,
                type: undefined,
                starts: undefined
            }
        };

        if (data.reacAsis && Object.keys(data.reacAsis).length > 0) {
            sensibleData.treatments.reacAsis = data.reacAsis;
        }

        if (data.treatments) {
            if (data.treatments.type) {
                sensibleData.treatments.type = data.treatments.type;
            }

            if (data.treatments.doctorId) {
                sensibleData.treatments.doctorId = data.treatments.doctorId;
            }

            if (data.treatments.starts) {
                sensibleData.treatments.starts = data.treatments.starts;
            }
        }

        return sensibleData;
    };

    validateForm(patientForm, controls) {
        patientForm.$$controls.forEach(element => this.consolePrint(element, controls));
    };

    consolePrint(element, controls) {
        let message = element.$$attr.id ? element.$$attr.id : "sin nombre: ";

        if (element.$$attr.id == "horaCita") {
            const fecha = element.$modelValue;
            if (!fecha || (fecha && fecha.getHours() == 0 && fecha.getMinutes() == 0)) {
                this.controls.horaCita = true;
            } else {
                this.controls.horaCita = false;
            }
        }

        message = message + ": " + (Object.keys(element.$error).length !== 0 ? JSON.stringify(element.$error) : "OK");
        console.log(message);
        const n = element.$$attr.id;
        const e = Object.keys(element.$error).length !== 0;
        controls[n] = { "has-error": e };
    };

    validate(event) {
        if (event && event.type == "keypress" && (event.keyCode == 13 || event.keyName == "Enter")) {
            console.log("Se ha pulsado el enter o intro");
            event.preventDefault();
        }
    }

    copyMedicines() {
        const reacAsis = this.form.reacAsis;
        if (reacAsis) {
            this.cloneHospitalMedicines(reacAsis);
        }
    }

    cloneHospitalMedicines(reacAsis) {
        if (reacAsis.hospital != null) {
            if (reacAsis.hospital.antihistamine) {
                reacAsis.antihistamine = this.copy(reacAsis.hospital.antihistamine);
            }
            if (reacAsis.hospital.corticoid) {
                reacAsis.corticoid = this.copy(reacAsis.hospital.corticoid);
            }
            if (reacAsis.hospital.bronchodilator) {
                reacAsis.bronchodilator = this.copy(reacAsis.hospital.bronchodilator);
            }
            if (reacAsis.hospital.adrenalin) {
                reacAsis.adrenalin = this.copy(reacAsis.hospital.adrenalin);
            }
        }
    }

    copy(element) {
        return angular.copy(element);
    }

    $onChanges() {
        if (this.edicion && this.patient) {
            this.editArea("mainData");
        }
    };

}

patientDataController.$inject = [
    "$scope",
    "$state",
    UTILS_DATE_SERVICE,
    CENTERS_SERVICE,
    USERS_SERVICE,
    PROTOCOLS_SERVICE,
    APPOINTMENTS_SERVICE,
    CONFIRMATION_SERVICE,
    MAPPER_SERVICE,
    PDF_SERVICE,
    TOKEN_SERVICE,
    PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE,
    ERROR_MODAL_SERVICE,
    INFO_MODAL_SERVICE,
    PROTOCOL_MODAL_SERVICE,
    UTILS_TREATMENT_SERVICE
];

export default patientDataController;
