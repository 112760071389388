export default {
    regexp: {
        expresion: " |m|g|l",
    },
    dateFilter: {
        options1: {
            showWeeks: false,
            startingDay: 1
        },
        options2: {
            maxDate: "",
            showWeeks: false,
            startingDay: 1
        }
    },
    graphics: {
        series: ["Paciente"],
        datasetOverride: [{ label: ["Paciente"], lineTension: 0 }],
        colors: [
            {
                backgroundColor: "rgba(247,70,74,0.35)",
                borderColor: "rgb(247,70,74)",
                pointBackgroundColor: "rgb(247,70,74)",
                pointBorderColor: "rgb(247,70,74)",
                fill: true
            }
        ],
        options: {
            legend: { display: true },
            title: {
                display: true,
                fontSize: 18,
                text: "Evolución Paciente vs. Protocolo"
            },
            scales: {
                yAxes: [
                    {
                        id: "protocol",
                        type: "linear",
                        display: true,
                        position: "right",
                        pointStyle: "circle",
                        scaleLabel: {
                            display: true,
                            labelString: "Dosis en mg",
                            fontColor: "rgb(10,40,100)"
                        }
                    }
                ]
            }
        }
    }
};
