import {TOKEN_SERVICE} from "services/token";

const AUTH_SERVICE = "authService";

var AuthService = function ($http, __env, token) {
    var apiUrl = `${__env.apiUrl}/users`;
    var authService = {};

    authService.signIn = function (user, pass, callback) {
        $http.post(`${apiUrl}/login`, {user: user, pass: pass}).then(
            () => callback(),
            callback
        );
    };

    authService.logOut = token.delete;

    return authService;
};

AuthService.$inject = ["$http", "__env", TOKEN_SERVICE];

export { AUTH_SERVICE, AuthService };
