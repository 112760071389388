import "angular-ui-bootstrap";

import { TOKEN_SERVICE } from "services/token";
import { UTILS_DATE_SERVICE } from "services/utils";
import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { TREATMENT_SERVICE, USERS_SERVICE, INTAKE_CONTROL_SERVICE } from "services/api-client";
import { UTILS_TREATMENT_SERVICE } from "services/utils";
import { INTAKE_MODAL_SERVICE } from "services/modal/intake-modal";

const ONE_MINUTE_IN_MILLIS = 60 * 1000;

class comparativeEditIntakeController {
    constructor(
        $window,
        tokenService,
        dateUtils,
        reactionsService,
        confirmationService,
        treatmentService,
        userService,
        treatmentUtils,
        intakeControlService,
        intakeModalService
    ) {
        this.token = tokenService.get();
        this.role = this.token.role;
        this.window = $window;
        this.dateUtils = dateUtils;
        this.reactionsService = reactionsService;
        this.confirmationService = confirmationService;
        this.treatmentService = treatmentService;
        this.userService = userService;
        this.treatmentUtils = treatmentUtils;
        this.intakeControlService = intakeControlService;
        this.intakeModalService = intakeModalService;
        this.view = {};

        this.refreshData = this.refreshData.bind(this);
    }

    $onInit() {
        this.loadInitValues();
    }

    $onChanges(changes) {
        if (this.intake != undefined && changes) {
            const reactionsIds = this.reactionsService.symptomsDescriptionToIds(this.intake.reactions);
            this.actualIntake = JSON.parse(JSON.stringify(this.intake));
            this.cloneReactions = this.arrToObject(reactionsIds);
            this.hasReactions = this.intake.reactions && this.intake.reactions.length > 0;
            this.cloneCofactors = this.arrToObject(this.intake.cofactors);
            this.cloneSuggesting = this.compoundMedicines(this.intake.suggesting);
            this.displayDose = this.getDoseValue(this.intake);
            if (this.dayToEdit.isIntra) {
                this.displayVolume = this.getVolumeValue(this.intake);
            }

            this.displayNotes = this.getNotesValue(this.intake);

            // Set the intakeType to hospitalary to reflect it in the radio button of intake edition.
            if (this.intake.intakeType === undefined) {
                this.intake.intakeType = this.intake.state == "taken"
                    ? "hospitalary"
                    : "extrahospitalary";
            }
        }
    }

    updateIntake() {
        if (this.token.role !== "Doctor") {
            this.window.$("#intakeDetail").modal("toggle");
            this.confirmationService.error(() => { });
        }

        this.intake.dose = this.displayDose + " " + this.unit;
        this.intake.patientIntake = this.intake.dose;
        if (this.dayToEdit.isIntra) {
            this.intake.volume = this.displayVolume;
        }
        this.intake.notes = this.displayNotes;

        this.intake.reactions = this.objToArray(this.cloneReactions);
        this.intake.reactions = this.reactionsService.symptomsDescriptionToIds(this.intake.reactions);
        this.intake.cofactors = this.objToArray(this.cloneCofactors);
        this.intake.suggesting = this.translateMedicines(this.intake.suggesting, this.cloneSuggesting);

        if (this.intake.state === "taken" && this.intake.patientIntake === "No especificada") {
            this.intake.patientIntake = this.intake.doctorDose !== "No especificada"
                ? this.intake.doctorDose
                : this.intake.protocolDose;
        }

        if (!this.intake.type) {
            this.intake.type = this.currentTreatment.type;
            this.actualIntake.type = this.currentTreatment.type;
        }

        if (!this.intake.date && !this.dayToEdit.isIntra) {
            this.intake.date = this.dateUtils.getCurrentTs();
        }

        if (!this.intake.doctorDose) {
            this.intake.doctorDose = this.intake.dose;
        }

        const intraIntakeDate = this.isIntraIntake(this.intake);

        if (this.intake.intakeType === "hospitalary") {
            delete this.intake.isExtrahospitalary;
        }

        if (this.intake.state === "taken" && this.intake.intakeType === "extrahospitalary"
            && JSON.stringify(this.actualIntake) !== JSON.stringify(this.intake)) {
            this.intake.editedByDoctor = true;
        }

        if (this.dayToEdit.isIntra) {
            this.intake.date = this.getIntakeDate();
            this.userService.updateIntraIntakes(this.patient._id, intraIntakeDate, this.intake, (err) => {
                if (!this.dayToEdit.discharged && this.dayToEdit.allIntakesAreTaken()) {
                    this.treatmentService.dischargeIntraDay(this.currentTreatment._id, this.dayToEdit.day);
                }

                this.refreshData(err);
            });
        } else {
            this.userService.updateIntake(this.patient._id, this.intake, this.refreshData);
        }
    }

    isIntraIntake(intake) {
        if (!this.currentTreatment.intra) {
            return undefined;
        }

        const days = this.currentTreatment.intra.days;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];

            for (let j = 0; j < day.intakes.length; j++) {
                if (day.intakes[j].date == intake.date) {
                    return day.date;
                }
            }
        }

        return undefined;
    }

    calculeHasReactions() {
        this.hasReactions = this.objValuesToArray(this.cloneReactions).some(value => value === true);
    }

    getMedicineKeyByType(medicineType) {
        const medicineKeysByType = {
            "Adrenalina": "adrenalin",
            "Antihistamínico": "antihistamine",
            "Broncodilatador": "bronchodilator",
            "Corticoide": "corticoid",
        };

        return medicineKeysByType[medicineType];
    }

    getMedicineTypeByKey(medicineKey) {
        const medicineTypesByKey = {
            "adrenalin": "Adrenalina",
            "antihistamine": "Antihistamínico",
            "bronchodilator": "Broncodilatador",
            "corticoid": "Corticoide",
        };

        return medicineTypesByKey[medicineKey];
    }

    compoundMedicines(suggestings) {
        const med = {
            "Adrenalina": { intake: false, count: 1 },
            "Antihistamínico": { intake: false, count: 1 },
            "Broncodilatador": { intake: false, count: 1 },
            "Corticoide": { intake: false, count: 1 },
        };

        if (suggestings) {
            suggestings.map(suggest => med[this.getMedicineTypeByKey(this.getReactKey(suggest.medicine))].intake = suggest.intake);
            suggestings.map(suggest => med[this.getMedicineTypeByKey(this.getReactKey(suggest.medicine))].count = suggest.date ? suggest.date.length : 1);
        }

        return med;
    }

    translateMedicines(suggestings, editedSuggestings) {
        if (!suggestings) {
            suggestings = [];
        }

        for (const medicineType in editedSuggestings) {
            const medicineKey = this.getMedicineKeyByType(medicineType);

            let i = suggestings.findIndex(s => this.getReactKey(s.medicine) === medicineKey);

            if (!editedSuggestings[medicineType].intake || editedSuggestings[medicineType].count == 0) {
                if (i >= 0) {
                    suggestings.splice(i, 1);
                }
            } else if (editedSuggestings[medicineType].count > 0) {
                if (i < 0) {
                    const suggest = this.currentTreatment.reacAsis[medicineKey];
                    suggest.intake = true;
                    suggest.date = [this.intake.date];
                    suggestings.push(suggest);
                    i = suggestings.findIndex(s => this.getReactKey(s.medicine) === medicineKey);
                }

                if (suggestings[i].date) {
                    while (suggestings[i].date.length < editedSuggestings[medicineType].count) {
                        const lastDate = suggestings[i].date[suggestings[i].date.length - 1];
                        suggestings[i].date.push(lastDate + ONE_MINUTE_IN_MILLIS);
                    }

                    while (suggestings[i].date.length > editedSuggestings[medicineType].count) {
                        suggestings[i].date.splice(suggestings[i].date.length - 1, 1);
                    }
                }
            } else {
                if (i < 0) {
                    const suggest = this.currentTreatment.reacAsis[medicineKey];
                    suggest.intake = true;
                    suggest.date = [this.intake.date];
                    suggestings.push(suggest);
                }
            }
        }

        return suggestings;
    }

    getReactKey(react) {
        const reacAsis = this.currentTreatment.reacAsis;

        for (const reactKey in reacAsis) {
            if (reactKey !== "hospital" && reacAsis[reactKey].medicine === react) {
                return reactKey;
            }
        }

        return undefined;
    }

    getDoseValue(intake) {
        if (intake != undefined) {
            if (!intake.patientIntake) {
                return parseFloat(this.treatmentUtils.parseDoseValue(intake.dose));
            } else {
                return parseFloat(this.treatmentUtils.parseDoseValue(intake.patientIntake));
            }
        }
    }

    getVolumeValue(intake) {
        if (intake != undefined) {
            return intake.volume;
        }
    }

    getNotesValue(intake) {
        if (intake != undefined) {
            return intake.notes;
        }
    }

    getIntakeDate() {
        return !this.intake.date
            ? (this.intake.index == undefined || this.intake.index == 0)
                ? this.dayToEdit.date
                : this.dayToEdit.intakes[this.intake.index - 1].date + ONE_MINUTE_IN_MILLIS
            : this.intake.date;
    }

    arrToObject(arrai) {
        var obj = {};
        for (var attr in arrai) {
            var property = arrai[attr];
            obj[property] = true;
        }
        return obj;
    }

    objToArray(obj) {
        const arrai = [];
        for (const id in obj) {
            if (obj[id]) {
                arrai.push(id);
            }
        }
        //arrai = Array.from(arrai);
        return arrai;
    }

    objValuesToArray(obj) {
        const arrai = [];
        for (const id in obj) {
            if (obj[id]) {
                arrai.push(obj[id]);
            }
        }
        //arrai = Array.from(arrai);
        return arrai;
    }

    refreshData(err) {
        if (err) {
            if (err.status == 403) {
                this.confirmationService.error(() => { });
            }
            return console.error(err);
        }

        this.onPatientRefreshNeeded();
        this.window.$("#intakeDetail").modal("toggle");
    }

    loadInitValues() {
        if (this.currentTreatment && this.currentTreatment.weeks) {
            this.cofactors = this.userService.getCofactors();
            this.medicineTypes = this.userService.getSuggestings();
            this.symptoms = this.reactionsService.getSymptomsNames();
            this.unit = this.treatmentUtils.getTreatmentUnit(this.currentTreatment);
        }

        if (this.token && this.token.centerId && this.view) {
            const view = this.view;
            this.intakeControlService.getConstants(this.token.centerId,
                (error, data) => {
                    if (error) {
                        return console.error(error);
                    }

                    view.constants = data.data;
                });
        }
    }

    editConstants(intake) {
        this.window.$("#intakeDetail").modal("toggle");

        this.intakeModalService.setConstants(this.view.constants, intake.constants,
            (accepted, data) => {
                if (accepted) {
                    intake.constants = data.filter(constant => constant.value !== "");
                    intake.type = this.currentTreatment.type;
                    if (this.dayToEdit.isIntra) {
                        const intraIntakeDate = this.isIntraIntake(this.intake);
                        this.intake.date = this.getIntakeDate();
                        this.userService.updateIntraIntakes(this.patient._id, intraIntakeDate, this.intake, () => { });
                    } else {
                        this.userService.updateIntake(this.patient._id, this.intake, () => { });
                    }

                    this.window.$("#intakeDetail").modal("toggle");
                }
            });
    }

    back() {
        this.refreshData();
    }

}

comparativeEditIntakeController.$inject = [
    "$window",
    TOKEN_SERVICE,
    UTILS_DATE_SERVICE,
    REACTION_ALGORITHM_SERVICE,
    CONFIRMATION_SERVICE,
    TREATMENT_SERVICE,
    USERS_SERVICE,
    UTILS_TREATMENT_SERVICE,
    INTAKE_CONTROL_SERVICE,
    INTAKE_MODAL_SERVICE
];

export default comparativeEditIntakeController;
