const UTILS_STRINGS_SERVICE = "stringUtilsService";

const StringUtilsService = function () {

    const stringUtilsService = {};

    stringUtilsService.capitalize = function (s) {
        if (typeof s !== "string")  {
            return "";
        }

        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return stringUtilsService;
};

StringUtilsService.$inject = [];

export { UTILS_STRINGS_SERVICE, StringUtilsService };
