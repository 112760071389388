import webHeaderTemplate from "./web-header.template";
import webHeaderController from "./web-header.controller";

const HEADER_COMPONENT = "webHeader";

const webHeaderComponent = {
    template: webHeaderTemplate,
    controller: webHeaderController,
};

export { HEADER_COMPONENT, webHeaderComponent };
