import { DISCHARGE_ALERT_SERVICE } from "components/discharge-alert";
import { LOGGER_SERVICE } from "services/logger";
import { USERS_SERVICE, INTAKE_CONTROL_SERVICE } from "services/api-client";
import { INTAKE_MODAL_SERVICE } from "services/modal/intake-modal";
import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { CANCEL_INTAKE_MODAL_SERVICE } from "services/modal/cancel-intake";
import { TOKEN_SERVICE } from "services/token";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { UTILS_DATE_SERVICE } from "services/utils";

class intakeDetailController {

    constructor(
        $state,
        $stateParams,
        logger,
        dischargeAlertService,
        intakeControlService,
        intakeModalService,
        reactionsService,
        tokenService,
        userService,
        dateUtils,
        errorModal,
        cancelIntakeModal
    ) {
        this.state = $state;
        this.logger = logger;
        this.dischargeAlertService = dischargeAlertService;
        this.intakeControlService = intakeControlService;
        this.intakeModalService = intakeModalService;
        this.reactionsService = reactionsService;
        this.userService = userService;
        this.dateUtils = dateUtils;
        this.errorModal = errorModal;
        this.cancelIntakeModal = cancelIntakeModal;

        this.patientId = $stateParams.id;
        this.token = tokenService.get();
        this.isOnEdit = false;
        this.view = {};
        this.day = {};
        this.patient = {};
        this.isIntra = false;
        this.data;
        this.initData = this.initData.bind(this);
        this.defaultConstants = [
            { name: "TAS", unit: "mmHg", value: null },
            { name: "TAD", unit: "mmHg", value: null },
            { name: "Frecuencia cardíaca", unit: "lpm", value: null },
            { name: "Peak Flow", unit: "L/min", value: null },
            { name: "Sat O2", unit: "%", value: null }
        ];
    }

    $onInit() {
        if (!this.patientId) {
            return console.error("Missing patient Id");
        }

        this.updateData();

        const view = this.view;
        this.intakeControlService.getConstants(this.token.centerId,
            (error, data) => {
                if (error) {
                    return console.error(error);
                }

                view.constants = data.data;
            });

        this.patient = this.intakeControlService.getUserDetail(this.patientId);

        if (this.patient) {
            this.treatments = this.patient.treatments;
            this.view.nextIntake = this.getTimeNextIntake(this.treatments[this.treatments.length - 1].intra);
            this.patient.nextIntakeTime = this.view.nextIntake;
        }
    }

    saveBasalConstants() {
        this.isOnEdit = !this.isOnEdit;
        this.intakeModalService.setConstants(this.view.constants, this.day.constants,
            (accepted, data) => {
                if (accepted) {
                    this.day.constants = data;
                    this.userService.addBasalConstants(this.patientId, this.isIntra, this.day.date, this.day.constants, (error, result) => {
                        if (error) {
                            this.logger.error("Error saving basal constants");
                            this.errorModal.show("Error", "Ha ocurrido un error al resgistrar las constantes basales.");
                            return;
                        }

                        this.logger.info(result.message);
                    });
                }
            });
    }

    cancelIntake() {
        this.cancelIntakeModal.show((result) => {
            if (result) {
                this.state.go("app.admin-medical.control-tomas");
                this.userService.cancelIntake(this.patientId, (error) => {
                    if (error) {
                        return console.error(error);
                    }
                });
            }
        });
    };

    updateData() {
        if (!this.patientId) {
            return;
        }

        this.patient = this.intakeControlService.getUserDetail(this.patientId);
        if (!this.patient) {
            return;
        }
        this.treatments = this.patient.treatments;
        this.patient.nextIntakeTime = this.getTimeNextIntake(this.treatments[this.treatments.length - 1].intra);
        this.view.nextIntake = this.patient.nextIntakeTime;
        this.view.reactionsDate = [];
        this.view.reactionsSummary = [];
        this.view.medicines = [];

        if (!this.patient.intakesTaken) {
            this.patient.intakesTaken = 0;
        }

        // Ojo: this.patient.treatments[0].intra no tiene la info original recibida del API
        // sino que se edita en intakecontrol.service.js
        this.day = this.intakeControlService.getTreatmentDay(this.treatments[this.treatments.length - 1].intra, this.dateUtils.getCurrentTs());
        this.isIntra = this.treatments[this.treatments.length - 1].intra.isIntra;

        if (!this.day) {
            const week = this.intakeControlService.getTreatmentWeek(this.treatments[this.treatments.length - 1], this.dateUtils.getCurrentTs());
            this.day = this.intakeControlService.getTreatmentDay(week, this.dateUtils.getCurrentTs());
            this.isIntra = false;
        }

        this.discharged = this.treatments[this.treatments.length - 1].discharged;

        this.day.intakes.forEach(function (intake, index) {
            intake.type = this.treatments[this.treatments.length - 1].type;
            if (intake.state === "taken") {
                this.patient.intakesTaken++;
                const observationTime = parseFloat(intake.observationTime.split(" ")[0]) * 60000;
                if (observationTime + intake.date < this.dateUtils.getCurrentTs()) {
                    intake.expired = true;
                }
            }

            if (intake.reactDate) {
                this.view.reactionsDate[index] = intake.reactDate;
            }

            if (intake.reactions) {
                const reactionsDescriptions = this.reactionsService.symptomsIdsToDescriptions(intake.reactions);
                this.view.reactionsSummary[index] = reactionsDescriptions.join(", ");
            }

            if (intake.suggesting) {
                const medicines = this.getMedicines(intake.suggesting);
                this.view.medicines[index] = medicines.join(", ");
            }
        }, this);

        if (!this.day.constants) {
            this.day.constants = this.defaultConstants;
        }
    }

    getMedicines(suggesting) {
        const medicines = [];
        for (const i in suggesting) {
            const medicine = suggesting[i].medicine;
            const dose = suggesting[i].dose;

            for (const j in suggesting[i].date) {
                const date = new Date(suggesting[i].date[j]);
                const hours = ("0" + date.getHours()).slice(-2);
                const minutes = ("0" + date.getMinutes()).slice(-2);
                medicines.push(medicine + " " + dose + " (" + hours + ":" + minutes + ")");
            }
        }

        return medicines;
    }

    getTimeNextIntake(intra) {
        const intakes = intra.days[0].intakes;
        let index = 0;
        for (; index < intakes.length; index++) {
            if (!intakes[index].state) {
                break;
            }
        }

        const intake = intakes[index];
        const time = parseInt(intra.days[0].date);

        if (index == 0) {
            return time;
        } else if (index == intakes.length) {
            return "";
        } else {
            return time + parseInt(intake.observationTime.split(" ")[0] * 60000);
        }
    }

    editMode() {
        this.isOnEdit = !this.isOnEdit;
    }

    editReactions(intakeIndex) {
        this.data = {
            patientId: this.patientId,
            intake: this.day.intakes[intakeIndex],
            reacAsis: this.treatments[this.treatments.length - 1].reacAsis.hospital,
        };

        this.intakeModalService.setReactions(this.data, (accepted, data) => {
            if (!accepted) {
                return;
            }

            this.data.intake.reactDate = data.reactionTime.getTime();

            this.data.intake.reactions = [];
            for (let i = 0; i < data.reactions.length; i++) {
                this.data.intake.reactions.push(data.reactions[i]);
            }

            if (this.reactionsService.intakeHasNoIgnorableReactions(this.data.intake)) {
                this.intakeControlService.addTimer(
                    this.data.patientId,
                    this.data.intake
                );
            }

            const reactionsDescriptions = this.reactionsService.symptomsIdsToDescriptions(data.reactions);
            this.view.reactionsSummary[intakeIndex] = reactionsDescriptions.join(", ");

            if (this.data.intake.reactions.length == 0) {
                this.data.intake.reactDate = undefined;
            }

            this.view.reactionsDate[intakeIndex] = this.data.intake.reactDate;

            this.view.medicines;
            if (!this.data.intake.suggesting) {
                this.data.intake.suggesting = [];
            }

            for (let i = 0; i < data.medicines.length; i++) {
                const medicine = data.medicines[i];
                for (const j in this.data.reacAsis) {
                    const farm = this.data.reacAsis[j];
                    if (farm.medicine === medicine) {
                        const k = this.data.intake.suggesting.findIndex(suggest => suggest.medicine === farm.medicine);

                        if (k >= 0) {
                            this.data.intake.suggesting[k].date.push(data.reactionTime.getTime());
                        } else {
                            farm.intake = true;
                            farm.date = [data.reactionTime.getTime()];
                            this.data.intake.suggesting.push(farm);
                        }
                    }
                }
            }

            const medicines = this.getMedicines(this.data.intake.suggesting);
            this.view.medicines[intakeIndex] = medicines.join(", ");
            this.data.intake.notes = data.notes;

            this.userService.updateIntraIntakes(this.patientId, this.day.date, this.data.intake, () => { });
        });
    }

    editConstants(intake) {
        this.intakeModalService.setConstants(this.view.constants, intake.constants,
            (accepted, data) => {
                if (accepted) {
                    intake.constants = data.filter(constant => constant.value !== "");
                    intake.type = this.treatments[this.treatments.length - 1].type;
                    this.userService.updateIntraIntakes(this.patientId, this.day.date, intake, () => { });
                }
            });
    };

    onClickApplyIntake(intake, intakeIndex) {
        this.intakeControlService.delayTreatmentStartDateIfNeeded(
            this.isIntra,
            {
                days: this.treatments[this.treatments.length - 1].intra.days
            },
            this.treatments[this.treatments.length - 1].starts,
            this.treatments[this.treatments.length - 1]._id
        ).then((delayed) => {
            if (!delayed) {
                return this.applyIntake(intake, intakeIndex);
            }

            this.intakeControlService.resetPatientsCache();
            this.intakeControlService.load(() => {
                this.$onInit();
                intake = this.day.intakes.find(i => i.index == intake.index);
                this.applyIntake(intake, intakeIndex);
            });
        });
    }

    applyIntake(intake, intakeIndex) {
        const previousIntake = this.day.intakes[intakeIndex - 1];

        if (!previousIntake || (previousIntake.state === "taken" && previousIntake.expired)) {
            intake = this.compoundIntake(intake, intakeIndex);
        }

        if (this.day.intakes.length >= this.patient.intakesTaken) {
            if (!this.patient.state && intake.state && (intakeIndex === 0 || previousIntake.expired)) {
                this.view.nextIntake = this.getTimeNextIntake(this.treatments[this.treatments.length - 1].intra);
                this.userService.updateIntraIntakes(this.patientId, this.day.date, intake, this.initData);
            }
        }
    }

    intakeCheckboxIsChecked(intake) {
        return intake.state === "taken";
    }

    intakeCheckboxIsDisabled(intakeIndex) {
        return this.isExpiredIntake(intakeIndex)
            || this.isFutureIntake(intakeIndex)
            || this.isAfterNonExpiredIntake(intakeIndex);
    }

    isAfterNonExpiredIntake(intakeIndex) {
        return !this.isFirstIntake(intakeIndex)
            && !this.isExpiredIntake(intakeIndex - 1);
    }

    isFirstIntake(intakeIndex) {
        return intakeIndex == 0;
    }

    isFutureIntake(intakeIndex) {
        return intakeIndex > this.patient.intakesTaken;
    }

    isExpiredIntake(intakeIndex) {
        return this.day.intakes[intakeIndex].expired;
    }

    isIntakeDosisAltered(intakeIndex) {
        return this.day.intakes[intakeIndex].doseAltered;
    }

    isIntakeVolumeAltered(intakeIndex) {
        return this.day.intakes[intakeIndex].volumeAltered;
    }

    hasAlteredIntakes() {
        const alteredIntakes = this.day.intakes.filter(intake => intake.doseAltered || intake.volumeAltered);

        return Array.isArray(alteredIntakes) && alteredIntakes.length;
    }

    initData(err, data) {
        if (err) {
            return;
        }

        const intake = data.index;

        if (!data.nModified) {
            this.day.intakes[intake] = data;
        }

        if (intake) {
            const reactionsDescriptions = this.reactionsService.symptomsIdsToDescriptions(this.day.intakes[intake].reactions);
            this.view.reactionsSummary[intake] = reactionsDescriptions.join(", ");
        }

        if (data.suggesting) {
            const medicines = this.getMedicines(data.suggesting);
            this.view.medicines[intake] = medicines.join(", ");
        } else {
            this.view.medicines[intake] = undefined;
        }
    }

    seePatientDetail() {
        this.state.go("app.admin-medical.patient-detail", { id: this.patientId });
    }

    navigateBack() {
        window.history.back();
    }

    takeOutFromIntakeControl(action) {
        this.intakeControlService.removeTimer(this.patientId);

        this.userService.takeOutFromIntakeControl(this.patientId, this.day.date, action, (error) => {
            if (error) {
                return console.error(error);
            }

            if (action === "archive") {
                this.state.go("app.admin-medical.control-tomas");
            } else {
                const patient = this.intakeControlService.getPatientById(this.patientId);
                this.dischargeAlertService.dischargePatient(patient);
            }
        });
    }

    compoundIntake(intake, intakeIndex) {
        const now = this.dateUtils.getCurrentTs();
        const patient = this.intakeControlService.getPatientById(this.patientId);

        if (intake.state === "taken") {
            patient.intakesTaken--;
            this.patient.intakesTaken = patient.intakesTaken;
            this.resetIntake(intake);
            this.intakeControlService.removeTimer(this.patientId);
        } else {
            patient.intakesTaken++;
            this.patient.intakesTaken = patient.intakesTaken;
            intake.date = now;
            intake.state = "taken";
            intake.type = patient.type;
            intake.patientIntake = intake.dose;
            intake.doctorDose = intake.doctorDose && intake.doctorDose.length ? intake.doctorDose : intake.dose;
            intake.protocolDose = intake.protocolDose && intake.protocolDose.length ? intake.protocolDose : intake.dose;
            intake.index = intakeIndex;

            if (!this.day.discharged) {
                this.intakeControlService.addTimer(this.patientId, intake);
            }
        }

        return intake;
    }

    resetIntake(intake) {
        if (intake) {
            intake.state = "untaken";
            intake.suggesting = [];
            intake.reactions = [];
            intake.constants = [];
            delete intake.reactDate;
        }
    }

}

intakeDetailController.$inject = [
    "$state",
    "$stateParams",
    LOGGER_SERVICE,
    DISCHARGE_ALERT_SERVICE,
    INTAKE_CONTROL_SERVICE,
    INTAKE_MODAL_SERVICE,
    REACTION_ALGORITHM_SERVICE,
    TOKEN_SERVICE,
    USERS_SERVICE,
    UTILS_DATE_SERVICE,
    ERROR_MODAL_SERVICE,
    CANCEL_INTAKE_MODAL_SERVICE,
];

export default intakeDetailController;
