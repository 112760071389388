import protocolsEditorTemplate from "./protocols-editor.template";
import protocolsEditorController from "./protocols-editor.controller";

const PROTOCOLS_EDITOR_COMPONENT = "protocolsEditor";

const protocolsEditorComponent = {
    template: protocolsEditorTemplate,
    controller: protocolsEditorController,
    bindings: {
        newProtocol: "<extra",
        protocols:  "<protocols"
    }
};

export { PROTOCOLS_EDITOR_COMPONENT, protocolsEditorComponent };
