export default [
    {
        id: "mayor",
        text: "Edad mayor de",
        type: "number",
        min: 0,
        max: 150
    },
    {
        id: "menor",
        text: "Edad menor de",
        type: "number",
        min: 0,
        max: 150
    },
    {
        id: "gender",
        text: "Sexo",
        type: "select",
        values: [
            {
                key: "Hombre",
                value: "male",
            },
            {
                key: "Mujer",
                value: "female"
            },
        ]
    }
];
