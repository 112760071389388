import protocolTemplate from "./protocol.template";

const PROTOCOL_MODAL_SERVICE = "ProtocolModalService";

var ProtocolModalService = function ($uibModal) {
    var protocolModalService = {};

    protocolModalService.show = function (title, protocol, callback = () => { }) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: protocolTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                $scope.title = title;
                $scope.protocol = protocol;

                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    return protocolModalService;
};

ProtocolModalService.$inject = ["$uibModal"];

export { PROTOCOL_MODAL_SERVICE, ProtocolModalService };
