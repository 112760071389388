import errorTemplate from "./error.template";

const ERROR_MODAL_SERVICE = "errorModalService";

var ErrorModalService = function ($uibModal) {
    var errorModalService = {};

    errorModalService.show = function (title, msg, callback = () => { }) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: errorTemplate,
            size: title ? "md" : "sm",
            controller: ["$scope", function ($scope) {
                $scope.title = title;
                $scope.msg = msg;

                $scope.accept = () => {
                    //TODO actualizar alerta
                    modal.close();
                    handled = true;
                    //callback(true, $scope.action);
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });
        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };


    return errorModalService;
};

ErrorModalService.$inject = ["$uibModal"];

export { ERROR_MODAL_SERVICE, ErrorModalService };
