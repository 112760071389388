import adminCenterTemplate from "./admin-center.template";

var adminCenterStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-center",
        url: "/admin-center",
        template: adminCenterTemplate,
    });
};

adminCenterStates.$inject = ["$stateProvider"];

export default adminCenterStates;
