import doseDataTemplate from "./dose-data.template";
import doseDataController from "./dose-data.controller";

const DOSE_DATA_COMPONENT = "doseData";

const doseDataComponent = {
    template: doseDataTemplate,
    controller: doseDataController,
    bindings: {
        currentTreatment: "<",
        dischargeMode: "<",
        onEnterEditMode: "&",
        onExitEditMode: "&",
        onPatientDischarged: "&",
        onPatientRefreshNeeded: "&",
        patient: "<",
        sectionDisabled: "<",
    }
};

export { DOSE_DATA_COMPONENT, doseDataComponent };
