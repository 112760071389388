import "angular-ui-bootstrap";
import angular from "angular";
import Protocol from "models/Protocol";

import { PROTOCOLS_SERVICE } from "services/api-client";
import { PROTOCOL_EDITOR_SERVICE } from "services/protocols";
import { UTILS_PROTOCOL_SERVICE } from "services/utils";

class ProtocolsEditorController {

    constructor($window, protocolService, protocolEditorService, protocolUtils) {
        this.window = $window;
        this.protocolService = protocolService;
        this.protocolEditorService = protocolEditorService;
        this.protocolUtils = protocolUtils;
        this.err = {};

        this.botonCrearProtocolo = {
            "btn": true,
            "btn-primary": true,
            ".active": false,
        };

        this.newProtocol = new Protocol();

        this.protocols;

        this.units = ["mg", "ml", "unidad"];
    }

    inMinutes(mili) {
        return mili / 60000;
    }

    editProtocol() {
        this.newProtocol = new Protocol();
        this.onEdit = !this.onEdit;
        this.botonCrearProtocolo.active = !this.botonCrearProtocolo.active;
        this.protocol = null;
    }

    validateIntake(index) {
        const week = this.newProtocol.weeks[index];
        const row = this.newProtocol.control[index];

        if (this.protocolEditorService.validateDose(week.dose, this.newProtocol.selectedUnit)) {
            this.updateControl(row.dose, false, true, false);
        } else {
            week.doseBorder = null;

            this.updateControl(row.dose, true, false, false);
        }

        if (row.dose["has-success"]) {
            this.updateControl(row.icon, false, true, false);
        } else {
            this.updateControl(row.icon, true, false, false);
        }
    }

    createControl(err, succ, none, feed) {
        var dose = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        var icon = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };

        return { dose, icon };
    }

    updateControl(node, err, succ, none) {
        node["has-error"] = err;
        node["has-success"] = succ;
        node["none"] = none;
    }

    addWeek(index) {
        var i = this.newProtocol.weeks.length + 1;
        if (typeof index != "undefined") {
            i = index + 1;
        }
        this.newProtocol.weeks.splice(i, 0, { weekNumber: i, dose: "", doseBorder: { "border-style": "none" } });
        this.newProtocol.control.splice(i, 0, this.createControl(false, false, true, true));
    }

    save() {
        this.err = this.protocolEditorService.validateProtocol(this.newProtocol);
        if (this.err.hasError) {
            if (!this.newProtocol.allergen) {
                this.newProtocol.allergen = "";
            }
            if (!this.newProtocol.type) {
                this.newProtocol.type = "";
            }
            if (!this.newProtocol.selectedUnit) {
                this.newProtocol.selectedUnit = "";
            }

            $("#protocolErrorDetail").modal("show");
        } else {
            console.log("cumple");

            let protocol = angular.copy(this.newProtocol);

            protocol = this.protocolEditorService.normalizeProtocol(protocol);

            protocol = this.protocolEditorService.removeControls(protocol);

            this.protocolService.post(protocol, (err) => {
                if (err) {
                    $("#protocolExistsError").modal("show");
                    this.newProtocol.controlType = "has-error";
                    return console.error(err);
                }

                this.clon = false;
                this.window.location.reload();
            });
        }
    }

    remove(index) {
        this.newProtocol.removeWeek(index);
        this.newProtocol.control.splice(index, 1);
    }

    clone(id) {
        this.clon = true;
        this.editProtocol();

        this.protocolService.get(id, (err, protocol) => {
            if (err) {
                this.protocol = null;
                this.clon = false;
                // TODO do something
                return console.error(err);
            }
            this.newProtocol.inicializeControl(protocol.data);
            this.newProtocol.allergen = protocol.data.allergen;
            this.newProtocol.type = "COPIA DE " + protocol.data.type;
            this.newProtocol.observationTime = this.inMinutes(protocol.data.observationTime);
            this.clon = false;
            this.onEdit = true;
            this.protocol = null;
        });

    }

    inicializeControl(protocol) {
        if (protocol) {
            this.newProtocol.control = [];
            protocol.weeks.forEach(this.addRow);
        }
    }

    addRow(week, index) {
        this.newProtocol.weeks.push({ weekNumber: this.newProtocol.weeks.length + 1, dose: week.dose, doseBorder: { "border-style": "none" } });
        this.newProtocol.push(this.createControl(true, false, true, false));
        this.validateReg(index);
    }
}

ProtocolsEditorController.$inject = [
    "$window",
    PROTOCOLS_SERVICE,
    PROTOCOL_EDITOR_SERVICE,
    UTILS_PROTOCOL_SERVICE,
];

export default ProtocolsEditorController;
