const ALERT_SERVICE = "alertService";

var AlertService = function ($http, __env) {
    var apiUrl = `${__env.apiUrl}/alerts`;

    var alertService = {};

    alertService.createPendingDischargeAlert = function (alert, callback) {
        $http.post(`${apiUrl}/pending-discharge`, alert).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    };

    alertService.checkPendingDischargeAlert = function (patientId, callback) {
        const alert = { userId: patientId };

        $http.put(`${apiUrl}/pending-discharge/check`, alert).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    alertService.postponePendingDischargeAlert = function (patientId, callback) {
        const alert = { userId: patientId };

        $http.put(`${apiUrl}/pending-discharge/postpone`, alert).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    };

    alertService.get = function (params, callback) {
        $http.get(`${apiUrl}`, { params: params }).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    };

    alertService.check = function (alert, callback) {
        $http.put(`${apiUrl}`, alert).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    return alertService;
};

AlertService.$inject = ["$http", "__env"];

export { ALERT_SERVICE, AlertService };
