import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { DISCHARGE_ALERT_MODULE } from "components/discharge-alert";
import { CANCELINTAKE_MODAL_MODULE } from "services/modal/cancel-intake";
import { INTAKE_USER_DETAIL_COMPONENT, intakeUserDetailComponent } from "./intake-detail.component";

const INTAKE_DETAIL_MODULE = "intakeDetailModule";

angular.module(INTAKE_DETAIL_MODULE, [
    "ui.router",
    API_CLIENT,
    DISCHARGE_ALERT_MODULE,
    CANCELINTAKE_MODAL_MODULE,
]).component(INTAKE_USER_DETAIL_COMPONENT, intakeUserDetailComponent);

export default INTAKE_DETAIL_MODULE;
