import moment from "moment";
import { UTILS_STRINGS_SERVICE } from "./utils.string";
const TWO_HOURS_IN_MILLI = 7200000;
const UTILS_DATE_SERVICE = "utilsDateService";

const UtilsDateService = function (stringUtils) {

    const ONE_HOUR_IN_MILLIS = 60 * 60 * 1000;

    const utilsDateService = {};

    utilsDateService.getCurrentDate = function () {
        return new Date();
    };

    utilsDateService.getCurrentTs = function () {
        return utilsDateService.getCurrentDate().getTime();
    };

    utilsDateService.getStartOfCurrentDate = function () {
        return moment(utilsDateService.getCurrentTs() + TWO_HOURS_IN_MILLI)
            .utc()
            .startOf("day")
            .toDate();
    };

    utilsDateService.getSameDayInNextWeekTs = function (dateTs) {
        const nextDate = moment(dateTs).utc();

        while (nextDate.valueOf() <= utilsDateService.getCurrentTs()) {
            nextDate.add(7, "days").valueOf();
        }

        return nextDate.valueOf();
    };

    utilsDateService.getWeekdayLocalized = function (date) {
        return stringUtils.capitalize(moment(date).utc().locale("es").format("dddd"));
    };

    utilsDateService.isToday = function (date) {
        return moment(utilsDateService.getCurrentTs()).utc().isSame(date, "day");
    };

    utilsDateService.compoundDate = function (day, time) {
        if (time == "Invalid Date" || day == "Invalid Date") return;

        day.setHours(time.getHours());
        day.setMinutes(time.getMinutes());

        return day;
    };

    /**
     * Parche para los bugs relacionados con comparación de días de tratamiento.
     * (como el de los días duplicados en la pestaña de Evolución).
     *
     * El date de los `days` se está guardando a las 00:00 hora local, es decir
     * a las 22:00 UTC o a las 23:00 UTC, según horario de verano o invierno.
     *
     * El inicio de las semanas se está guardando a las 22:00 UTC, es decir
     * a las 23:00 o a las 00:00 en hora local, según horario de verano o invierno.
     *
     * El parche consiste en comparar el UTC de ambas fechas permitiendo
     * un desfase de 1 hora, que es el que daría en horario de invierno.
     */
    utilsDateService.isStartOfTheSameDay = function (date1, date2) {
        return Math.abs(date1 - date2) <= ONE_HOUR_IN_MILLIS;
    };

    /**
     * Parche para el cálculo incorrecto del inicio y final de la semana
     * basado en el inicio del objeto `week`.
     *
     * El inicio de las semanas se está guardando a las 22:00 UTC, es decir
     * a las 23:00 o a las 00:00 en hora local, según horario de verano o invierno.
     *
     * El parche consiste en añadir 2 horas al inicio de las semanas para que
     * empiecen a las 00:00 UTC, siendo la fecha correcta tanto en UTC como en
     * local, y sea verano o invierno.
     */
    utilsDateService.getTimezoneCorrector = function() {
        return ONE_HOUR_IN_MILLIS * 2;
    };

    return utilsDateService;
};

UtilsDateService.$inject = [UTILS_STRINGS_SERVICE];

export { UTILS_DATE_SERVICE, UtilsDateService };
