const INFORMS_SERVICE = "informsService";

var InformsService = function ($http, __env) {
    var apiUrl = `${__env.apiUrl}/informs`;

    var informService = {};

    informService.getData = function (params, callback) {
        if (!callback)[params, callback] = [callback, params];

        $http.get(apiUrl, params ? { params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    informService.getDataGraphics = function (groupBy, filters, callback) {
        //if (!callback)[groupBy, callback] = [callback, groupBy];
        var params = {};
        if (filters) {
            for (var key in filters) {
                params[key] = filters[key];
            }
        }
        if (groupBy)
            params.group = groupBy;

        //$http.get(apiUrl, groupBy ? { params: { group: groupBy } } : undefined).then(
        $http.get(apiUrl, groupBy ? { params: params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    informService.getCSV = function (params, callback) {
        if (!callback)[params, callback] = [callback, params];

        $http.get(`${apiUrl}/fulldata`, params ? { params: params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    informService.getSumarizationInform = function (params, callback) {
        if (!callback)[params, callback] = [callback, params];

        $http.get(apiUrl, params ? { params: params } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    return informService;
};

InformsService.$inject = ["$http", "__env"];

export { INFORMS_SERVICE, InformsService };
