class Appointment {
    constructor(_id, date, type, state, centerId, doctorId, userId) {
        this._id = _id;
        this.date = date;
        this.type = type;
        this.state = state;
        this.centerId = centerId;
        this.doctorId = doctorId;
        this.userId = userId;
    }

    getDate() {
        return this.date;
    }

    getType() {
        return this.type;
    }

    getState() {
        return this.state;
    }
}

export default Appointment;