import angular from "angular";

const CONFIRMATION_MODULE = "confirmationModule";

import { CONFIRMATION_SERVICE, ConfirmationService } from "./confirmation.service";

angular.module(CONFIRMATION_MODULE, [])
    .factory(CONFIRMATION_SERVICE, ConfirmationService);

export { CONFIRMATION_MODULE, CONFIRMATION_SERVICE };
