import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import calendarStates from "./calendar.states";

const CALENDAR_MODULE = "calendarModule";

angular.module(CALENDAR_MODULE, [
    "ui.router",
    API_CLIENT,
])
    .config(calendarStates);

export default CALENDAR_MODULE;




