const PROTOCOLS_SERVICE = "protocolsService";

class ProtocolsService {
    constructor($http, __env) {
        this.http = $http;
        this.apiUrl = `${__env.apiUrl}/protocols`;
    }

    create(protocolData, callback) {
        this.http.post(this.apiUrl, protocolData).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    }

    getList(params, callback) {
        if (!callback)[params, callback] = [callback, params];

        this.http.get(this.apiUrl, params ? { params: { allergen: params } } : undefined).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    }

    getAllergens(callback) {
        this.http.get(`${this.apiUrl}/allergens`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    }

    get(id, callback) {
        this.http.get(`${this.apiUrl}/${id}`).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    }

    post(newProtocol, callback) {
        this.http.post(this.apiUrl, newProtocol).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    }

    activeProtocol(protocol, callback) {
        this.http.put(`${this.apiUrl}/${protocol._id}/activate`).then(
            (result) => callback(null, result.data),
            (result) => callback(result)
        );
    }
}

ProtocolsService.$inject = ["$http", "__env"];

export { PROTOCOLS_SERVICE, ProtocolsService };
