import template from "./password-change-confirmation.template";

const PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE = "passwordChangeConfirmationModalService";

const PasswordChangeConfirmationModalService = function ($uibModal) {
    const passwordModalService = {};

    passwordModalService.show = function (callback = () => { }) {
        let handled = false;

        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: template,
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;

                    callback(true);
                };

                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    return passwordModalService;
};

PasswordChangeConfirmationModalService.$inject = ["$uibModal"];

export { PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE, PasswordChangeConfirmationModalService };
