const DOSE_SERVICE = "doseService";

var DoseService = function ($http, __env) {
    var apiUrl = `${__env.apiUrl}/users`;

    var doseService = {};

    doseService.edit = function (patientId, dayDose, callback) {
        $http.put(`${apiUrl}/${patientId}/intakes`, dayDose)
            .then(
                (result) => callback(null, result.data),
                (err) => callback(err)
            );
    };

    doseService.set = function (patientId, doseData, callback) {
        $http.put(`${apiUrl}/${patientId}/dose`, doseData).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    return doseService;
};

DoseService.$inject = ["$http", "__env"];

export { DOSE_SERVICE, DoseService };
