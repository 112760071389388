import angular from "angular";

import { REACTION_ALGORITHM_SERVICE, ReactionAlgorithmService } from "./reaction-algorithm.service";

const REACTION_ALGORITHM_MODULE = "reactionAlgorithmModule";

angular.module(REACTION_ALGORITHM_MODULE, [])
    .factory(REACTION_ALGORITHM_SERVICE, ReactionAlgorithmService);

export { REACTION_ALGORITHM_MODULE, REACTION_ALGORITHM_SERVICE };
