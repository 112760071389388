import angular from "angular";
import "angular-ui-router";

import centerDetailStates from "./center-detail.states";

const CENTER_MODULE = "adminAppCenterModule";

angular.module(CENTER_MODULE, [
    "ui.router"
])
    .config(centerDetailStates);

export default CENTER_MODULE;
