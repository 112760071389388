import angular from "angular";

const ERROR_MODAL_MODULE = "errorModalModule";

import { ERROR_MODAL_SERVICE, ErrorModalService } from "./error.service";

angular.module(ERROR_MODAL_MODULE, [])
    .factory(ERROR_MODAL_SERVICE, ErrorModalService);

export { ERROR_MODAL_MODULE, ERROR_MODAL_SERVICE };
