class popupMessage {
    constructor(code, text) {
        this.code = code;
        this.text = text;
    }

    getCode() {
        return this.code;
    }

    getText() {
        return this.text;
    }
}

export default popupMessage;