import protocolsIntraEditorTemplate from "./protocols-intra-editor.template";
import protocolsIntraEditorController from "./protocols-intra-editor.controller";

const PROTOCOLS_INTRA_EDITOR_COMPONENT = "protocolsIntraEditor";

const protocolsIntraEditorComponent = {
    template: protocolsIntraEditorTemplate,
    controller: protocolsIntraEditorController,
    bindings: {
        newIntraProtocol: "<intra",
        newProtocol: "<extra"
    }
};

export { PROTOCOLS_INTRA_EDITOR_COMPONENT, protocolsIntraEditorComponent };
