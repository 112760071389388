import "angular-ui-bootstrap";

import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { TOKEN_SERVICE } from "services/token";
import { ALERT_SERVICE, INTAKE_CONTROL_SERVICE, USERS_SERVICE } from "services/api-client";
import { UTILS_DATE_SERVICE, UTILS_TREATMENT_SERVICE } from "services/utils";

class patientCreateController {

    constructor(
        $scope,
        $stateParams,
        $timeout,
        $window,
        alertService,
        confirmationService,
        intakeControlService,
        userService,
        tokenService,
        dateUtils,
        treatmentsUtils

    ) {
        this.$scope = $scope;
        this.$window = $window;
        this.token = tokenService.get();
        this.prevState = $stateParams.prevState;
        this.alertService = alertService;
        this.confirmationService = confirmationService;
        this.intakeControlService = intakeControlService;
        this.userService = userService;
        this.dateUtils = dateUtils;
        this.treatmentUtils = treatmentsUtils;

        this.patient = undefined;
        this.centerId = undefined;
        this.nextAppointmentDate = undefined;
        this.patientLoading = false;
        this.edicion = false;
        this.adaptationMode = false;
        this.adaptationPhaseFinished = false;
        this.canEditIntraPhase = false;
        this.canEditExtraordinaryAppointments = true;
        this.treatmentHasStarted = false;
        /**
         * dischargeError durante la fase de ascensos siempre es undefined.
         * Durante la fase adaptación se pone a true/false
         * tras dar de alta al paciente
         */
        this.dischargeError = undefined;
        this.dischargeMode = false;
        this.doseDataSectionDisabled = false;
        this.hospitalaryDoseSectionDisabled = false;
        this.intraDaysAdded = false;
        this.intraDaysRemoved = false;

        //TODO: si se abandona el state
        /*if (this.prevState == "alert") {
            alertActionService.setStatus((accepted, data) => {
                if (!accepted) return;

                if (this.token.role == "Doctor") {
                    //var alertUpdate = { _id: alert._id, doctorId: this.token.userId, state: data.action };
                    alertService.check(alertUpdate, (err, res) => {
                        if (err) return;
                        //this.loadAlerts();
                        //$state.go("app.admin-medical.patient-detail", { id: alert.userId, prevState: "alert" });
                    });
                }
            });
        }*/

        if ($stateParams.id) {
            this.patient = undefined;
            this.centerId = undefined;
            //this.patientLoading = true;

            userService.get($stateParams.id, (err, patient) => $timeout(() => {
                this.patientLoading = false;

                if (err) {
                    // TODO show error and redirect to somewhere
                    return console.error(err);
                }

                this.initPatientData(patient.data);
            }));
        }
    }

    initPatientData(patientData) {
        this.patient = patientData;
        this.centerId = this.patient.profiles[0].centerId;

        this.intakeControlService.resetPatientsCache();
        this.intakeControlService.startTimersRefresh();

        if (this.patient && this.isComplete(this.patient.treatments)) {
            this.initPatientTreatmentData();
        } else {
            this.edicion = true;
        }
    }

    getSelectedTreatments() {
        this.selectedTreatment;
    }

    initPatientTreatmentData() {
        this.patientTreatments = this.patient.treatments.sort((a, b) => a.starts - b.starts);
        this.currentTreatment = this.patientTreatments.find(treatment => treatment.active === true);
        this.selectedTreatment = this.currentTreatment;
        this.treatmentHasStarted = this.hasTreatmentStarted(this.currentTreatment);
        this.nextAppointmentDate = this.getNextAppointmentDate();
        this.adaptationMode = this.isInAdaptationPhase(this.currentTreatment);
        this.adaptationPhaseFinished = false == this.adaptationDaysRemain(this.currentTreatment);
        this.canEditIntraPhase = !this.hasTakenDomiciliaryDoses(this.currentTreatment);
        this.canEditExtraordinaryAppointments = !this.canEditIntraPhase;
        this.doseDataSectionDisabled = (this.currentTreatment.inIntakeControl && !this.currentTreatment.toArchive);
    }

    getNextAppointmentDate() {
        if (this.patient && this.patient.appointments && this.patient.appointments.length > 0) {
            const nextAppointment = this.patient.appointments[this.patient.appointments.length - 1];
            return nextAppointment.date;
        }
    }

    isInAdaptationPhase(treatment) {
        if (treatment && treatment.intra && this.treatmentHasStarted) {
            return this.adaptationDaysRemain(treatment);
        }

        return false;
    }

    hasCurrentTreatmentEnd() {
        return this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    adaptationDaysRemain(treatment) {
        const untakenIntraDays = treatment.intra.days.filter(day => !day.discharged);
        return untakenIntraDays.length > 0;
    }

    hasTakenDomiciliaryDoses(treatment) {
        if (treatment && treatment.weeks) {
            return undefined !== treatment.weeks.find(w => w.days && w.days.length);
        }

        return false;
    }

    hasTreatmentStarted(treatment) {
        return treatment && treatment.starts <= this.dateUtils.getCurrentTs();
    }

    onPatientRefreshNeeded() {
        if (!this.patient) {
            return;
        }

        this.userService.get(this.patient._id, (err, patient) => {
            if (err) {
                return console.error(err);
            }

            this.initPatientData(patient.data);
        });
    }

    onDoseDataEditEnter() {
        this.hospitalaryDoseSectionDisabled = true;
    }

    onDoseDataEditExit() {
        this.hospitalaryDoseSectionDisabled = false;
    }

    onHospitalaryDoseEditEnter() {
        this.doseDataSectionDisabled = true;
    }

    onHospitalaryDoseEditExit() {
        this.doseDataSectionDisabled = false;
    }

    // Para el alta en semana de ascenso
    onHospitalaryIntakeEnd() {
        this.dischargeMode = true;
        this.doseDataSectionDisabled = false;
        this.hospitalaryDoseSectionDisabled = true;
    }

    onPatientDischarged() {
        this.dischargeMode = false;
        this.doseDataSectionDisabled = false;
        this.hospitalaryDoseSectionDisabled = false;
    }

    onIntraDaysAdded() {
        this.onPatientRefreshNeeded();
        this.intraDaysAdded = true;
    }

    onIntraDaysRemoved() {
        this.onPatientRefreshNeeded();
        this.intraDaysRemoved = true;
    }

    isComplete(treatments) {
        if (treatments.length > 0) {
            const sortedTreatments = treatments.sort((a, b) => a.starts - b.starts);
            const currentTreatment = sortedTreatments.find(treatment => treatment.active === true);

            if (currentTreatment &&
                currentTreatment.starts &&
                currentTreatment.doctorId &&
                currentTreatment.type &&
                currentTreatment.allergen) {
                return true;
            }
        }

        return false;
    }

    dismissIntraDaysAddedAlert() {
        this.intraDaysAdded = false;
    }

    dismissIntraDaysRemovedAlert() {
        this.intraDaysRemoved = false;
    }

    // Para el alta tras la fase inicial
    onDischargePatient(authData) {
        const dischargeData = {
            type: this.currentTreatment.type,
            auth: authData
        };

        return this.userService.discharge(this.patient._id, dischargeData, (err) => {
            this.dischargeError = err ? true : false;
            if (err) {
                return this.unauthorizedMessage(err.status);
            }

            this.checkDischargeAlert(this.patient);
            this.onHospitalaryDoseEditExit();
            this.onPatientRefreshNeeded();
        });
    }

    checkDischargeAlert(patient) {
        this.alertService.checkPendingDischargeAlert(patient._id, (error) => {
            if (error) {
                return console.error(error);
            }

            this.$scope.$emit("alertsRefreshed");
        });
    }

    unauthorizedMessage(err) {
        if (err == 403) {
            this.confirmationService.error((accepted) => {
                if (accepted) {
                    return;
                } else {
                    return console.error(err);
                }
            });
        } else {
            console.error(err);
        }
    }

}

patientCreateController.$inject = [
    "$scope",
    "$stateParams",
    "$timeout",
    "$window",
    ALERT_SERVICE,
    CONFIRMATION_SERVICE,
    INTAKE_CONTROL_SERVICE,
    USERS_SERVICE,
    TOKEN_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default patientCreateController;
