import "angular-ui-bootstrap";

import { DOSE_DATA_SERVICE } from "./dose-data.service";
import { ALERT_SERVICE, USERS_SERVICE, DOSE_SERVICE } from "services/api-client";
import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { UTILS_PROTOCOL_SERVICE } from "services/utils";
import { UTILS_DATE_SERVICE } from "services/utils";
import { UTILS_NUMBERS_SERVICE } from "services/utils";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

class doseDataController {

    constructor(
        $scope,
        alertService,
        doseDataService,
        userService,
        confirmationService,
        doseService,
        protocolUtils,
        dateUtils,
        numberUtils,
        treatmentUtils
    ) {
        this.$scope = $scope;
        this.editNextDose;
        this.currentDose = undefined;
        this.lastEnduredDose = undefined;
        this.formIsNotValid = true;
        this.editMode = false;
        this.unit = undefined;
        this.dischargeError = undefined;
        this.highDoseWarningVisible = false;
        this.lowDoseWarningVisible = false;

        this.alertService = alertService;
        this.doseDataService = doseDataService;
        this.userService = userService;
        this.confirmationService = confirmationService;
        this.doseService = doseService;
        this.protocolUtils = protocolUtils;
        this.dateUtils = dateUtils;
        this.numberUtils = numberUtils;
        this.treatmentUtils = treatmentUtils;
    }

    $onChanges(changes) {
        if (changes.patient || changes.currentTreatment) {
            this.initCurrentTreatmentData();
            this.currentDose = this.treatmentUtils.getTreatmentCurrentDose(
                this.currentTreatment,
                "extrahospitalary");
            this.lastEnduredDose = this.doseDataService.getTreatmentLastEnduredDose(
                this.currentTreatment);
            this.exitEditMode();
        }
    }

    onNextDoseChanged() {
        try {
            const value = Number.parseFloat(eval(this.editNextDose));
            this.highDoseWarningVisible = value > eval(this.lastEnduredDose);
            this.lowDoseWarningVisible = value < eval(this.lastEnduredDose);
        } catch (e) { }

        if (this.treatmentUtils.getTreatmentUnit(this.currentTreatment) == "unidad") {
            if (this.protocolUtils.validateFractionedDose(this.editNextDose)) {
                this.formIsNotValid = false;
            } else {
                this.formIsNotValid = true;
            }
        } else {
            if (this.protocolUtils.validateNormalDose(this.editNextDose)) {
                this.formIsNotValid = false;
            } else {
                this.formIsNotValid = true;
            }
        }
    }

    initCurrentTreatmentData() {
        this.unit = this.treatmentUtils.getTreatmentUnit(this.currentTreatment);
    }

    submitDoseData() {
        if (!this.editNextDose) {
            return;
        }

        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (accepted) {
                this.updateAloneDosis(authData);
            }
        });
    }

    hasTreatmentStartedAndNotEnd() {
        return this.treatmentUtils.treatmentHasStarted(this.currentTreatment) && !this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    hasCurrentTreatmentEnd() {
        return this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    updateAloneDosis(authData) {
        let newDose;
        if (this.protocolUtils.validateFractionedDose(this.editNextDose)) {
            newDose = this.editNextDose;
        } else {
            newDose = this.numberUtils.fixToRealPrecission(this.editNextDose, 4);
        }
        const newDoseRequest = {
            auth: {
                pass: authData.pass,
                user: authData.user
            },
            type: this.currentTreatment.type,
            date: this.dateUtils.getCurrentTs(),
            dose: `${newDose} ${this.unit}`,
            doseType: "extrahospitalary",
        };

        this.doseService.set(this.patient._id, newDoseRequest, (err) => {
            if (err) {
                return this.unauthorizedMessage(err.status);
            }

            if (this.dischargeMode) {
                this.dischargePatient(authData);
            } else {
                this.onPatientRefreshNeeded();
            }
        });
    }

    enterEditMode() {
        this.editMode = true;
        this.editNextDose = undefined;
        this.onNextDoseChanged();
        this.onEnterEditMode();
    }

    exitEditMode() {
        this.editMode = false;
        this.onExitEditMode();
    }

    dischargePatient(authData) {
        const dischargeData = {
            type: this.currentTreatment.type,
            auth: authData
        };

        return this.userService.discharge(this.patient._id, dischargeData, (err) => {
            this.dischargeError = err ? true : false;
            if (err) {
                return this.unauthorizedMessage(err.status);
            }

            this.checkDischargeAlert(this.patient);
            this.onPatientRefreshNeeded();
            this.onPatientDischarged();
        });
    }

    checkDischargeAlert(patient) {
        this.alertService.checkPendingDischargeAlert(patient._id, (error) => {
            if (error) {
                return console.error(error);
            }

            this.$scope.$emit("alertsRefreshed");
        });
    }

    unauthorizedMessage(err) {
        if (err == 403) {
            this.confirmationService.error((accepted) => {
                if (accepted) {
                    return;
                } else {
                    return console.error(err);
                }
            });
        } else {
            console.error(err);
        }
    }

}

doseDataController.$inject = [
    "$scope",
    ALERT_SERVICE,
    DOSE_DATA_SERVICE,
    USERS_SERVICE,
    CONFIRMATION_SERVICE,
    DOSE_SERVICE,
    UTILS_PROTOCOL_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_NUMBERS_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default doseDataController;
