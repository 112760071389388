import { TOKEN_SERVICE } from "services/token";

var authInterceptorConfig = function ($httpProvider) {

    var interceptor = function ($q, $injector, __env) {
        var token = $injector.get(TOKEN_SERVICE);

        return {
            "request": function (config) {
                config.headers = Object.assign({}, config.headers);

                if(token.getRaw()) {
                    config.headers[__env.tokenHeader] = token.getRaw();
                }

                return config;
            },
            "responseError": function (response) {
                var $state = $injector.get("$state");

                if (response.status === 401/* || response.status === 403*/) {
                    token.delete();
                    $state.go("app.signin");
                }
                return $q.reject(response);
            },
            "response": function (response) {
                if(response.headers(__env.tokenHeader)){
                    token.set(response.headers(__env.tokenHeader));
                }

                return response;
            }
        };
    };

    interceptor.$inject = ["$q", "$injector", "__env"];

    $httpProvider.interceptors.push(interceptor);
};

authInterceptorConfig.$inject = ["$httpProvider"];

export default authInterceptorConfig;
