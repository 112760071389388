import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import { REACTIONS_COMPONENT, reactionListComponent } from "./reaction.component";

const REACTIONS_MODULE = "reactionListModule";

angular.module(REACTIONS_MODULE, [
    "ui.router",
    API_CLIENT,
])
.component( REACTIONS_COMPONENT, reactionListComponent );

export default REACTIONS_MODULE;
