import angular from "angular";
import { CENTERS_SERVICE, USERS_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";

class personalController {
    constructor($scope, $window, $stateParams, centersService, userService, tokenService) {
        $scope.userForm = {};
        $scope.usersForm;
        $scope.enablePassword;
        $scope.token = tokenService.get();
        $scope.constants = [
            { name: "TAS", unit: "mmHg", selected: false },
            { name: "TAD", unit: "mmHg", selected: false },
            { name: "Frecuencia cardíaca", unit: "lpm", selected: false },
            { name: "Peak Flow", unit: "L/min", selected: false },
            { name: "Sat O2", unit: "%", selected: false },
        ];
        $scope.newConstants = [];

        function loadUsers() {
            userService.getList((err, res) => {
                if (err) return console.error(err);
                $scope.users = res.data;
            });
        };

        function loadCenter() {
            centersService.get($scope.token.centerId, (err, res) => {
                if (err) return console.error(err);
                $scope.center = res.data;
            });
        };

        loadCenter();
        loadUsers();

        $scope.openCreateModal = function (formType) {
            $scope.formType = formType;
            $scope.userForm = {};
            $scope.usersForm.$setPristine();
            $window.$("#UserModal").modal("toggle");
        };

        $scope.openActivateUser = function (user) {
            $scope.EnableModal = Object.assign({}, user);
            $window.$("#EnableModal").modal("toggle");
        };

        $scope.activateUser = function () {
            userService.activateUser($scope.EnableModal._id, function () {
                loadUsers();
                $window.$("#EnableModal").modal("toggle");
            });
        };

        $scope.openDeleteUser = function (user) {
            $scope.UserDeleteModal = Object.assign({}, user);
            $window.$("#DeleteModal").modal("toggle");
        };

        $scope.deleteUser = function () {
            userService.deleteUser($scope.UserDeleteModal._id, function () {
                loadUsers();
                $window.$("#DeleteModal").modal("toggle");
            });
        };

        $scope.openModifyConstants = function () {
            $scope.newConstants = angular.copy($scope.constants);
            $scope.center.constants.forEach(function (constant) {
                let c = $scope.newConstants.find(ct => ct.name === constant.name);

                if (c)
                    c.selected = true;
            });
            $window.$("#ModifyConstants").modal("toggle");
        };

        $scope.updateConstants = function () {
            $scope.center.constants = $scope.newConstants;
            centersService.updateConstants($scope.center, function (err) {
                loadCenter();
                $window.$("#ModifyConstants").modal("toggle");
            });
        };

        $scope.openEditUser = function (user) {
            $scope.formType = "admin";
            $scope.userForm = Object.assign({}, user);
            $window.$("#UserModal").modal("toggle");
        };

        $scope.submitGuardarUser = function () {
            $scope.usersForm.$setSubmitted();
            if ($scope.usersForm.$invalid) {
                return;
            }

            if (!$scope.userForm._id) {
                let user = {
                    fullName: $scope.userForm.fullName,
                    email: $scope.userForm.email,
                    nick: $scope.userForm.nick,
                    pass: $scope.userForm.password,
                    profiles: [
                        {
                            role: $scope.userForm.role,
                            centerId: $scope.center._id,
                            active: true
                        }
                    ]
                };

                if ($scope.formType != "doctor") {
                    user.profiles[0].role = "CenterAdmin";
                }

                userService.create(user, (err, res) => {
                    if (err) {
                        $scope.errorMessage = "Error al crear administrador del centro";
                        return;
                    }
                    loadUsers();
                    $window.$("#UserModal").modal("toggle");
                });

            } else {
                // TODO: cambiar a peticion de API (pendiente)
                var updateUser = {
                    fullName: $scope.userForm.fullName,
                    email: $scope.userForm.email,
                    nick: $scope.userForm.nick
                };

                if ($scope.userForm.password && $scope.userForm.password != "") {
                    updateUser.pass = $scope.userForm.password;
                }

                userService.modifyUser($scope.userForm._id, updateUser, function () {
                    loadUsers();
                    $window.$("#UserModal").modal("toggle");
                });
            }
        };
    }
}

personalController.$inject = ["$scope", "$window", "$stateParams", CENTERS_SERVICE, USERS_SERVICE, TOKEN_SERVICE];

export default personalController;
