const UTILS_APPOINTMENT_SERVICE = "utilsAppointmentService";

var UtilsAppointmentService = function () {

    var utilsAppointmentService = {};

    utilsAppointmentService.getLastAppointmentBeforeDate = function (appointmentList, types, date) {
        let listAppointmentBefore = this.filterAppointmentBeforeDate(this.filterAppointmentByType(appointmentList, types), date);
        return listAppointmentBefore.pop();
    };

    utilsAppointmentService.getFirstAppointmentAfterDate = function (appointmentList, types, date) {
        let listAppointmentsAfter = this.filterAppointmentAfterDate(this.filterAppointmentByType(appointmentList, types), date);
        return listAppointmentsAfter.pop();
    };

    utilsAppointmentService.filterAppointmentByType = function (appointmentList, types) {
        return appointmentList.filter(elem => (types.indexOf(elem.type) >= 0));
    };

    utilsAppointmentService.filterAppointmentBeforeDate = function (appointmentList, date) {
        return appointmentList.filter(elem => (elem.date < date));
    };

    utilsAppointmentService.filterAppointmentAfterDate = function (appointmentList, date) {
        return appointmentList.filter(elem => (elem.date > date));
    };

    return utilsAppointmentService;
};

UtilsAppointmentService.$inject = [];

export { UTILS_APPOINTMENT_SERVICE, UtilsAppointmentService };
