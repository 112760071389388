import informsTemplate from "./informs.template";
import informsController from "./informs.controller";

var informsStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-medical.informs",
        url: "/informes",
        template: informsTemplate,
        controller: informsController,
    });
};

informsStates.$inject = ["$stateProvider"];

export default informsStates;
