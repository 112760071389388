import personalTemplate from "./personal-medico.template";
import personalController from "./personal-medico.controller";

var personalStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-center.personal",
        url: "/",
        params: {
            id: ":id"
        },
        template: personalTemplate,
        controller: personalController,
    });
};

personalStates.$inject = ["$stateProvider"];

export default personalStates;
