import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { ADAPTATION_DOSE_COMPONENT, adaptationDoseComponent } from "./adaptation-dose.component";
import { PROTOCOL_EDITOR_MODULE } from "services/protocols";

const ADAPTATION_DOSE_MODULE = "adaptationDoseModule";

angular.module(ADAPTATION_DOSE_MODULE, [
    "ui.router",
    API_CLIENT,
    PROTOCOL_EDITOR_MODULE,
]).component(ADAPTATION_DOSE_COMPONENT, adaptationDoseComponent);

export default ADAPTATION_DOSE_MODULE;
