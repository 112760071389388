const CLINICAL_TRIAL_SERVICE = "CLINICAL_TRIAL_SERVICE";

var ClinicalTrialService   = function ($http, __env) {
    var apiUrl = `${__env.csvServiceUrl}/clinical-trial`;

    var clinicalTrialService = {};

    clinicalTrialService.exportCsv = function(callback) {
        return $http.get(`${apiUrl}/export`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    }


    clinicalTrialService.exportReactionsCsv = function(callback) {
        return $http.get(`${apiUrl}/reactions/export`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    }

    return clinicalTrialService;
}

ClinicalTrialService.$inject = ["$http", "__env"];

export { CLINICAL_TRIAL_SERVICE, ClinicalTrialService };
