import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import {  COMPARATIVE_MULTI_INTAKE_COMPONENT, comparativeMultiIntakeComponent } from "./comparative-multi-intake.component";

const COMPARATIVE_MULTI_INTAKE_MODULE = "comparativeMultiIntakeModule";

angular.module(COMPARATIVE_MULTI_INTAKE_MODULE, [
    "ui.router",
    API_CLIENT,
])
.component(  COMPARATIVE_MULTI_INTAKE_COMPONENT, comparativeMultiIntakeComponent );

export default COMPARATIVE_MULTI_INTAKE_MODULE;
