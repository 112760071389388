import { ALERT_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";
import template from "./discharge-alert-modal.template";

const DISCHARGE_ALERT_SERVICE = "dischargeAlertService";

class DischargeAlertService {

    constructor($state, $uibModal, alertService, tokenService) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.alertService = alertService;
        this.tokenService = tokenService;
        this.modalIsOpened = false;
    }

    dischargePatient(patient) {
        this.createDischargeAlert(patient);

        if (this.tokenService.get().role === "Doctor") {
            this.openDischargeModal(patient, true);
        } else {
            this.$state.go("app.admin-medical.control-tomas");
        }
    }

    openDischargeModal(patient, redirectOnDecline) {
        if (this.modalIsOpened) {
            return;
        }

        const modal = this.$uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            backdrop: "static",
            controller: ["$scope", ($scope) =>
                this.dischargeModalController($scope, patient, modal, redirectOnDecline)
            ],
            keyboard: false,
            template: template,
            size: "md",
        });
        this.modalIsOpened = true;
    }

    dischargeModalController($scope, patient, modal, redirectOnDecline) {
        $scope.patient = patient,
        $scope.accept = () => {
            modal.close();
            this.modalIsOpened = false;
            this.$state.go("app.admin-medical.patient-detail", { id: patient._id });
        };
        $scope.decline = () => {
            modal.close();
            this.modalIsOpened = false;
            this.postponeDischargeAlert(patient, redirectOnDecline);
        };
    }

    createDischargeAlert(patient) {
        const alert = {
            userId: patient._id,
            userName: patient.fullName,
        };

        this.alertService.createPendingDischargeAlert(alert, (error) => {
            if (error) {
                return console.error(error);
            }
        });
    }

    postponeDischargeAlert(patient, redirect) {
        this.alertService.postponePendingDischargeAlert(patient._id, (error) => {
            if (error) {
                return console.error(error);
            }

            if (redirect == true) {
                this.$state.go("app.admin-medical.control-tomas");
            }
        });
    }
}

DischargeAlertService.$inject = [
    "$state",
    "$uibModal",
    ALERT_SERVICE,
    TOKEN_SERVICE,
];

export { DISCHARGE_ALERT_SERVICE, DischargeAlertService };
