class AppointmentControllerViewModel {

    constructor() {
        this.ordinary = {
            last: {
                date: undefined
            },
            next: {
                onEdit: false,
                id: undefined,
                date: undefined,
                time: undefined
            },
            onEdit: false
        };

        this.extraordinary = {
            next: {
                onEdit: false,
                id: undefined,
                date: undefined,
                time: undefined
            },
            nexts: [],
            onEdit: false
        };
    }

    setOrdinaryLastDate(date) {
        this.ordinary.last = date;
    }

    setOrdinaryNextDate(appointment) {
        this.ordinary.next.id = appointment ? appointment._id : undefined;
        this.ordinary.next.date = appointment ? appointment.date : undefined;
        this.ordinary.next.time = appointment ? appointment.date : undefined;
    }

    setExtraordinaryNexts(nextsDates) {
        this.extraordinary.nexts = nextsDates;
    }

    setExtraOrdinaryNextAppointmentDate(appointment) {
        this.extraordinary.next.id = appointment ? appointment._id : undefined;
        this.extraordinary.next.date = appointment ? appointment.date : undefined;
        this.extraordinary.next.time = appointment ? appointment.date : undefined;
    }

}

module.exports = AppointmentControllerViewModel;
