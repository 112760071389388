import centerListTemplate from "./center-list.template";
import centerListController from "./center-list.controller";

var centerListStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-app.center-list",
        url: "",
        template: centerListTemplate,
        controller: centerListController,
    });
};

centerListStates.$inject = ["$stateProvider"];

export default centerListStates;
