import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { UTILS_DATE_SERVICE, UTILS_PROTOCOL_SERVICE, UTILS_TREATMENT_SERVICE } from "services/utils";

const DOSE_DATA_SERVICE = "doseDataService";

const doseDataService = function (
    dateUtils,
    protocolUtils,
    treatmentUtils,
    reactionAlgorithm
) {

    function getTreatmentLastEnduredDose(treatment, untilDate) {
        if (!treatment || !treatment.weeks) {
            return undefined;
        }
        const unit = treatmentUtils.getTreatmentUnit(treatment);
        untilDate = untilDate ? untilDate : dateUtils.getCurrentTs();
        const pastWeeks = treatment.weeks
            .filter(week => week.date <= untilDate);
        pastWeeks.sort((a, b) => b.date - a.date); // Reverse order

        for (let i = 0; i < pastWeeks.length; i++) {
            const week = pastWeeks[i];
            const lastEnduredDose = getWeekLastEnduredDose(week, untilDate, false, unit);
            if (lastEnduredDose) {
                return lastEnduredDose;
            }
        }

        if (treatment.intra && treatment.intra.days) {
            const week = treatment.intra;
            const lastEnduredDose = getWeekLastEnduredDose(week, untilDate, true, unit);
            if (lastEnduredDose) {
                return lastEnduredDose;
            }
        }

        return undefined;
    }

    function getWeekLastEnduredDose(week, untilDate, isIntra, unit) {
        const pastDays = week.days
            .filter(day => day.date <= untilDate);
        pastDays.sort((a, b) => b.date - a.date); // Reverse order

        for (let i = 0; i < pastDays.length; i++) {
            const day = pastDays[i];
            const lastEnduredDose = getDayLastEnduredDose(day, isIntra, unit);

            if (lastEnduredDose) {
                return lastEnduredDose;
            }
        }

        return undefined;
    }

    function getDayLastEnduredDose(day, isIntra, unit) {
        const takenIntakes = day.intakes
            .filter(intake => intake.state == "taken");
        takenIntakes.sort((a, b) => b.date - a.date); // Reverse order

        for (let i = 0; i < takenIntakes.length; i++) {
            const intake = takenIntakes[i];
            if (!reactionAlgorithm.intakeHasNoIgnorableReactions(intake)) {
                const lastEnduredIntake = intake.patientIntake;
                const lastEnduredDose = lastEnduredIntake.split(" ")[0];

                if (unit == "unidad") {
                    return lastEnduredDose;
                } else {
                    return isIntra
                        ? applyDilutionToDose(lastEnduredDose, intake.volume)
                        : lastEnduredDose;
                }
            }
        }

        return undefined;
    }

    function applyDilutionToDose(dose, intakeVolume) {
        const volume = protocolUtils.cleanVolumeValue(intakeVolume);
        if (volume == null || !protocolUtils.validateVolume(volume)) {
            return dose;
        }
        return eval(volume) * dose;
    }

    return {
        getDayLastEnduredDose: getDayLastEnduredDose,
        getTreatmentLastEnduredDose: getTreatmentLastEnduredDose,
    };

};

doseDataService.$inject = [
    UTILS_DATE_SERVICE,
    UTILS_PROTOCOL_SERVICE,
    UTILS_TREATMENT_SERVICE,
    REACTION_ALGORITHM_SERVICE
];

export { DOSE_DATA_SERVICE, doseDataService };
