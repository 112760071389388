import angular from "angular";
import "ngstorage";

import { LOGGER_MODULE } from "services/logger";

const TOKEN_MODULE = "tokenModule";
import { TOKEN_SERVICE, TokenService } from "./token.service";

angular.module(TOKEN_MODULE, [
    "ngStorage",
    LOGGER_MODULE,
])
    .factory(TOKEN_SERVICE, TokenService);

export { TOKEN_MODULE, TOKEN_SERVICE };
