const UTILS_PROTOCOL_SERVICE = "utilsProtocolsService";

const UtilsProtocolsService = function () {

    const VOLUME_PATTERN = /^(\s*\d+\s*\/\s*\d+\s*|\s*\-\s*|\s*0\s*|)$/;
    const DOSE_FRACTIONED_PATTERN = /^(\s*\d+\s*\/\s*\d+\s*|\s*\d+\s*|\s*0\s*|)$/;
    const ONLY_FRACTIONES_PATTER = /^(\s*\d+\s*\/\s*\d+\s*|\s*\s*|\s*0\s*|)$/;
    const NORMAL_DOSE_PATTERN = /^\d+(|(\.\d+))$/;

    const utilsProtocolService = {};

    utilsProtocolService.validateVolume = function (volume) {
        return volume == null || VOLUME_PATTERN.test(volume);
    };

    utilsProtocolService.validateFractionedDose = function (dose) {
        return dose == null || DOSE_FRACTIONED_PATTERN.test(dose);
    };

    utilsProtocolService.validateOnlyFractionedDose = function (dose) {
        return dose == null || ONLY_FRACTIONES_PATTER.test(dose);
    };

    utilsProtocolService.validateNormalDose = function (dose) {
        return dose == null || NORMAL_DOSE_PATTERN.test(dose);
    };

    utilsProtocolService.cleanVolumeValue = function (value) {
        if (utilsProtocolService.isNullVolume(value)) {
            return null;
        }

        return value
            .replace(/ /g, "")
            .replace(/:/g, "/");
    };

    utilsProtocolService.isNullVolume = function (value) {
        return value == null
            || value.trim() == ""
            || value.trim() == "-"
            || value.trim() == "0";
    };

    return utilsProtocolService;
};

UtilsProtocolsService.$inject = [];

export { UTILS_PROTOCOL_SERVICE, UtilsProtocolsService };
