import angular from "angular";

import { TOKEN_MODULE } from "services/token";
import { DISCHARGE_ALERT_LIST_COMPONENT, dischargeAlertListComponent } from "./discharge-alert-list.component";
import { DISCHARGE_ALERT_MODULE } from "../discharge-alert";

const DISCHARGE_ALERT_LIST_MODULE = "dischargeAlertListModule";

angular.module(DISCHARGE_ALERT_LIST_MODULE, [
    DISCHARGE_ALERT_MODULE,
    TOKEN_MODULE,
]).component(DISCHARGE_ALERT_LIST_COMPONENT, dischargeAlertListComponent);

export default DISCHARGE_ALERT_LIST_MODULE;
