import "angular-ui-bootstrap";

import { UTILS_DATE_SERVICE } from "services/utils";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

const MAX_TO_SHOW = 7;

class reactionListController {

    constructor(dateUtils, utilsTreatmentService) {
        this.dateUtils = dateUtils;
        this.utilsTreatmentService = utilsTreatmentService;
    }

    $onChanges() {
        this.showingAll = false;
        this.reactions = [];
        this.fullReactions = [];

        this.currentTreatment = this.selectedTreatment;
        if (this.currentTreatment) {
            this.fillReactions(this.currentTreatment);
            this.fullReactions = this.fullReactions.sort((a, b) => b.date - a.date);
            this.reactions = this.fullReactions.slice(0, MAX_TO_SHOW);
        }
    }

    showAll() {
        this.reactions = this.fullReactions;
        this.showingAll = true;
    }

    fillReactions(treatment) {
        this.searchReactionsInWeeks(treatment.weeks);
        this.searchReactionsInWeek(treatment.intra);
    }

    addReactionsInReactionList(intake) {
        this.fullReactions.push({
            date: intake.date,
            dose: intake.patientIntake,
            reactions: intake.reactions,
            followed: intake.suggesting
                ? this.calculeIfFollowedAllSuggestings(intake.suggesting)
                : false,
            cofactors: intake.cofactors,
            notes: intake.notes,
            hospitalary: intake.intakeType != "extrahospitalary",
        });
    }

    searchReactionsInWeeks(weeks) {
        if (weeks && weeks.length) {
            for (let i = 0; i < weeks.length; i++) {
                this.searchReactionsInWeek(weeks[i]);
            }
        }
    }

    searchReactionsInWeek(week) {
        if (week.days && week.days.length) {
            const days = week.days;
            for (let i = 0; i < days.length; i++) {
                const day = days[i];
                if (day.date > this.dateUtils.getCurrentTs()) {
                    break;
                }

                this.searchReactionsInDay(day);
            }
        }
    }

    searchReactionsInDay(day) {
        const intakes = day.intakes;

        if (intakes && intakes.length > 0) {
            const lastIntake = intakes[intakes.length - 1];
            if (lastIntake.reactions && lastIntake.reactions.length > 0) {
                this.addReactionsInReactionList(lastIntake);
            }
        }
    }

    calculeIfFollowedAllSuggestings(suggestings) {
        if (suggestings && suggestings.length == 0) {
            return false;
        }

        let follow = true;
        if (suggestings != undefined) {
            for (let i = 0; i < suggestings.length; i++) {
                follow = follow && suggestings[i].intake;
            }
            return follow;
        }
    }
}

reactionListController.$inject = [UTILS_DATE_SERVICE, UTILS_TREATMENT_SERVICE];

export default reactionListController;
