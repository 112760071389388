import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { TOKEN_MODULE } from "services/token";
import { CONFIRMATION_MODULE } from "services/modal/confirmation";

import signInStates from "./signin.states";
import "./signin.styles";

const SIGNIN_MODULE = "signInModule";

angular.module(SIGNIN_MODULE, [
    "ui.router",
    API_CLIENT,
    TOKEN_MODULE,
    CONFIRMATION_MODULE,
])
    .config(signInStates);

export default SIGNIN_MODULE;
