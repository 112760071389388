import comparativeTemplate from "./comparative.template";
import comparativeController from "./comparative.controller";

const COMPARATIVE_COMPONENT = "comparativeChart";

const comparativeComponent = {
    template: comparativeTemplate,
    controller: comparativeController,
    bindings: {
        patient: "<",
        onPatientRefreshNeeded: "&",
        selectedTreatment: "<",
    }
};

export { COMPARATIVE_COMPONENT, comparativeComponent };
