import angular from "angular";

const PROTOCOL_MODAL_MODULE = "ProtocolModalModule";

import { PROTOCOL_MODAL_SERVICE, ProtocolModalService } from "./protocol.service";

angular.module( PROTOCOL_MODAL_MODULE, [])
    .factory(PROTOCOL_MODAL_SERVICE, ProtocolModalService);

export { PROTOCOL_MODAL_MODULE, PROTOCOL_MODAL_SERVICE };
