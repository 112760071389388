import angular from "angular";

const INFO_MODAL_MODULE = "infoModalModule";

import { INFO_MODAL_SERVICE, InfoModalService } from "./info.service";

angular.module(INFO_MODAL_MODULE, [])
    .factory(INFO_MODAL_SERVICE, InfoModalService);

export { INFO_MODAL_MODULE, INFO_MODAL_SERVICE };
