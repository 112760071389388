import passwordTemplate from "./password-change.template";

const PASSWORD_CHANGE_MODAL_SERVICE = "passwordChangeModalService";

const PasswordChangeModalService = function ($uibModal) {
    const passwordChangeModalService = {};

    passwordChangeModalService.show = function (callback = () => { }) {
        let handled = false;

        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: passwordTemplate,
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    $scope.usersForm.$setSubmitted();
                    $scope.samePass = false;

                    if ($scope.usersForm.userPass.$viewValue == undefined || $scope.usersForm.userPass.$viewValue == "") {
                        $scope.usersForm.userPass.$pristine = true;
                    } else {
                        $scope.usersForm.userPass.$pristine = false;

                        if ($scope.usersForm.userPassRepeat.$viewValue == undefined || $scope.usersForm.userPassRepeat.$viewValue == "") {
                            $scope.usersForm.userPassRepeat.$pristine = true;
                        } else {
                            $scope.usersForm.userPassRepeat.$pristine = false;

                            if ($scope.usersForm.userPass.$viewValue === $scope.usersForm.userPassRepeat.$viewValue &&
                                $scope.usersForm.$error.pattern == undefined && $scope.usersForm.$error.minlength == undefined) {

                                $scope.samePass = false;

                                $scope.un = false;
                                modal.close();
                                handled = true;
                                callback(true, $scope.usersForm.userPass.$viewValue);

                            } else if ($scope.usersForm.userPass.$viewValue === $scope.usersForm.userPassRepeat.$viewValue &&
                                $scope.usersForm.$error.pattern != undefined && $scope.usersForm.$error.minlength != undefined) {
                                $scope.samePass = false;

                            } else {
                                $scope.samePass = true;
                            }
                        }
                    }
                };

                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    return passwordChangeModalService;
};

PasswordChangeModalService.$inject = ["$uibModal"];

export { PASSWORD_CHANGE_MODAL_SERVICE, PasswordChangeModalService };
