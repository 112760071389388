import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";

import protocolsStates from "./protocols.states";

import PROTOCOLS_HEAD_EDITOR from "./protocols-head";
import PROTOCOLS_EDITOR_MODULE from "./protocols-editor";
import PROTOCOLS_INTRA_EDITOR_MODULE from "./protocols-intra-editor";
import { PROTOCOLS_COMPONENT, protocolsComponent } from "./protocols.component";

const PROTOCOLS_MODULE = "protocolsModule";

angular.module(PROTOCOLS_MODULE, [
    "ui.router",
    API_CLIENT,
    PROTOCOLS_HEAD_EDITOR,
    PROTOCOLS_EDITOR_MODULE,
    PROTOCOLS_INTRA_EDITOR_MODULE,
])
    .component(PROTOCOLS_COMPONENT, protocolsComponent)
    .config(protocolsStates);

export default PROTOCOLS_MODULE;
