import cancelIntakeTemplate from "./cancel-intake.template";

const CANCEL_INTAKE_MODAL_SERVICE = "cancelIntakeModalService";

const CancelIntakeService = function ($uibModal) {
    const cancelIntakeModalService = {};

    cancelIntakeModalService.show = function (callback = () => { }) {
        let handled = false;

        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: cancelIntakeTemplate,
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);
                };

                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    return cancelIntakeModalService;
};

CancelIntakeService.$inject = ["$uibModal"];

export { CANCEL_INTAKE_MODAL_SERVICE, CancelIntakeService };
