import Clipboard from "clipboard";
import loadingImage from "images/loading.gif";

import { USERS_SERVICE, INTAKE_CONTROL_SERVICE } from "services/api-client";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { INTAKE_MODAL_SERVICE } from "services/modal/intake-modal";
import { TOKEN_SERVICE } from "services/token";
import { UTILS_DATE_SERVICE } from "services/utils";

class intakeControlController {

    constructor(
        $state,
        userService,
        tokenService,
        errorModal,
        intakeModal,
        intakeControlService,
        dateUtils
    ) {
        this.state = $state;
        this.userService = userService;
        this.errorModal = errorModal;
        this.intakeModal = intakeModal;
        this.intakeControlService = intakeControlService;
        this.dateUtils = dateUtils;
        this.generaInforme = this.generaInforme.bind(this);
        this.loadingImage = loadingImage;
        this.loading = true;
        this.token = tokenService.get();
        this.patients = [];
        this.patientDischarged = [];
        this.constants = [];
        this.timers = [];
    }

    $onInit() {
        const today = this.dateUtils.getCurrentDate();
        this.weekDay = this.dateUtils.getWeekdayLocalized(today);
        this.localizedDate = this.intakeControlService.formatDateLocalized(today);

        this.intakeControlService.resetPatientsCache();

        this.intakeControlService.load(() => {
            this.patients = this.intakeControlService.getPatients();
            this.patientDischarged = this.intakeControlService.getPatientsDischarged();
            this.loading = false;
        });

        this.intakeControlService.getConstants(this.token.centerId, (err, result) => {
            if (result) {
                this.constants = result;
            }
        });
        this.timers = this.intakeControlService.getTimers();
        this.intakeControlService.startTimersRefresh();
    }

    isNextIntakeDosisAltered(patient) {
        const intake = this.getNextIntake(patient);

        return intake && intake.doseAltered;
    }

    isNextIntakeVolumeAltered(patient) {
        const intake = this.getNextIntake(patient);

        return intake && intake.volumeAltered;
    }

    thereAreAlteredIntakes() {
        for (const i in this.patients) {
            const intake = this.getNextIntake(this.patients[i]);
            if (intake && (intake.doseAltered || intake.volumeAltered)) {
                return true;
            }
        }

        return false;
    }

    getPatientTimer(patientId) {
        return this.timers[patientId] ? this.timers[patientId].time : null;
    }

    getIntra(cita) {
        const treatments = cita.treatments;
        return treatments[treatments.length - 1].intra ? true : false;
    }

    patientCanTake(patient) {
        const patientTreatmentDay = this.getTreatmentCurrentDay(patient);

        return patientTreatmentDay != undefined
            && patientTreatmentDay.intakes.length > patient.intakesTaken;
    }

    onClickApplyIntake(patientId, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        const patient = this.patients.find(p => p._id === patientId);

        this.intakeControlService.delayTreatmentStartDateIfNeeded(
            patient.treatments.isIntra,
            {
                days: patient.treatments.days
            },
            patient.treatments.starts,
            patient.treatments._id
        ).then((delayed) => {
            if (!delayed) {
                return this.applyIntake(patientId);
            }

            this.intakeControlService.resetPatientsCache();
            this.intakeControlService.load(() => {
                this.patients = this.intakeControlService.getPatients();
                this.patientDischarged = this.intakeControlService.getPatientsDischarged();
                this.loading = false;
                this.applyIntake(patientId);
            });
        });
    }

    applyIntake(patientId) {
        const now = this.dateUtils.getCurrentTs();
        const patient = this.patients.find(p => p._id === patientId);
        const treatmentDay = this.intakeControlService.getTreatmentDay(patient.treatments, now);

        if (treatmentDay.expired || treatmentDay.state === "revision") {
            if (patient.intakesTaken < treatmentDay.intakes.length) {
                treatmentDay.state = "pendingIntake";
            }

            const intake = treatmentDay.intakes[patient.intakesTaken];
            intake.pdate = intake.date;
            intake.date = now;
            intake.state = "taken";
            intake.type = patient.type;
            intake.patientIntake = intake.dose;
            intake.doctorDose = intake.dose;
            intake.protocolDose = intake.dose;
            intake.index = patient.intakesTaken;

            const timerExists = this.intakeControlService.timerExists(patientId);
            const timerIsFinished = this.intakeControlService.timerIsFinished(patientId, now);
            if (!timerExists || timerIsFinished) {
                patient.intakesTaken++;
                treatmentDay.expired = false;
                this.intakeControlService.addTimer(patientId, intake);
            }

            const errorModal = this.errorModal;
            this.userService.updateIntraIntakes(patientId, treatmentDay.date, intake, function (err) {
                if (err) {
                    errorModal.show("Error", "Ha ocurrido un error al actualizar la toma.");
                }
            });
        }
    }

    showDetail(_id) {
        this.state.go("app.admin-medical.control-tomas-detail", { id: _id });
    }

    showMedicianTable(index, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        const patient = this.patients[index];

        if (patient.intakesTaken > 0) {
            const intake = patient.treatments.days[0].intakes[patient.intakesTaken - 1];
            const now = this.dateUtils.getCurrentDate();
            if (!intake.reactDate) {
                const treatmentDay = this.intakeControlService.getTreatmentDay(patient.treatments, now);
                intake.reactDate = now.getTime();
                this.userService.updateIntraIntakes(patient._id, treatmentDay.date, intake, function () { });
            }
        }

        this.intakeModal.showMedicinesTable(patient._id, patient.reacAsis.hospital);
    }

    showConstants(index, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        const patient = this.patients[index];
        const constants = this.constants.slice();

        this.intakeModal.showMedicinesTable(patient._id, patient, constants);
    }

    copy(index, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        const generaInforme = this.generaInforme;
        const getTreatmentCurrentDay = this.getTreatmentCurrentDay;

        const patient = this.patientDischarged[index];
        const days = patient.treatments.days;

        const todayIsLastDayOfIntra = patient.treatments.isIntra &&
            days.length - 1 === patient.treatments.currentDay;

        let informe = "";
        if (todayIsLastDayOfIntra) {
            for (let i = 0; i < days.length; i++) {
                informe += generaInforme(patient, days[i]);
                informe += "\n\n";
            }
        } else {
            const currentDay = getTreatmentCurrentDay(patient);
            informe = generaInforme(patient, currentDay);
        }

        const clipboard = new Clipboard($event.target, {
            text: () => informe
        });
        clipboard.onClick($event);
        clipboard.destroy();

        this.intakeModal.showClipBoardMessage();
    }

    generaInforme(patient, day) {
        const intake = this.getLastIntakeOfDay(day);
        const intakesNotes = this.getIntakesNotes(day.intakes);
        const hasReactions = intake.reactions && intake.reactions.length > 0;
        const hasMedication = intake.suggesting && intake.suggesting.length > 0;
        const isIntra = patient.treatments.isIntra;
        const lastDay = patient.treatments.days.length - 1 === patient.treatments.currentDay;
        const lastExtraHospitalaryDose = this.getLastExtraHospitalaryDose(patient.treatments);
        const dayFormatedDate = this.intakeControlService.formatDateLocalized(intake.date);
        const dayFormatedDateTime = this.intakeControlService.formatDateTimeLocalized(intake.date);

        let informe = this.getTextOfInform(lastExtraHospitalaryDose, isIntra, hasReactions, lastDay);
        informe = informe.replace("{FECHA_HORA}", dayFormatedDateTime)
            .replace("{NOMBRE_APELLIDOS}", patient.fullName)
            .replace("{FECHA}", dayFormatedDate)
            .replace("{DOSIS}", intake.dose)
            .replace("{ALERGENO}", patient.allergen)
            .replace("{PROTOCOLO}", patient.type);

        if (lastExtraHospitalaryDose != undefined) {
            informe = informe.replace("{DOSIS_PRESCRITA_DOMICILIO}", lastExtraHospitalaryDose);
        }

        if (!!intake.volume && intake.volume.trim() != "-") {
            informe = informe.replace("{DILUCION}", `con dilución ${intake.volume}`);
        } else {
            informe = informe.replace("{DILUCION}", "sin dilución");
        }

        if (patient.gender !== "male") {
            informe = informe.replace("El paciente", "La paciente");
        }

        if (hasReactions) {
            let reacts = "";
            intake.reactions.forEach(reaction => reacts = reacts + reaction + "\n");
            informe = informe.replace("{LISTADO_DE_SINTOMAS}", reacts);
        }

        if (hasMedication) {
            const medicines = [];
            for (let i = 0; i < intake.suggesting.length; i++) {
                const medicine = intake.suggesting[i];
                medicines.push(`${medicine.dose} de ${medicine.medicine}`);
            }

            informe = informe.replace("{MEDICACION}", `Se le ha administrado ${medicines.join(", ")}`);
        } else if (hasReactions && !hasMedication) {
            informe = informe.replace("{MEDICACION}", "NO se ha administrado medicación");
        }

        if (patient.nextAppointment && lastDay) {
            informe += `\nAcudirá a nueva cita el día ${patient.nextAppointment}.`;
        }

        if (!!intakesNotes) {
            informe += "\n\nNotas tomadas durante la toma:\n";
            informe += intakesNotes;
        }

        return informe;
    }

    /**
     * @param {Array} intakes
     *
     * @returns {String}
     */
    getIntakesNotes(intakes) {
        const notes = [];

        for (let i = 0; i < intakes.length; i++) {
            if (!!intakes[i].notes && intakes[i].notes.trim() != "") {
                const dose = intakes[i].dose
                    ? intakes[i].dose
                    : intakes[i].patientIntake;

                notes.push(`Toma nº${(i + 1)} (${dose}): ${intakes[i].notes}.`);
            }
        }

        return notes.join("\n");
    }

    getLastExtraHospitalaryDose(treatment) {
        if (!treatment.doctorDoses) {
            return undefined;
        }

        for (let i = treatment.doctorDoses.length - 1; i >= 0; i--) {
            const doctorDose = treatment.doctorDoses[i];
            if (doctorDose.doseType != "hospitalary") {
                return doctorDose.dose;
            }
        }

        return undefined;
    }

    getTakenIntakes(patient) {
        return patient.intakesTaken;
    }

    getTreatmentCurrentDay(patient) {
        return patient.treatments.days[patient.treatments.currentDay];
    }

    getLastIntake(patient) {
        const treatmentDay = this.getTreatmentCurrentDay(patient);

        return treatmentDay.intakes[patient.intakesTaken - 1];
    }

    getNextIntake(patient) {
        const treatmentDay = this.getTreatmentCurrentDay(patient);

        return treatmentDay.intakes[patient.intakesTaken];
    }

    getTotalIntakes(patient) {
        const treatmentDay = this.getTreatmentCurrentDay(patient);

        return (treatmentDay && treatmentDay.intakes)
            ? treatmentDay.intakes.length
            : 0;
    }

    getLastTakenIntake(patient) {
        const treatmentDay = this.getTreatmentCurrentDay(patient);

        return (treatmentDay && treatmentDay.intakes)
            ? treatmentDay.intakes[patient.intakesTaken]
            : 0;
    }

    getLastIntakeOfDay(day) {
        const intakes = day.intakes.filter(
            intake => intake.state === "taken"
        );

        return intakes.pop();
    }

    hasTakenAllIntakes(patient) {
        return this.getTotalIntakes(patient) === patient.intakesTaken;
    }

    getTextOfInform(lastExtraHospitalaryDose, isIntra, hasReactions, lastDay) {
        let informe = "";

        if (!isIntra) {
            if (!hasReactions) {
                if (lastExtraHospitalaryDose != undefined) {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se le ha " +
                        "administrado la dosis de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" sin presentar reacción. " +
                        "Se le ha indicado el consumo en su domicilio de la dosis de {DOSIS_PRESCRITA_DOMICILIO}.";
                } else {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se le ha " +
                        "administrado la dosis de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" sin presentar reacción. " +
                        "No se le ha prescrito ninguna dosis.";
                }
            } else {
                if (lastExtraHospitalaryDose != undefined) {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se le ha " +
                        "administrado la dosis de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" presentando la " +
                        "siguiente reacción:\n\n{LISTADO_DE_SINTOMAS}\n" +
                        "{MEDICACION} y se ha dado de alta en condiciones estables. " +
                        "Se le ha indicado el consumo en su domicilio de la dosis de {DOSIS_PRESCRITA_DOMICILIO}.";
                } else {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se le ha " +
                        "administrado la dosis de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" presentando la " +
                        "siguiente reacción:\n\n{LISTADO_DE_SINTOMAS}\n" +
                        "{MEDICACION} y se ha dado de alta en condiciones estables. " +
                        "No se le ha prescrito ninguna dosis.";
                }
            }
        } else {
            if (hasReactions) {
                if (lastDay) {
                    if (lastExtraHospitalaryDose != undefined) {
                        informe = "{FECHA_HORA}\n\n" +
                            "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                            "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" presentando, en la última " +
                            "dosis la siguiente reacción:\n\n{LISTADO_DE_SINTOMAS}\n" +
                            "{MEDICACION} y se ha dado de alta en condiciones estables. Se le ha " +
                            "indicado el consumo en su domicilio de la dosis de {DOSIS_PRESCRITA_DOMICILIO}.";
                    } else {
                        informe = "{FECHA_HORA}\n\n" +
                            "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                            "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" presentando, en la última " +
                            "dosis la siguiente reacción:\n\n{LISTADO_DE_SINTOMAS}\n" +
                            "{MEDICACION} y se ha dado de alta en condiciones estables. " +
                            "No se le ha prescrito ninguna dosis.";
                    }
                } else {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                        "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" presentando, en la última " +
                        "dosis la siguiente reacción: \n\n{LISTADO_DE_SINTOMAS}\n" +
                        "{MEDICACION} y se ha dado de alta en condiciones estables. Se le ha indicado acudir mañana a continuar ascenso de dosis.";
                }
            } else {
                if (lastDay) {
                    if (lastExtraHospitalaryDose != undefined) {
                        informe = "{FECHA_HORA}\n\n" +
                            "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                            "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" sin presentar reacción. Se " +
                            "le ha indicado el consumo en su domicilio de la dosis de {DOSIS_PRESCRITA_DOMICILIO}.";
                    } else {
                        informe = "{FECHA_HORA}\n\n" +
                            "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                            "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" sin presentar reacción. " +
                            "No se le ha prescrito ninguna dosis.";
                    }
                } else {
                    informe = "{FECHA_HORA}\n\n" +
                        "El paciente {NOMBRE_APELLIDOS} a día {FECHA} ha acudido a nuestro centro y se ha alcanzado la " +
                        "dosis máxima de {DOSIS} de {ALERGENO} {DILUCION}, bajo el protocolo \"{PROTOCOLO}\" sin presentar reacción. Se " +
                        "le ha indicado acudir mañana a continuar ascenso de dosis.";
                }
            }
        }

        return informe;
    }

}

intakeControlController.$inject = [
    "$state",
    USERS_SERVICE,
    TOKEN_SERVICE,
    ERROR_MODAL_SERVICE,
    INTAKE_MODAL_SERVICE,
    INTAKE_CONTROL_SERVICE,
    UTILS_DATE_SERVICE,
];

export default intakeControlController;
