class Protocol {
    constructor(allergen = "", type = "", observationTime = "", doseUnit = "", volumeUnit = "") {
        this.allergen = allergen;
        this.type = type;
        this.observationTime = observationTime;
        this.weeks = [];
        this.control = [];
        this.selectedDoseUnit = doseUnit;
        this.selectedVolumeUnit = volumeUnit;
        this.intra = {
            days: []
        }
    }

    setIntra(intra) {
        this.intra = intra;
    }

    removeIntra() {
        delete this.intra;
    }

    removeWeeks() {
        delete this.weeks;
    }

    removeWeek(index){
        this.weeks.splice(index, 1);
    }

    cleanIntra() {
        this.cleanEmptyIntraIntakes();
        this.cleanEmptyIntraDays();
    }

    cleanEmptyIntraIntakes() {
        this.intra.days.forEach(day => {
            day.intakes = day.intakes.filter(intake => intake.dose !== "" || intake.observationTime != "");
        });
    }

    cleanEmptyIntraDays() {
        this.intra.days = this.intra.days.filter(day => day.intakes.length > 0);
    }

    reIndexIntraDays() {
        this.intra.days.forEach((day, index) => {
            day.day = index + 1;
        });
    }

    cleanEmptyWeeks() {
        this.weeks = this.weeks.filter(intake => intake.dose !== "" && intake.observationTime != "");
    }

    reIndexWeeks(){
        this.weeks.forEach((week, index) => {
            week.weekNumber = index + 1;
        });
    }

    removeWeeksControls(){
        delete this.control;
        this.weeks.forEach((week) => {
            delete week.doseBorder;
            delete week.volumeBorder;
            week.dose = week.dose + " " + this.selectedUnit;
        });

        delete this.selectedDoseUnit;
        delete this.selectedVolumeUnit;
        this.observationTime = this.observationTime * 60000;
    }

    removeIntraControls(){
        let intra = this.intra;

        delete intra.control;
        if (intra.days && intra.days.length > 0) {
            intra.days.forEach((day) => {
                delete day.rowSpan;

                day.intakes.forEach((intake) => {
                    delete intake.doseBorder;
                    delete intake.volumeBorder;
                    delete intake.obsBorder;
                    delete intake.show;

                    intake.dose = intake.dose + " " + this.selectedUnit;
                    intake.observationTime = intake.observationTime + " minutos";
                });
            });

            delete intra.selectedDoseUnit;
            delete intra.selectedVolumeUnit;
            delete intra.selectedTimeUnit;
            //delete this.selectedUnit;
        } else {
            delete this.intra;
        }
    }
}

export default Protocol;