import adminMedicalTemplate from "./admin-medical.template";

var adminMedicalStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-medical",
        url: "/personal-medico",
        template: adminMedicalTemplate,
    });
};

adminMedicalStates.$inject = ["$stateProvider"];

export default adminMedicalStates;
