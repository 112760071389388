import angular from "angular";
import "angular-ui-router";

import { EDIT_INTRA_PHASE_COMPONENT, editIntraPhaseComponent } from "./edit-intra-phase.component";
import { API_CLIENT } from "services/api-client";
import { ERROR_MODAL_MODULE } from "services/modal/error";
import { UTILS_MODULE } from "services/utils";

const EDIT_INTRA_PHASE_MODULE = "editIntraPhaseModule";

angular.module(EDIT_INTRA_PHASE_MODULE, [
    "ui.router",
    API_CLIENT,
    UTILS_MODULE,
    ERROR_MODAL_MODULE
]).component(EDIT_INTRA_PHASE_COMPONENT, editIntraPhaseComponent);

export default EDIT_INTRA_PHASE_MODULE;
