const APPOINTMENTS_SERVICE = "appointmentsService";

var AppointmentsService = function ($http, __env) {
    const apiUrl = `${__env.apiUrl}/appointments`;

    const appointmentsService = {};

    appointmentsService.create = function (appointment, callback) {
        $http.post(apiUrl, appointment).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.update = function (dateObj, callback) {
        $http.put(apiUrl, dateObj).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.delete = function (dateID, callback) {
        $http.delete(`${apiUrl}/${dateID}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.get = function (patientId, callback) {
        $http.get(`${apiUrl}/users/${patientId}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.getNextAppointment = function (patientId, date, callback) {
        $http.get(`${apiUrl}/users/${patientId}`, {
            params: { startDate: date }
        }).then(
            (result) => callback(null, result.data.data),
            (err) => callback(err)
        );
    };

    appointmentsService.getByDoctor = function (doctorId, callback) {
        $http.get(`${apiUrl}/doctors/${doctorId}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };
    appointmentsService.getByCenter = function (centerId, callback) {
        $http.get(`${apiUrl}/centers/${centerId}`).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.getDoctorDates = function (doctorId, start, end, callback) {
        $http.get(`${apiUrl}/doctors/${doctorId}`, { params: { startDate: start, endDate: end } }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    appointmentsService.getCenterDates = function (centerId, start, end, callback) {
        $http.get(`${apiUrl}/centers/${centerId}`, { params: { startDate: start, endDate: end } }).then(
            (result) => callback(null, result.data),
            (err) => callback(err)
        );
    };

    return appointmentsService;
};

AppointmentsService.$inject = ["$http", "__env"];

export { APPOINTMENTS_SERVICE, AppointmentsService };
