var protocolsStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-medical.protocols",
        url: "/protocolos",
        template: "<protocols-component></protocols-component>",
    });
};

protocolsStates.$inject = ["$stateProvider"];

export default protocolsStates;
