import "angular-ui-bootstrap";
import Protocol from "models/Protocol";

import { UTILS_PROTOCOL_SERVICE } from "services/utils";
import { PROTOCOL_EDITOR_SERVICE } from "services/protocols";

class protocolsIntraEditorController {

    constructor(protocolUtils, protocolEditor) {
        this.protocolUtils = protocolUtils;
        this.protocolEditor = protocolEditor;

        this.newIntraProtocol = {
            days: [],
        };
        this.newProtocol = new Protocol();

        this.show = false;
        this.reg = /^\d+(|(\.\d*))$/;
    }

    newDay(dayNumber) {
        var day = {
            day: dayNumber,
            intakes: [],
        };

        return day;
    }

    newIntake(dose, volume, obsTime) {
        var intake = {
            dose: dose,
            volume: volume,
            observationTime: obsTime,
            obsBorder: { "border-style": "none" },
            show: false,
        };

        return intake;
    }

    editProtocol() {
        this.onEdit = !this.onEdit;
        this.botonCrearProtocolo.active = !this.botonCrearProtocolo.active;
        this.protocol = null;
    }

    validateReg(dayIndex, index) {
        const intake = this.newIntraProtocol.days[dayIndex].intakes[index];
        const row = this.newIntraProtocol.control[dayIndex].intakes[index];

        if (this.protocolEditor.validateDose(intake.dose, this.newProtocol.selectedUnit)) {
            this.updateControl(row.dose, false, true, false);
        } else {
            this.updateControl(row.dose, true, false, false);
        }

        if (this.protocolUtils.validateVolume(intake.volume)) {
            this.updateControl(row.volume, false, true, false);
        } else {
            if (intake.volume.length === 0) {
                this.updateControl(row.volume, false, false, true);
            } else {
                this.updateControl(row.volume, true, false, false);
            }
        }

        if (this.reg.test(intake.observationTime)) {
            this.updateControl(row.obs, false, true, false);
        } else {
            this.updateControl(row.obs, true, false, false);
        }

        if (row.dose["has-success"] & (row.volume["has-success"] || row.volume["none"]) & row.obs["has-success"]) {
            this.updateControl(row.icon, false, true, false);
        }
        else {
            this.updateControl(row.icon, true, false, false);
        }
    }

    validateAndCleanVolume(dayIndex, index) {
        const intake = this.newIntraProtocol.days[dayIndex].intakes[index];
        const row = this.newIntraProtocol.control[dayIndex].intakes[index];

        if (this.protocolUtils.validateVolume(intake.volume)) {
            const volume = this.protocolUtils.cleanVolumeValue(intake.volume);
            intake.volume = volume != null ? volume : "-";
            this.updateControl(row.volume, false, true, false);
        } else {
            if (intake.volume.length === 0) {
                this.updateControl(row.volume, false, false, true);
            } else {
                this.updateControl(row.volume, true, false, false);
            }
        }
    }

    createControl(err, succ, feed, none) {
        let dose = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        let icon = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        let volume = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };
        let obs = { "has-error": err, "has-success": succ, "none": none, "has-feedback": feed };

        return { dose, icon, volume, obs };
    }

    updateControl(node, err, succ, none) {
        node["has-error"] = err;
        node["has-success"] = succ;
        node["none"] = none;
    }

    addDay() {
        this.newIntraProtocol.days.push(this.newDay(this.newIntraProtocol.days.length + 1));
        if (!this.newIntraProtocol.control)
            this.newIntraProtocol.control = [];

        this.newIntraProtocol.control.push({ show: false, intakes: [] });
        this.addIntake(this.newIntraProtocol.days.length - 1, 0);
    }

    addIntake(dayIndex) {
        if (!this.newIntraProtocol.days[dayIndex].intakes)
            this.newIntraProtocol.days[dayIndex].intakes = [];

        this.newIntraProtocol.days[dayIndex].rowSpan = this.newIntraProtocol.days[dayIndex].intakes.length;
        this.newIntraProtocol.days[dayIndex].intakes.push(this.newIntake("", "", ""));
        this.newIntraProtocol.control[dayIndex].intakes.push(this.createControl(false, false, true, true));
    }

    remove(dayIndex, index) {
        if (this.newIntraProtocol.days[dayIndex].intakes.length == 1) {
            this.newIntraProtocol.days[dayIndex].intakes.splice(index, 1);
            this.newIntraProtocol.control[dayIndex].intakes.splice(index, 1);
            this.newIntraProtocol.days.splice(dayIndex, 1);
            this.newIntraProtocol.control.splice(dayIndex, 1);
        } else {
            this.newIntraProtocol.days[dayIndex].intakes.splice(index, 1);
            this.newIntraProtocol.control[dayIndex].intakes.splice(index, 1);
        }
    }

    inicializeControl(protocol) {
        if (protocol) {
            this.newIntraProtocol.control = [];
            protocol.weeks.forEach(this.addRow);
        }
    }

    addRow(week, index) {
        this.newIntraProtocol.weeks.push({ weekNumber: this.newIntraProtocol.weeks.length + 1, dose: week.dose, volume: week.volume });
        this.newIntraProtocol.control[index].intakes = [];
        this.newIntraProtocol.control[index].intakes.push(this.createControl(false, false, true, true));

        this.validateReg(index);
    }

}

protocolsIntraEditorController.$inject = [UTILS_PROTOCOL_SERVICE, PROTOCOL_EDITOR_SERVICE];

export default protocolsIntraEditorController;
