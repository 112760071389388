import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { HOSPITALARY_DOSE_COMPONENT, hospitalaryDoseComponent } from "./hospitalary-dose.component";
import { HOSPITALARY_DOSE_SERVICE, hospitalaryDoseService } from "./hospitalary-dose.service";
import { PROTOCOL_MODAL_MODULE } from "services/modal/protocol";

const HOSPITALARY_DOSE_MODULE = "hospitalaryDoseModule";

angular.module(HOSPITALARY_DOSE_MODULE, [
    "ui.router",
    API_CLIENT,
    PROTOCOL_MODAL_MODULE,
])
    .component(HOSPITALARY_DOSE_COMPONENT, hospitalaryDoseComponent)
    .factory(HOSPITALARY_DOSE_SERVICE, hospitalaryDoseService);

export default HOSPITALARY_DOSE_MODULE;
