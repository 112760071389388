import { UTILS_DATE_SERVICE } from "./utils.dates";
import { UTILS_NUMBERS_SERVICE } from "services/utils";

const UTILS_TREATMENT_SERVICE = "utilsTreatmentService";

const UtilsTreatmentService = function (dateUtils, numberUtils) {

    var utilsTreatmentService = {};

    utilsTreatmentService.getCurrentTreatment = function (treatments) {
        treatments.sort((a, b) => a.starts - b.starts);
        return treatments.find(treatment => treatment.active === true);
    };

    utilsTreatmentService.getTreatmentCurrentDay = function (treatment) {
        if (treatment.intra) {
            const currentIntraDay = utilsTreatmentService.getWeekCurrentDay(treatment.intra);
            if (currentIntraDay) {
                return currentIntraDay;
            }
        }

        for (let i = 0; i < treatment.weeks.length; i++) {
            const currentDay = utilsTreatmentService.getWeekCurrentDay(treatment.weeks[i]);
            if (currentDay) {
                return currentDay;
            }
        }
    };

    utilsTreatmentService.getWeekCurrentDay = function (week) {
        return week.days.find(day => dateUtils.isToday(day.date));
    };

    utilsTreatmentService.getTreatmentCurrentDose = function (
        currentTreatment,
        doseType
    ) {
        if (!currentTreatment || !currentTreatment.doctorDoses) {
            return undefined;
        }

        const lastDoctorDoses = currentTreatment.doctorDoses
            .filter(d => d.doseType == doseType)
            .sort((a, b) => b.date - a.date); // Reverse order
        return lastDoctorDoses.length > 0
            ? utilsTreatmentService.parseDoseValue(lastDoctorDoses[0].dose)
            : undefined;
    };

    utilsTreatmentService.getTreatmentUnit = function (treatment) {
        if (treatment.selectedUnit) {
            return treatment.selectedUnit;
        }

        if (treatment.weeks && treatment.weeks.length && treatment.weeks[0].dose) {
            return treatment.weeks[0].dose.split(" ")[1];
        }

        if (treatment.intra && treatment.intra.days && treatment.intra.days.length) {
            const intraDay = treatment.intra.days[0];
            if (intraDay.intakes && intraDay.intakes.length && intraDay.intakes[0].dose) {
                return intraDay.intakes[0].dose.split(" ")[1];
            }
        }
    };

    utilsTreatmentService.hasDosesTaken = function (treatment) {
        if (!treatment.intra && !treatment.weeks) {
            return false;
        }

        if (utilsTreatmentService.weekHasAnyTakenIntake(treatment.intra)) {
            return true;
        }

        return undefined != treatment.weeks
            .find(utilsTreatmentService.weekHasAnyTakenIntake);
    };

    utilsTreatmentService.weekHasAnyDischargedDay = function (week) {
        if (!week.days) {
            return false;
        }

        return undefined != week.days
            .find(utilsTreatmentService.dayIsDischarged);
    };

    utilsTreatmentService.weekHasAnyTakenIntake = function (week) {
        if (!week || !week.days) {
            return false;
        }

        return undefined != week.days
            .find(utilsTreatmentService.dayHasAnyTakenIntake);
    };

    utilsTreatmentService.dayIsDischarged = function (day) {
        return day && day.discharged == 1;
    };

    utilsTreatmentService.dayHasAnyTakenIntake = function (day) {
        if (!day.intakes) {
            return false;
        }

        return undefined != day.intakes
            .find(utilsTreatmentService.intakeIsTaken);
    };

    utilsTreatmentService.intakeIsTaken = function (intake) {
        return intake && intake.state == "taken";
    };

    /**
     * Extract the numeric value of a given dose in format "value + unit".
     *
     * Examples:
     *  4000 mg -> 4000
     *  0.3 ml -> 0.3
     */
    utilsTreatmentService.parseDoseValue = function (doseStr) {
        if (doseStr === undefined) {
            return doseStr;
        }

        let dose = doseStr;

        if (typeof dose === "string" && isNaN(dose)) {
            // Get the "value" part of the string
            dose = dose.split(" ")[0];
        }

        if (isNaN(dose)) {
            return doseStr.split(" ")[0];
        }

        return numberUtils.fixToRealPrecission(dose, 4);
    };

    utilsTreatmentService.treatmentHasStarted = function (treatment) {
        return treatment.starts <= dateUtils.getCurrentTs();
    };

    utilsTreatmentService.treatmentHasEnd = function (treatment) {
        return treatment.ends != undefined
            && treatment.ends != null
            && treatment.ends != "";
    };

    return utilsTreatmentService;
};

UtilsTreatmentService.$inject = [
    UTILS_DATE_SERVICE,
    UTILS_NUMBERS_SERVICE
];

export { UTILS_TREATMENT_SERVICE, UtilsTreatmentService };
