import angular from "angular";
import reactions from "./reactions";

const REACTION_ALGORITHM_SERVICE = "reactionAlgorithmService";

function ReactionAlgorithmService() {

    const reactionAlgorithmService = {};

    const ignorableReactionLevels = ["Banal", "Leve"];

    reactionAlgorithmService.getReaction = function (symptoms, treatments) {

        if (!symptoms.length) return;

        // Si sólo un sintoma
        if (symptoms.length == 1) {
            return handleStates(reactions[symptoms[0]]);
        }

        // Quitamos el 1a y el 1b
        let no1a1b = symptoms.filter(e => e != "1a" && e != "1b");

        // Si sintoma 2 y 3
        if (no1a1b.length == 2 && no1a1b.find(e => e == "2") && no1a1b.find(e => e == "3")) {
            return handleStates(reactions["2+3"]);
        }

        // Si los distintos de 1a y 1b son 2 o más
        if (no1a1b.length > 1) {
            // TODO return sintomas de cada uno
            return handleStates(reactions["2o+!=1"]);
        }

        // Si hay 1 distinto de 1a o 1b
        if (no1a1b.length == 1) {
            return handleStates(reactions[no1a1b[0]]);
        }

        // Si 1b entonces 1b, si no 1a
        return handleStates(reactions[symptoms.find(e => e == "1b") ? "1b" : "1a"]);

        function handleStates(state) {
            let auxState = angular.merge({}, state);

            if (auxState.type == "Banal") return auxState;

            auxState.action = auxState.action.map(elem => {
                let aux = angular.merge({}, treatments[elem]);
                let doseSplit = aux.dose.split(" ");
                aux.unit = doseSplit.pop();
                aux.dose = doseSplit.join(" ");
                return aux;
            });

            if (auxState.medicDescription == "Anafilaxia") {
                symptoms.forEach(symptom => {
                    auxState.description += reactions[symptom].description + ". ";
                    auxState.patientDescription += reactions[symptom].patientDescription + ". ";
                });
            }

            return auxState;
        }
    };

    reactionAlgorithmService.getSymptomsNames = function() {
        const symptomsNames = [];

        for (let key in reactions) {
            if (key == "2+3" || key == "4a+4b") {
                continue;
            }

            symptomsNames.push({
                id: reactions[key].medicDescription,
                name: reactions[key].equivalent
                    ? reactions[key].equivalent
                    : reactions[key].medicDescription,
            });
        }

        return symptomsNames;
    };

    /**
     * Return the medicDescriptions of the given symptoms.
     */
    reactionAlgorithmService.symptomsDescriptionToIds = function (symptomsDescriptions) {
        if (!symptomsDescriptions) {
            return [];
        }

        const symptomsIds = [];

        for (let key in reactions) {
            if (symptomsDescriptions.includes(reactions[key].medicDescription)
                || symptomsDescriptions.includes(reactions[key].equivalent)) {
                if (key == "2+3" || key == "4a+4b") {
                    continue;
                }

                symptomsIds.push(reactions[key].medicDescription);
            }
        };

        return symptomsIds;
    };

    /**
     * Return the equivalent names of the given symptoms' medicDescriptions.
     */
    reactionAlgorithmService.symptomsIdsToDescriptions = function (symptomsIds) {
        if (!symptomsIds) {
            return [];
        }

        const symptomsDescriptions = [];

        for (let key in reactions) {
            if (symptomsIds.includes(reactions[key].medicDescription)) {
                if (key == "2+3" || key == "4a+4b") {
                    continue;
                }

                const description = reactions[key].equivalent
                    ? reactions[key].equivalent
                    : reactions[key].medicDescription;

                symptomsDescriptions.push(description);
            }
        };

        return symptomsDescriptions;
    };

    reactionAlgorithmService.getSymtomDetail = function (id) {
        return reactions[id];
    };

    reactionAlgorithmService.intakeHasNoIgnorableReactions = function (intake) {
        if (!intake.reactions || !intake.reactions.length) {
            return false;
        }

        for (let i = 0; i < intake.reactions.length; i++) {
            if (!reactionIsIgnorable(intake.reactions[i])) {
                return true;
            }
        }

        return false;
    };

    reactionAlgorithmService.getReaction = function (reactionName) {
        for (let state in reactions) {
            const reaction = reactions[state];
            if (reaction.medicDescription == reactionName ||
                reaction.equivalent == reactionName) {
                return reaction;
            }
        }
    };

    function reactionIsIgnorable(reactionName) {
        const reaction = reactionAlgorithmService.getReaction(reactionName);

        return reaction && ignorableReactionLevels.includes(reaction.type);
    }

    return reactionAlgorithmService;
}

ReactionAlgorithmService.$inject = [];

export { REACTION_ALGORITHM_SERVICE, ReactionAlgorithmService };
