import signInTemplate from "./signin.template";
import signInController from "./signin.controller";

var signInStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.signin",
        url: "/signin?id",
        template: signInTemplate,
        controller: signInController,
    });
};

signInStates.$inject = ["$stateProvider"];

export default signInStates;
