import angular from "angular";
import "angular-ui-bootstrap";
import moment from "moment";

import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { USERS_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";
import { REACTION_ALGORITHM_SERVICE } from "services/reaction-algorithm";
import { UTILS_DATE_SERVICE, UTILS_TREATMENT_SERVICE } from "services/utils";

import ComparativeEmptyDay from "models/comparative/ComparativeEmptyDay";
import ComparativeIntake from "models/comparative/ComparativeIntake";
import ComparativeWeek from "models/comparative/ComparativeWeek";
import DayToEdit from "models/comparative/DayToEdit";
import DoseSeparator from "models/comparative/DoseSeparator";
import WeekSeparator from "models/comparative/WeekSeparator";

const ONE_HOUR_IN_MILLIS = 60 * 60 * 1000;
const ONE_DAY_TIME = ONE_HOUR_IN_MILLIS * 24;
const ONE_WEEK_TIME = ONE_DAY_TIME * 7;

class comparativeController {
    constructor(
        $window,
        errorModal,
        userService,
        tokenService,
        reactionsService,
        dateUtils,
        treatmentsUtils
    ) {
        this.patient;
        this.token = tokenService.get();
        this.role = this.token.role;
        this.detail = {};
        this.hasIntra = false;
        this.all = false;
        this.window = $window;
        this.userService = userService;
        this.errorModal = errorModal;
        this.reactionsService = reactionsService;
        this.dateUtils = dateUtils;
        this.treatmentUtils = treatmentsUtils;
    }

    $onChanges(changes) {
        this.loadInitValues();

        if (changes.patient) {
            this.loadInitValues();
        }
    }

    updateReferences(week) {
        if (this.currentWeekNumber < 0) {
            this.end = this.intraPhase.days[this.intraPhase.days.length - 1].date + this.dateUtils.getTimezoneCorrector();
            this.init = moment(this.end).utc().subtract(6, "days").valueOf();
            this.prevWeek = undefined;
            this.nextWeek = this.selectedTreatment.weeks[0];
        } else {
            this.currentWeekNumber = week && week.weekNumber ? week.weekNumber : 0;

            this.prevWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == this.currentWeekNumber - 1);
            this.nextWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == this.currentWeekNumber + 1);

            if (this.currentWeek) {
                this.init = this.currentWeek.date + this.dateUtils.getTimezoneCorrector();
                this.end = moment(this.init).utc().add(6, "days").valueOf();
            }
        }
    }

    loadWeekDetail(weekNumber) {
        this.detail[weekNumber] = !this.detail[weekNumber];
        this.curWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == weekNumber);

        if (!this.detail[weekNumber]) {
            delete this.curWeek;
        }
    }

    previousWeek() {
        if (this.hasPreviousWeek()) {
            if (this.currentWeekNumber == 0 && this.hasIntra) {
                this.currentWeek = this.intraPhase;
                this.currentWeekNumber = -1;
            } else {
                this.currentWeek = this.prevWeek;
                this.currentWeekNumber = this.currentWeek && this.currentWeek.weekNumber ? this.currentWeek.weekNumber : 0;
            }

            this.updateReferences(this.currentWeek);
        }
    }

    followingWeek() {
        if (this.hasFollowingWeek()) {
            this.currentWeek = this.nextWeek;

            if (this.currentWeek) {
                this.currentWeekNumber = this.currentWeek.weekNumber;
            }

            this.updateReferences(this.currentWeek);
        }
    }

    hasPreviousWeek() {
        return this.currentWeekNumber > 0 || (this.currentWeekNumber == 0 && this.hasIntra);
    }

    hasFollowingWeek() {
        return this.currentWeekNumber + 1 < this.selectedTreatment.weeks.length;
    }

    multiIntake() {
        this.window.$("#intakeDetail").modal("toggle");
        this.window.$("#multiIntake").modal("toggle");
    }

    showAll() {
        this.all = !this.all;
    }

    loadInitValues() {
        if (this.patient && this.patient.treatments && this.patient.treatments.length > 0) {
            this.patient.treatments.sort((a, b) => a.starts - b.starts);

            if (this.selectedTreatment && this.selectedTreatment.weeks) {
                this.intraPhase = this.generateIntrahospitalary();
                this.hasIntra = this.intraPhase != undefined;

                this.goToWeek(this.currentWeek);

                this.prevWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == this.currentWeekNumber - 1);
                this.nextWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == this.currentWeekNumber + 1);

                this.cofactors = this.userService.getCofactors();
                this.suggesting = this.userService.getSuggestings();
                this.symptoms = this.reactionsService.getSymptomsNames();
            }
        }
    }

    goToWeek(week) {
        const weekDate = week ? week.date : this.dateUtils.getCurrentTs();

        this.selectedTreatment.weeks.sort((a, b) => b.date - a.date);
        if (this.selectedTreatment.starts > weekDate) {
            this.currentWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == 0);
        } else {
            const previousWeeks = this.selectedTreatment.weeks.filter(week => week.date <= weekDate);
            this.currentWeek = previousWeeks[0];
        }

        if (this.hasIntra) {
            this.selectedTreatment.weeks = this.generateDoseTakes(this.selectedTreatment);
        }

        if (!this.currentWeek && this.hasIntra) {
            this.currentWeek = this.intraPhase;
            this.currentWeekNumber = -1;
            this.end = this.intraPhase.days[this.intraPhase.days.length - 1].date + this.dateUtils.getTimezoneCorrector();
            this.init = moment(this.init).utc().subtract(6, "days").valueOf();
        } else {
            this.currentWeekNumber = this.currentWeek && this.currentWeek.weekNumber ? this.currentWeek.weekNumber : 0;
            this.init = this.currentWeek.date + this.dateUtils.getTimezoneCorrector();
            this.end = moment(this.init).utc().add(6, "days").valueOf();
        }

        if (this.currentWeekNumber >= 0) {
            this.currentWeek = this.selectedTreatment.weeks.find(e => e.weekNumber == this.currentWeekNumber);
        }
    }

    compoundCompleteTreatment(treatments) {
        const completeTreatment = {
            weeks: []
        };

        if (treatments[0].weeks) {
            let treatmentStarting = 0;
            let deltaWeek = false;

            for (let i = 0, week = 0; i < treatments.length; i++) {
                const treatment = treatments[i];
                const nextTreatment = treatments[i + 1];
                treatment.weeks = this.generateDoseTakes(treatment);

                const weeks = treatment.weeks.sort((a, b) => a.date - b.date);
                treatmentStarting = nextTreatment ? nextTreatment.starts : treatments.starts;

                let weekIndex = 0;
                while (weekIndex < weeks.length) {
                    treatment.weeks[weekIndex].weekNumber = week;

                    if (treatmentStarting < weeks[weekIndex].date + ONE_WEEK_TIME && nextTreatment) {
                        nextTreatment.weeks = this.generateDoseTakes(nextTreatment);
                        const newWeek = new ComparativeWeek(weeks[weekIndex], weekIndex);

                        let dayIndex = 0;
                        while (dayIndex < weeks[weekIndex].days.length && weeks[weekIndex].days[dayIndex].date < treatmentStarting) {
                            newWeek.addDay(weeks[weekIndex].days[dayIndex]);
                            dayIndex++;
                        }

                        if (newWeek.day && newWeek.day.length > 0) {
                            newWeek.addDay(new WeekSeparator(true, treatment.type, nextTreatment.type));
                        }

                        let newDayIndex = dayIndex;
                        while (newDayIndex < 7) {
                            newWeek.addDay(nextTreatment.weeks[weekIndex].days[newDayIndex]);
                            newDayIndex++;
                        }

                        newWeek.setDose(new DoseSeparator(newWeek.dose, nextTreatment.weeks[weekIndex].dose));

                        deltaWeek = true;
                        completeTreatment.weeks.push(newWeek);
                        break;
                    } else {
                        if (deltaWeek) {
                            weekIndex++;
                            deltaWeek = false;
                        }
                        const weeky = treatment.weeks[weekIndex];
                        weeky.weekNumber = completeTreatment.weeks.length;
                        weeky.doctorDose = weeky.doctorDoses && weeky.doctorDoses.length > 0 ? weeky.doctorDoses[weeky.doctorDoses.length - 1].dose : " - ";
                        completeTreatment.weeks.push(weeky);
                    }
                    weekIndex++;
                    week++;
                }
            }
        }

        return completeTreatment;
    }

    generateIntrahospitalary() {
        if (!this.selectedTreatment || !this.selectedTreatment.intra) {
            return undefined;
        }

        const intraPhase = {
            days: [],
            lastIntakeOfDay: [],
            weekNumber: -1
        };

        const days = angular.copy(this.selectedTreatment.intra.days);
        days.sort((a, b) => a.date - b.date);

        for (let i = 0; i < days.length; i++) {
            if (days[i].intakes && days[i].intakes.length > 0) {
                intraPhase.days.push(days[i]);
            } else {
                // TODO: This should be unnecessary after dates correction script
                // is executed in API
                const correctedDate = moment(days[i].date)
                    .utc()
                    .add(2, "hour")
                    .startOf("day")
                    .valueOf();
                const newIntake = new ComparativeIntake(
                    this.selectedTreatment.type,
                    undefined,
                    "notTaken",
                    "",
                    days[i].dose,
                    days[i],
                    "No tomada",
                    false);

                intraPhase.days.push({
                    day: days[i].day,
                    date: correctedDate,
                    intakes: [newIntake]
                });
            }
        }

        // Generate an array with last intake of each intra day
        for (let i = 0; i < intraPhase.days.length; i++) {
            const dayNumber = intraPhase.days[i].day;
            const intakes = intraPhase.days[i].intakes
                .filter(intake => intake.state == "taken");

            intraPhase.lastIntakeOfDay[dayNumber] = intakes.pop();
        }

        // If the adaptation week has less than 7 days, fill the remaining gaps
        if (intraPhase.days.length > 0) {
            this.end = intraPhase.days[intraPhase.days.length - 1].date + this.dateUtils.getTimezoneCorrector();
            this.init = moment(this.end).utc().subtract(1, "week").valueOf();

            for (let i = intraPhase.days.length; i < 7; i++) {
                const emptyDayDate = moment(this.end)
                    .utc()
                    .subtract(i, "week")
                    .startOf("day")
                    .valueOf();
                const emptyDay = new ComparativeEmptyDay(emptyDayDate);

                intraPhase.days.splice(0, 0, emptyDay);
            }
        }

        return intraPhase;
    }

    generateDoseTakes(treatment) {
        if (treatment.weeks == undefined) {
            return undefined;
        }

        const weeks = [];

        for (let i = 0; i < treatment.weeks.length; i++) {
            const week = treatment.weeks.find(e => e.weekNumber == i);
            week.days = week.days ? week.days.sort((a, b) => b.date - a.date) : [];

            for (let j = 0; j < 7; j++) {
                const correctedWeekDayDate = moment(week.date)
                    .utc()
                    .add(j, "day")
                    .add(2, "hour")
                    .startOf("day")
                    .valueOf();
                const existingDay = week.days.find(
                    day => this.dateUtils.isStartOfTheSameDay(day.date, correctedWeekDayDate));

                if (!existingDay) { // existe dia => existe toma
                    const doctorDose = (treatment.doctorDoses)
                        ? treatment.doctorDoses
                            .sort((a, b) => b.date - a.date)
                            .find(dose => dose.date <= (correctedWeekDayDate + ONE_DAY_TIME - 1))
                        : undefined;
                    const newIntake = new ComparativeIntake(
                        treatment.type,
                        correctedWeekDayDate,
                        "notTaken",
                        "",
                        week.dose,
                        doctorDose,
                        "No especificada",
                        true);

                    week.days.push({
                        date: correctedWeekDayDate,
                        intakes: [newIntake]
                    });
                }
            }

            week.days = week.days.sort((a, b) => a.date - b.date);
            if (week.doctorDoses && week.doctorDoses.length > 0) {
                week.currentWeekDose = week.doctorDoses
                    .filter(e => (e.date))
                    .sort((a, b) => b.date - a.date)[0];
                week.currentWeekDose = week.currentWeekDose.dose;
            }
            weeks.push(week);
        }

        return weeks;
    }

    getReasonNameById(reasonId) {
        const reasons = this.userService.getReasons();

        for (let i = 0; i < reasons.length; i++) {
            const reason = reasons[i];
            if (reason.id === reasonId) {
                return reason.name;
            }
        }
    };

    dayCanBeEdited(dayToEdit) {
        return !this.treatmentUtils.treatmentHasEnd(this.selectedTreatment)
            && !(dayToEdit.date > this.dateUtils.getCurrentTs() || !dayToEdit.intakes);
    }

    editDayIntakes(dayToEdit) {
        if (!this.dayCanBeEdited(dayToEdit)) {
            return;
        }

        const isIntraDay = this.isIntrahospitalaryWeek(this.currentWeek);
        this.dayToEdit = new DayToEdit(dayToEdit, isIntraDay);

        if (this.isMultiIntake(dayToEdit)) {
            this.window.$("#multiIntake").modal("toggle");
        } else {
            this.intake = dayToEdit.intakes[0];
            this.window.$("#intakeDetail").modal("toggle");
        }
    }

    isMultiIntake(day) {
        return day.intakes && day.intakes.length > 1;
    }

    getWeekDayStyle(day) {
        if (day.intakes == undefined) {
            return { "background-color": "#F3F3F3" };
        }

        return this.dayHasReactions(day) || this.lastIntakeOfDayHasReactions(day)
            ? { "background-color": "rgba(255, 0, 0, 0.1)" }
            : "";
    }

    dayHasReactions(day) {
        const lastIntake = this.getLastIntake(day);

        return lastIntake && lastIntake.reactions && lastIntake.reactions.length > 0;
    }

    lastIntakeOfDayHasReactions(day) {
        if (!this.currentWeek.lastIntakeOfDay || !this.currentWeek.lastIntakeOfDay.length) {
            return false;
        }

        const lastIntake = this.currentWeek.lastIntakeOfDay[day.day];

        return lastIntake
            && lastIntake.reactions
            && lastIntake.reactions.length > 0;
    }

    getLastIntake(day) {
        if (day && day.intakes && day.intakes.length) {
            return day.intakes[day.intakes.length - 1];
        }
    }

    isExtrahospitalary(day) {
        const lastIntake = this.getLastIntake(day);

        return lastIntake == undefined
            //|| lastIntake.state == "notTaken"
            || lastIntake.isExtrahospitalary
            || lastIntake.intakeType == "extrahospitalary";
    }

    isIntrahospitalaryWeek(week) {
        return week.weekNumber == -1;
    }

    enterEditMode(intake) {
        this.intake = intake;
        this.window.$("#intakeDetail").modal("toggle");
    }

    patientRefreshNeeded() {
        this.onPatientRefreshNeeded();

        if (this.isIntrahospitalaryWeek(this.currentWeek)) {
            this.window.$("#multiIntake").modal("toggle");
        }
    }

}

comparativeController.$inject = [
    "$window",
    ERROR_MODAL_SERVICE,
    USERS_SERVICE,
    TOKEN_SERVICE,
    REACTION_ALGORITHM_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default comparativeController;
