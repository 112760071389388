import angular from "angular";
import { TOKEN_SERVICE } from "services/token";
import { AUTH_SERVICE, USERS_SERVICE, ALERT_SERVICE, INTAKE_CONTROL_SERVICE, CLINICAL_TRIAL_SERVICE } from "services/api-client";
import { ERROR_MODAL_SERVICE } from "services/modal/error";
import { PASSWORD_CHANGE_MODAL_SERVICE } from "services/modal/password-change";

class webHeaderController {

    constructor(
        $rootScope,
        $state,
        $timeout,
        $interval,
        tokenService,
        authService,
        userService,
        alertService,
        intakeControlService,
        passwordChangeModal,
        errorModal,
        __env,
        clinicalTrialService,
    ) {
        var timer;
        this.alerts = [];
        this.automaticAlerts = [];
        this.pendingDischargeAlerts = [];
        this.campo = {};
        this.mainState;
        this.userLogged;
        this.token;
        this.role;
        this.searchPatient = {
            show: false
        };

        this.intakeControlService = intakeControlService;
        this.clinicalTrialService = clinicalTrialService;

        this.online = {
            text: "Offline",
            state: false
        };

        var stop;
        var stopLine;

        this.changePassword = function () {
            passwordChangeModal.show((success, newPassword) => {
                if (success) {
                    userService.generatePassword(this.token.userId, newPassword, (err) => {
                        if (err) {
                            errorModal.show(
                                "Error",
                                "No se ha podido actualizar la contraseña. Por favor, inténtelo más tarde."
                            );
                            return console.error(err);
                        }
                    });
                }
            });
        };

        this.exportClinicalTrialCsv = function () {
            this.clinicalTrialService.exportCsv((err,res) => {
                if(err){
                    const errorMsg = err.data.error ? err.data.error : 'No se ha podido generar la exportación. Por favor, inténtelo de nuevo más tarde.';
                    errorModal.show(
                        "Error",
                        errorMsg
                    );
                    return console.error(err);
                }
                const blob = new Blob([res], { type: 'text/csv' });
                const url= window.URL.createObjectURL(blob);
                var item = document.createElement("a");
                item.href = url;
                item.download = 'pacientes-raiz.csv';
                document.body.appendChild(item);
                item.click();
                document.body.removeChild(item);
            });
        };

        this.exportClinicalTrialReactionsCsv = function () {
            this.clinicalTrialService.exportReactionsCsv((err,res) => {
                console.log(err)
                if(err){
                    errorModal.show(
                        "Error",
                        "No se ha podido generar la exportación. Por favor, inténtelo de nuevo más tarde."
                    );
                    return console.error(err);
                }
                const blob = new Blob([res], { type: 'text/csv' });
                const url= window.URL.createObjectURL(blob);

                var item = document.createElement("a");
                item.href = url;
                item.download = 'reacciones-lista.csv';
                document.body.appendChild(item);
                item.click();
                document.body.removeChild(item);
            });
        };

        this.refresh = function () {
            if (!angular.isDefined(stop)) {
                stop = $interval(this.loadAlerts.bind(this), __env.minuteFreqRefresh * 1000 * 60);
            }

            if (!angular.isDefined(stopLine)) {
                stopLine = $interval(this.verifyline.bind(this), 250);
            }
        };

        this.loadAlerts = function () {
            this.token = tokenService.get();

            if (this.token && this.token.role == "Doctor") {
                alertService.get({ state: "unreviewed" }, (err, res) => {
                    if (err) {
                        this.alerts = [];
                        this.automaticAlerts = [];
                        this.pendingDischargeAlerts = [];
                    }

                    this.alerts = res.data.sort((a, b) => b.createDate - a.createDate);
                    this.automaticAlerts = this.alerts.filter(alert => (alert.type === "automatic" || alert.type ==="daysAlerts"));
                    this.pendingDischargeAlerts = this.alerts.filter(alert => alert.type === "pending_discharge");
                });
            } else {
                this.alerts = [];
                this.automaticAlerts = [];
                this.pendingDischargeAlerts = [];
            }
        };

        this.verifyline = function () {
            if (navigator.onLine) {
                this.online.text = "Online";
                this.online.state = true;
            } else {
                this.online.text = "Offline";
                this.online.state = false;
            }
        };

        this.loadRole = function () {
            this.userLogged = undefined;
            const userToken = tokenService.get();

            if (userToken) {
                this.role = userToken.role;
                userService.get(userToken.userId, (err, res) => {
                    this.userLogged = res && res.data && res.data.fullName || userToken.user;
                });
            } else {
                this.role = undefined;
                this.userLogged = undefined;
            }
        };

        this.loadMainState = function () {
            this.mainState = __env.avaibleStatesPerRole[this.role]
                ? __env.avaibleStatesPerRole[this.role][0]
                : "app.signin";
        };

        this.loadData = function () {
            this.loadAlerts();
            this.verifyline();
            this.refresh();
        };

        this.onStateChangeStart = function () {
            this.loadRole();
            this.loadMainState();
            this.loadData();
        };

        this.onStateChangeStart();
        $rootScope.$on("$stateChangeStart", this.onStateChangeStart.bind(this));

        this.callTimeout = function ($event) {
            $timeout.cancel(timer);
            timer = $timeout(() => this.searchPatients($event), 650);
        };

        this.searchPatients = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            if (!this.campo.campo) {
                this.searchPatient.show = false;
                return;
            }
            userService.searchPatient(this.campo.campo, (err, res) => {
                if (err) {
                    return console.error(err);
                };

                this.foundPatients = res.data || [];
                this.foundPatients = this.foundPatients.sort((a, b) => {
                    return a.fullName.localeCompare(b.fullName);
                });

                this.searchPatient.show = Boolean(this.foundPatients.length);
            });
        };

        this.seePatientDetail = function (patientId) {
            $state.go("app.admin-medical.patient-detail", { id: patientId });
            this.campo.campo = "";
        };

        this.processAlert = function (alert) {
            if (this.token.role == "Doctor") {
                const checked = { _id: alert._id, doctorId: this.token.userId };
                alertService.check(checked, (err) => {
                    if (err) return;
                    $state.go("app.admin-medical.patient-detail", { id: alert.userId, prevState: "alert" });
                });
            }
        };

        this.goToMain = function () {
            $state.go(this.mainState);
            return false;
        };

        this.logOut = function () {
            authService.logOut();
            this.destroyInterval();
            this.intakeControlService.stop();
            delete this.alerts;
            delete this.automaticAlerts;
            delete this.pendingDischargeAlerts;
            $state.go("app.signin");
        };

        this.destroyInterval = function () {
            if (angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
            if (angular.isDefined(stopLine)) {
                $interval.cancel(stopLine);
                stopLine = undefined;
            }
        };

        const self = this;
        $rootScope.$on("alertsRefreshed", function () {
            self.loadAlerts();
        });
    }

}

webHeaderController.$inject = [
    "$rootScope",
    "$state",
    "$timeout",
    "$interval",
    TOKEN_SERVICE,
    AUTH_SERVICE,
    USERS_SERVICE,
    ALERT_SERVICE,
    INTAKE_CONTROL_SERVICE,
    PASSWORD_CHANGE_MODAL_SERVICE,
    ERROR_MODAL_SERVICE,
    "__env",
    CLINICAL_TRIAL_SERVICE
];

export default webHeaderController;
