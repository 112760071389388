import angular from "angular";


import { LOGGER_SERVICE, LoggerService } from "./logger.service";

const LOGGER_MODULE = "loggerModule";

angular.module(LOGGER_MODULE, [])
    .factory(LOGGER_SERVICE, LoggerService);

export { LOGGER_MODULE, LOGGER_SERVICE };
