import activeProtocolTemplate from "./active-protocol.template";
import dobleTemplate from "./doble.template";
import dobleEndInductionTemplate from "./dobleEndInduction.template";
import errorTemplate from "./permission.template";
import infoTemplate from "./info.template";
import medicTemplate from "./medic.template";

const CONFIRMATION_SERVICE = "confirmationService";

var ConfirmationService = function ($uibModal) {
    function doble(callback) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            backdrop: "static",
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
            keyboard: false,
            size: "md",
            template: dobleTemplate,
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };
    function dobleEndInduction(callback) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            backdrop: "static",
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
            keyboard: false,
            size: "md",
            template: dobleEndInductionTemplate,
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    function activeProtocol(callback) {
        let handled = false;

        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: activeProtocolTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    function medic(callback) {
        let handled = false;

        const modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            backdrop: "static",
            keyboard: false,
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true, $scope.data);

                };
                $scope.decline = () => {
                    modal.close();
                    handled = true;
                    callback(false);
                };
            }],
            size: "md",
            template: medicTemplate,
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    function doblePlusMedic(callback) {
        doble(accepted => {
            if (!accepted) return callback(false);

            medic(callback);
        });
    };

    function doblePlusMedicEndInduction(callback) {
        dobleEndInduction(accepted => {
            if (!accepted) return callback(false);

            medic(callback);
        });
    };

    function error(callback) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: errorTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    function info(title, body, callback) {
        let handled = false;

        let modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            template: infoTemplate,
            size: "md",
            controller: ["$scope", function ($scope) {
                $scope.title = title;
                $scope.body = body;
                $scope.accept = () => {
                    modal.close();
                    handled = true;
                    callback(true);
                };
            }],
        });

        modal.result.then(
            () => { if (!handled) callback(false); },
            () => { if (!handled) callback(false); }
        );
    };

    return {
        activeProtocol: activeProtocol,
        doble: doble,
        doblePlusMedic: doblePlusMedic,
        doblePlusMedicEndInduction: doblePlusMedicEndInduction,
        error: error,
        info: info,
        medic: medic,
    };
};

ConfirmationService.$inject = ["$uibModal"];

export { CONFIRMATION_SERVICE, ConfirmationService };
