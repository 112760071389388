export default {
    "1a": {
        description: "Prurito en boca leve",
        patientDescription: "Picor oral suave",
        medicDescription: "SAO leve",
        equivalent: "SAO leve",
        type: "Banal",
        action: "Bebe agua y manténgase tranquilo",
        advise: "Si dura más de 15 min tome 1 dosis de antihistamínico. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "1b": {
        description: "Prurito en boca intenso o hinchazón de labios",
        patientDescription: "Picor o hinchazón oral intenso",
        medicDescription: "SAO",
        equivalent: "SAO relevante",
        type: "Leve",
        action: ["antihistamine"],
        advise: "Si no mejora en 1-2 horas acuda a urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "2": {
        description: "Ronchas o enrojecimiento en la cara",
        patientDescription: "Ronchas en cara",
        medicDescription: "Urticaria facial",
        equivalent: "Urticaria facial",
        type: "Leve",
        action: ["antihistamine"],
        advise: "Si no mejora en 1-2 horas acuda a urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "3": {
        description: "Ronchas o inflamación de otras partes del cuerpo distintas de la cara",
        patientDescription: "Ronchas en el cuerpo",
        medicDescription: "Urticaria generalizada",
        equivalent: "Urticaria generalizada",
        type: "Moderada",
        action: ["antihistamine", "corticoid"],
        advise: "Tras la toma de la medicación debe acudir de forma inmediata a Urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "4a": {
        description: "Dolor abdominal leve",
        patientDescription: "Dolor de tripa suave",
        medicDescription: "GI leve",
        equivalent: "Síntomas gastrointestinales leves",
        type: "Leve",
        action: ["antihistamine"],
        advise: "Si el dolor dura más de 45-60min, administre adrenalina intramuscular y acuda de forma inmediata a urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "4b": {
        description: "Dolor abdominal muy intenso",
        patientDescription: "Dolor de tripa intenso",
        medicDescription: "GI intenso",
        equivalent: "Síntomas gastrointestinales intensos",
        type: "Moderada",
        action: ["adrenalin"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "5": {
        description: "Picor de nariz, ojos, estornudos",
        patientDescription: "Síntomas nasales/oculares",
        medicDescription: "Rinoconjuntivitis",
        equivalent: "Rinoconjuntivitis",
        type: "Moderada",
        action: ["antihistamine"],
        advise: "Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "6": {
        description: "Opresión en la garganta",
        patientDescription: "Nudo en la garganta",
        medicDescription: "AE orofaríngeo",
        equivalent: "Angioedema orofaríngeo",
        type: "Grave",
        action: ["adrenalin"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
    },
    "7": {
        description: "Respiración dificultosa, voz ronca, carraspera",
        patientDescription: "Dificultad para respirar/tos",
        medicDescription: "Asma",
        equivalent: "Síntomas bronquiales",
        type: "Grave",
        action: ["adrenalin", "bronchodilator"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
    },
    "8": {
        description: "Palidez, pérdida de conocimiento, sensación de muerte inminente",
        patientDescription: "Mareo",
        medicDescription: "Shock",
        equivalent: "Síntomas cardiovasculares",
        type: "Grave",
        action: ["adrenalin"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
    },
    "2o+!=1": {
        description: "",
        patientDescription: "",
        medicDescription: "Anafilaxia",
        equivalent: "Anafilaxia",
        type: "Grave",
        action: ["adrenalin"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si no mejora en 5-15 min. administrar de nuevo el segundo dispositivo de adrenalina."
    },
    "2+3": {
        description: "Ronchas o inflamación por todo el cuerpo",
        patientDescription: "Ronchas en el cuerpo",
        medicDescription: "Urticaria generalizada",
        equivalent: "Urticaria generalizada",
        type: "Moderada",
        action: ["antihistamine", "corticoid"],
        advise: "Tras la toma de la medicación debe acudir de forma inmediata a Urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    },
    "4a+4b": {
        description: "Dolor abdominal muy intenso",
        patientDescription: "Dolor de tripa intenso",
        medicDescription: "GI intenso",
        equivalent: "Síntomas gastrointestinales intensos",
        type: "Moderada",
        action: ["adrenalin"],
        advise: "Debe acudir de forma inmediata a Urgencias. Si aparecen otros síntomas debe reconsiderar el tratamiento reiniciando el asistente de reacción."
    }
};
