import angular from "angular";

import {
    PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE,
    PasswordChangeConfirmationModalService
} from "./password-change-confirmation.service";

const PASSWORD_CHANGE_CONFIRMATION_MODAL_MODULE = "passwordChangeConfirmationModalModule";

angular.module(PASSWORD_CHANGE_CONFIRMATION_MODAL_MODULE, [])
    .factory(PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE, PasswordChangeConfirmationModalService);

export { PASSWORD_CHANGE_CONFIRMATION_MODAL_MODULE, PASSWORD_CHANGE_CONFIRMATION_MODAL_SERVICE };
