import angular from "angular";
import "angular-ui-router";

import adminMedicalStates from "./admin-medical.states";

import PATIENT_MODULE from "./patient";
import PROTOCOLS_MODULE from "./protocols";
import INFORMS_MODULE from "./informs";
import CALENDAR_MODULE from "./calendar";
import INTAKE_CONTROL_MODULE from "./intake-control";

const ADMINMEDICAL_MODULE = "adminMedicalModule";

angular.module(ADMINMEDICAL_MODULE, [
    "ui.router",
    CALENDAR_MODULE,
    PROTOCOLS_MODULE,
    PATIENT_MODULE,
    INFORMS_MODULE,
    INTAKE_CONTROL_MODULE,
])
    .config(adminMedicalStates);

export default ADMINMEDICAL_MODULE;
