import intakeControlTemplate from "./intake-control.template";
import intakeControlController from "./intake-control.controller";
import intakeDetailTemplate from "./intake-detail/intake-detail.template";
import intakeDetailController from "./intake-detail/intake-detail.controller";

var intakeControlStates = function($stateProvider) {
    $stateProvider
        .state({
            name: "app.admin-medical.control-tomas",
            url: "/control-tomas",
            template: intakeControlTemplate,
            controller: intakeControlController,
            controllerAs: "$ctrl"
        })
        .state({
            name: "app.admin-medical.control-tomas-detail",
            url: "/control-tomas/detalle/:id",
            template: intakeDetailTemplate,
            controller: intakeDetailController,
            controllerAs: "$ctrl"
        })
        .state({
            name: "app.admin-medical.control-tomas-cancelar",
            url: "/control-tomas/cancelar",
            template: intakeDetailTemplate,
            controller: intakeDetailController,
            controllerAs: "$ctrl"
        });
};

intakeControlStates.$inject = ["$stateProvider"];

export default intakeControlStates;
