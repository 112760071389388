import "angular-chart-lb.js";

import { INFORMS_SERVICE } from "services/api-client";

import configuration from "./config";

class InformPrevisualizationController {

    constructor($scope,$rootScope, informService) {
        this.parent = $scope.$parent;
        this.informService = informService;
        this.rootScope = $rootScope;
        this.grap = {};

        this.groupOptions = configuration.groupOptions;

        this.chartTypes = configuration.chartTypes;

        this.chartOptions = configuration.chartOptions;

        this.refreshChartOptions = this.refreshChartOptions.bind(this);
    }

    $onInit() {
        this.groupOption = this.groupOptions[0];
        this.chartType = "pie";

        this.rootScope.$on('cambioDeFiltroEvent', this.refreshChartOptions);
        
        this.refreshChartOptions();
    }

    changeGroupOptions() {
        this.graphic.groupOption = this.groupOption.id;

        this.informService.getDataGraphics(this.groupOption.id, this.filters, (err, result) => {
            if (err) return console.error(err);

            this.parent.result = result;
            this.parent.datos = [];

            this.graphic.chartData = [];
            this.graphic.chartLabels = [];

            for (let i = 0; i < this.parent.result.length; i++) {
                this.parent.datos.push(this.parent.result[i].count);

                this.graphic.chartData.push(this.parent.result[i].count);
                this.graphic.chartLabels.push(this.parent.result[i]._id);
            }
            
            this.refreshChartOptions();
        });
    }
    
    changeChartOptions(type) {
        this.chartType = type;
        this.refreshChartOptions();
    }

    refreshChartOptions() {
        this.grap.chartOptions = this.chartOptions;

        if (this.chartType == "bar") {
            this.grap.chartOptions.legend.display = false;
            this.grap.chartOptions.scales = {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: Math.max(...this.graphic.chartData) + 1
                    }
                }]
            };
        } else if (this.chartType == "pie") {
            this.grap.chartOptions.scales = undefined;
            this.grap.chartOptions.legend.display = true;
        }
    }
}

InformPrevisualizationController.$inject = ["$scope","$rootScope", INFORMS_SERVICE];

export default InformPrevisualizationController;
