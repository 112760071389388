import angular from "angular";

import { FILTER_COMPONENT, filterComponent } from "./filter.component";

const FILTER_MODULE = "oitcontrolFilterModule";

angular.module(FILTER_MODULE, [

])
.component(FILTER_COMPONENT, filterComponent);

export default FILTER_MODULE;
