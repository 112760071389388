import angular from "angular";
import "angular-ui-router";

import { API_CLIENT } from "services/api-client";
import { INTAKE_MODAL_MODULE } from "services/modal/intake-modal";

import intakeControlStates from "./intake-control.states";

import INTAKE_DETAIL_MODULE from "./intake-detail";

const INTAKE_CONTROL_MODULE = "intakeControlModule";

angular.module(INTAKE_CONTROL_MODULE, [
    "ui.router",
    API_CLIENT,
    INTAKE_MODAL_MODULE,
    INTAKE_DETAIL_MODULE,
])
    .config(intakeControlStates);

export default INTAKE_CONTROL_MODULE;
