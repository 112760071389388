import hospitalaryDoseTemplate from "./hospitalary-dose.template";
import hospitalaryDoseController from "./hospitalary-dose.controller";

const HOSPITALARY_DOSE_COMPONENT = "hospitalaryDose";

const hospitalaryDoseComponent = {
    template: hospitalaryDoseTemplate,
    controller: hospitalaryDoseController,
    bindings: {
        currentTreatment: "<",
        onEnterEditMode: "&",
        onExitEditMode: "&",
        onIntakeEnd: "&",
        onPatientRefreshNeeded: "&",
        patient: "<",
        sectionDisabled: "<",
    }
};

export { HOSPITALARY_DOSE_COMPONENT, hospitalaryDoseComponent };
