import angular from "angular";

import { API_CLIENT } from "services/api-client";

import { PROTOCOLS_INTRA_EDITOR_COMPONENT, protocolsIntraEditorComponent } from "./protocols-intra-editor.component";
import { PROTOCOL_EDITOR_MODULE } from "services/protocols";

const PROTOCOLS_INTRA_EDITOR_MODULE = "protocolsIntraEditorModule";

angular.module(PROTOCOLS_INTRA_EDITOR_MODULE, [
    API_CLIENT,
    PROTOCOL_EDITOR_MODULE
])
.component(PROTOCOLS_INTRA_EDITOR_COMPONENT, protocolsIntraEditorComponent);

export default PROTOCOLS_INTRA_EDITOR_MODULE;

