import "angular-ui-bootstrap";

import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { TOKEN_SERVICE } from "services/token";
import { TREATMENT_SERVICE } from "services/api-client";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

class editIntraPhaseController {

    constructor(
        $window,
        confirmationService,
        tokenService,
        treatmentService,
        treatmentUtils
    ) {
        this.token = tokenService.get();
        this.role = this.token.role;
        this.window = $window;
        this.confirmationService = confirmationService;
        this.treatmentService = treatmentService;
        this.treatmentUtils = treatmentUtils;

        this.intraDays = [];
        this.formIntraDays = {};
        this.visibleDetails = {};
    }

    $onChanges() {
        if (this.currentTreatment && this.currentTreatment.intra && this.currentTreatment.intra.days) {
            this.intraDays = this.currentTreatment.intra.days;
        } else {
            this.intraDays = [];
        }
    }

    onAddClick() {
        if (!this.currentTreatment) {
            return console.error("There is not current treatment");
        }

        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (!accepted) {
                return;
            }

            const request = {
                auth: {
                    pass: authData.pass,
                    user: authData.user
                },
            };

            this.treatmentService.addIntraDay(this.currentTreatment._id, request, (err, result) => {
                if (err) {
                    return console.error(err);
                }

                this.onIntraDaysAdded();

                return console.info(result.data);
            });
        });
    }

    onDeleteClick() {
        if (!this.currentTreatment) {
            return console.error("There is not current treatment");
        }

        this.resetForm();
        this.window.$("#deleteIntraDayModal").modal("toggle");
    }

    resetForm() {
        for (let i = 0; i < this.intraDays.length; i++) {
            this.formIntraDays[this.intraDays[i].day] = false;
        }
    }

    showDayDetail(dayNumber) {
        this.visibleDetails[dayNumber] = !this.visibleDetails[dayNumber];
    }

    confirmDeleteButtonDisabled() {
        return this.getDaysToRemove().length == 0;
    }

    onConfirmDeleteClick() {
        this.window.$("#deleteIntraDayModal").modal("toggle");

        const daysToRemove = this.getDaysToRemove();
        if (!daysToRemove.length) {
            return;
        }

        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (!accepted) {
                this.window.$("#deleteIntraDayModal").modal("toggle");
                return;
            }

            const request = {
                auth: {
                    pass: authData.pass,
                    user: authData.user
                },
                daysToRemove: daysToRemove,
            };

            this.treatmentService.removeIntraDay(this.currentTreatment._id, request, (err, result) => {
                if (err) {
                    return console.error(err);
                }

                this.onIntraDaysRemoved();

                return console.info(result.data);
            });
        });
    }

    hasCurrentTreatmentEnd() {
        return this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    getDaysToRemove() {
        const daysToRemove = [];

        for (var dayNumber in this.formIntraDays) {
            if (this.formIntraDays.hasOwnProperty(dayNumber)
                && this.formIntraDays[dayNumber] === true) {
                daysToRemove.push(parseInt(dayNumber));
            }
        }

        return daysToRemove;
    }

    canBeDeleted(dayNumber) {
        const intraDay = this.intraDays.find(intraDay => intraDay.day == dayNumber);

        return (!intraDay || !this.treatmentUtils.dayHasAnyTakenIntake(intraDay));
    }

    dayHasAnyTakenIntake(intraDay) {
        if (!intraDay.intakes) {
            return false;
        }

        return undefined != intraDay.intakes.find(intake => intake.state == "taken");
    }

}

editIntraPhaseController.$inject = [
    "$window",
    CONFIRMATION_SERVICE,
    TOKEN_SERVICE,
    TREATMENT_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default editIntraPhaseController;
