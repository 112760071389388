import angular from "angular";
import { UTILS_PROTOCOL_SERVICE } from "../utils/utils.protocols";

const PROTOCOL_EDITOR_SERVICE = "protocolEditorService";

var ProtocolEditorService = function (protocolUtil) {
    var protocolEditorService = {};
    let errores = {};
    const protocolUtils = protocolUtil;

    protocolEditorService.validateProtocol = function (protocol) {

        let protocolNorm = angular.copy(protocol);

        let hasError = false;

        errores = {};

        protocolNorm = protocolEditorService.normalizeProtocol(protocolNorm);

        hasError = !protocolEditorService.validateProtocolMinumunSchema(protocolNorm) || hasError;

        hasError = !protocolEditorService.validateIntra(protocolNorm) || hasError;

        hasError = !protocolEditorService.validateWeeks(protocolNorm) || hasError;

        errores.hasError = hasError;

        return errores;

    };

    protocolEditorService.validateProtocolMinumunSchema = function (protocol) {
        if (!protocol.allergen || !protocol.type || !protocol.observationTime || !protocol.selectedUnit) {
            console.log("Protocolo incompleto, revisa la informacion principal");
            errores.global = "- Has rellenado el alergeno, el nombre del protocolo, el tiempo de observacion y has seleccionado unidad para las dosis";
            return false;
        }

        return true;
    };

    protocolEditorService.validateWeeks = function (protocol) {
        if (!protocol.weeks) {
            errores.weeks = "- Has definido la fase de ascensos semanales con al menos una semana.";
            return false;
        }

        const hasWeeksIncompletes = protocol.weeks.find(week => !week.dose);

        const formatErrors = (protocol.selectedUnit == "unidad")
            ? protocol.weeks.filter(week => !protocolUtils.validateFractionedDose(week.dose))
            : protocol.weeks.filter(week => !protocolUtils.validateNormalDose(week.dose) || protocolUtils.validateOnlyFractionedDose(week.dose));

        if (formatErrors.length > 0) {
            errores.weeks = "- No existe ninguna toma en la fase inicial sin valor de dosis y tiempo de observacion.";
            return false;
        }

        if (hasWeeksIncompletes) {
            return false;
        }

        return true;
    };

    protocolEditorService.validateIntra = function (protocol) {
        const intra = protocol.intra;

        if (!intra) {
            return true;
        }

        for (let i = 0; i < intra.days.length; i++) {
            const intakes = protocol.intra.days[i].intakes;

            if (!intakes) {
                return false;
            }

            const incompleteIntakes = intakes.filter(intake => (!intake.dose && intake.observationTime) || (intake.dose && !intake.observationTime));
            const formatErrors = (protocol.selectedUnit == "unidad")
                ? intakes.filter(intake => !protocolUtils.validateFractionedDose(intake.dose))
                : intakes.filter(intake => !protocolUtils.validateNormalDose(intake.dose) || protocolUtils.validateOnlyFractionedDose(intake.dose));

            if (incompleteIntakes.length > 0 || formatErrors.length > 0) {
                errores.intra = "- No existe ninguna toma en la fase inicial sin valor de dosis y tiempo de observacion.";
                return false;
            }
        }

        return true;
    };

    protocolEditorService.normalizeProtocol = function (protocol) {

        protocolEditorService.normalizeIntra(protocol);

        protocolEditorService.normalizeWeeks(protocol);

        return protocol;
    };

    protocolEditorService.normalizeWeeks = function (protocol) {
        if (protocol.weeks) {
            protocol.cleanEmptyWeeks();
            protocol.reIndexWeeks();

            if (protocol.weeks.length == 0) {
                delete protocol.removeWeeks();
            }
        }

        return protocol;
    };

    protocolEditorService.normalizeIntra = function (protocol) {
        protocol.cleanIntra();

        if (protocol.intra.days.length == 0) {
            protocol.removeIntra();
        } else {
            protocol.reIndexIntraDays();
        }
    };

    protocolEditorService.removeControls = function (protocol) {
        if (protocol.weeks) {
            protocol.removeWeeksControls();
        }

        if (protocol.intra) {
            protocol.removeIntraControls();
        }

        return protocol;
    };

    protocolEditorService.validateDose = function (dose, unit) {
        if (unit=="unidad") {
            return protocolUtils.validateFractionedDose(dose);
        } else {
            return protocolUtils.validateNormalDose(dose);
        }
    };

    return protocolEditorService;
};

ProtocolEditorService.$inject = [UTILS_PROTOCOL_SERVICE];

export { PROTOCOL_EDITOR_SERVICE, ProtocolEditorService };
