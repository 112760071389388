import calendarTemplate from "./calendar.template";
import calendarController from "./calendar.controller";

var mainPageStates = function ($stateProvider) {
    $stateProvider.state({
        name: "app.admin-medical.calendar",
        url: "/citas",
        template: calendarTemplate,
        controller: calendarController,
    });
};

mainPageStates.$inject = ["$stateProvider"];

export default mainPageStates;
