import { AUTH_SERVICE } from "services/api-client";
import { TOKEN_SERVICE } from "services/token";

class SignInController {
    constructor($scope, $state, $window, authService, token, __env, $stateParams) {
        $scope.form = {};
        $scope.signInForm;

        $scope.showError = function (msg) {
            $scope.signInError = msg;
        };

        $scope.hideError = function () {
            delete $scope.form;
            delete $scope.signInError;
            $scope.signInForm.$setPristine();
        };

        $scope.submitSignIn = function () {
            $scope.signInForm.$setSubmitted();

            if ($scope.signInForm.$invalid) {
                return;
            }

            $scope.signInForm.$setPristine();

            let user = $scope.form.user;
            let pass = $scope.form.pass;
            authService.signIn(user, pass, function (err) {
                if (err) {
                    if(err.status == 401) return $scope.showError("Usuario o contraseña inválido");
                    if(err.status == 403) return $scope.showError("Su cuenta se encuentra desactivada. Por favor pongase en contacto con el administrador del centro");
                    return $scope.showError("Ha ocurrido un error al intentar identificarte");
                }

                let avaibleStates = __env.avaibleStatesPerRole[token.get("role")];

                if(!avaibleStates){
                    // TODO aqui machacar token
                    return $scope.showError("Rol inválido.");
                }

                if($stateParams.id){
                    $state.go("app.admin-medical.patient-detail",{id:$stateParams.id});
                }
                else{
                    $state.go(avaibleStates[0]);
                }
            });
        };
    }
}

SignInController.$inject = ["$scope", "$state", "$window", AUTH_SERVICE, TOKEN_SERVICE, "__env", "$stateParams"];

export default SignInController;
