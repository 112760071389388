import moment from "moment";

class DayToEdit {

    constructor(dayToEdit, isIntra) {
        this.day = dayToEdit.day;
        this.date = dayToEdit.date;
        this.intakes = dayToEdit.intakes;
        this.discharged = dayToEdit.discharged;
        this.toreview = dayToEdit.toreview;
        this.isIntra = isIntra;
    }

    allIntakesAreTaken() {
        return this.intakes.every(intake => intake.state === "taken");
    }

    getFormattedDate() {
        return moment(this.date).utc().format("YYYY/MM/DD");
    }

}

export default DayToEdit;
