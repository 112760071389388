import angular from "angular";
import "ngstorage";

import authInterceptorConfig from "./auth.interceptor";

const AUTH_INTERCEPTOR_MODULE = "authInterceptorModule";

import { TOKEN_MODULE } from "services/token";

angular.module(AUTH_INTERCEPTOR_MODULE, [
    "ngStorage",
    TOKEN_MODULE
])
    .config(authInterceptorConfig);

export default AUTH_INTERCEPTOR_MODULE;
