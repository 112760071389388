class ComparativeIntake {

    constructor(type, date, state, notes, protocolDose, doctorDose, patientIntake, isExtrahospitalary) {
        this.type = type;
        this.date = date;
        this.state = state;
        this.notes = notes;
        this.protocolDose = protocolDose;
        this.doctorDose = doctorDose ? doctorDose.dose : "No especificada";
        this.patientIntake = patientIntake;
        this.reactions = [];
        this.cofactors = [];
        this.reasons = [];
        this.suggesting = [];
        this.isExtrahospitalary = isExtrahospitalary;
    }

}

export default ComparativeIntake;
