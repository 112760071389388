import "angular-ui-bootstrap";
import moment from "moment";

import { HOSPITALARY_DOSE_SERVICE } from "./hospitalary-dose.service";
import { USERS_SERVICE, DOSE_SERVICE } from "services/api-client";
import { CONFIRMATION_SERVICE } from "services/modal/confirmation";
import { PROTOCOL_MODAL_SERVICE } from "services/modal/protocol";
import { UTILS_PROTOCOL_SERVICE } from "services/utils";
import { UTILS_DATE_SERVICE } from "services/utils";
import { UTILS_NUMBERS_SERVICE } from "services/utils";
import { UTILS_TREATMENT_SERVICE } from "services/utils";

class hospitalaryDoseController {

    constructor(
        hospitalaryDoseService,
        userService,
        confirmationService,
        doseService,
        $protocolModal,
        protocolUtils,
        dateUtils,
        numberUtils,
        treatmentUtils
    ) {
        this.hospitalaryDoseService = hospitalaryDoseService;
        this.userService = userService;
        this.confirmationService = confirmationService;
        this.doseService = doseService;
        this.$protocolModal = $protocolModal;
        this.protocolUtils = protocolUtils;
        this.dateUtils = dateUtils;
        this.numberUtils = numberUtils;
        this.treatmentUtils = treatmentUtils;

        this.editNextDose;
        this.currentDose = undefined;
        this.sectionShadowed = false;
        this.editMode = false;
        this.formIsNotValid = true;
        this.unit = undefined;
        this.sentToIntakeControl = undefined;
        this.canAuthorizeIntake = false;
    }

    $onChanges(changes) {
        if (changes.patient || changes.currentTreatment) {
            this.initPatientData();
        }
    }

    sectionMustBeShadowed() {
        const currentDay = this.treatmentUtils.getTreatmentCurrentDay(this.currentTreatment);
        if (currentDay && currentDay.discharged) {
            return true;
        }

        return !this.patientHasAppointmentToday(this.patient);
    }

    patientHasAppointmentToday(patient) {
        if (patient && patient.appointments && patient.appointments.length > 0) {
            const todayDate = moment.utc(this.dateUtils.getCurrentTs()).startOf("day");
            for (let i = 0; i < patient.appointments.length; i++) {
                const appointment = patient.appointments[i];
                const appointmentDate = moment.utc(appointment.date).startOf("day");

                if (appointmentDate.valueOf() == todayDate.valueOf()) {
                    return true;
                }
            }
        }

        return false;
    }

    initCurrentTreatmentData() {
        this.unit = this.treatmentUtils.getTreatmentUnit(this.currentTreatment);
    }

    submitDoseData() {
        if (!this.editNextDose) {
            return;
        }

        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (accepted) {
                this.addDoctorDose(authData);
            }
        });
    }

    hasTreatmentStartedAndNotEnd() {
        return this.treatmentUtils.treatmentHasStarted(this.currentTreatment) && !this.treatmentUtils.treatmentHasEnd(this.currentTreatment);
    }

    addDoctorDose(authData) {
        let newDose;
        if (this.protocolUtils.validateFractionedDose(this.editNextDose)) {
            newDose = this.editNextDose;
        } else {
            newDose = this.numberUtils.fixToRealPrecission(this.editNextDose, 4);
        }
        const newDoseRequest = {
            auth: {
                pass: authData.pass,
                user: authData.user
            },
            type: this.currentTreatment.type,
            date: this.dateUtils.getCurrentTs(),
            dose: `${newDose} ${this.unit}`,
            doseType: "hospitalary",
            doseAltered: this.currentTreatment.inIntakeControl == 1 && newDose != this.currentDose
        };

        this.doseService.set(this.patient._id, newDoseRequest, (err) => {
            if (err) {
                this.unauthorizedMessage(err.status);
            }

            this.canAuthorizeIntake = true;
            this.sectionShadowed = false;

            this.onPatientRefreshNeeded();
        });
    }
    onNextDoseChanged() {
        if (this.treatmentUtils.getTreatmentUnit(this.currentTreatment) == "unidad") {
            if (this.protocolUtils.validateFractionedDose(this.editNextDose)) {
                this.formIsNotValid = false;
            } else {
                this.formIsNotValid = true;
            }
        } else {
            if (this.protocolUtils.validateNormalDose(this.editNextDose)) {
                this.formIsNotValid = false;
            } else {
                this.formIsNotValid = true;
            }
        }
    }

    enterEditMode() {
        this.editMode = true;
        this.editNextDose = undefined;
        this.$protocolModal.show("Descripción del protocolo", this.currentTreatment);
        this.onEnterEditMode();
    }

    exitEditMode() {
        this.editMode = false;
        this.onExitEditMode();
    }

    intakeNeedsAuthorization() {
        return !this.currentTreatment.inIntakeControl;
    }

    sendToIntakeControl() {
        this.confirmationService.doblePlusMedic((accepted, authData) => {
            if (!accepted) return;

            const request = {
                auth: authData,
                type: this.currentTreatment.type,
            };
            this.userService.sendToIntakeControl(this.patient._id, request, (err) => {
                this.sentToIntakeControl = err ? false : true;
                this.canAuthorizeIntake = err ? true : false;
            });
        });
    }

    endHospitalaryIntake() {
        this.confirmationService.doblePlusMedic((accepted) => {
            if (accepted) {
                this.onIntakeEnd();
            }
        });
    }

    initPatientData() {
        this.initCurrentTreatmentData();
        this.currentDose = this.treatmentUtils.getTreatmentCurrentDose(
            this.currentTreatment,
            "hospitalary");
        this.sectionShadowed = this.sectionMustBeShadowed();
        this.editMode = false;
    }

    unauthorizedMessage(err) {
        if (err == 403) {
            this.confirmationService.error((accepted) => {
                if (accepted) {
                    return;
                } else {
                    return console.error(err);
                }
            });
        } else {
            console.error(err);
        }
    }

}

hospitalaryDoseController.$inject = [
    HOSPITALARY_DOSE_SERVICE,
    USERS_SERVICE,
    CONFIRMATION_SERVICE,
    DOSE_SERVICE,
    PROTOCOL_MODAL_SERVICE,
    UTILS_PROTOCOL_SERVICE,
    UTILS_DATE_SERVICE,
    UTILS_NUMBERS_SERVICE,
    UTILS_TREATMENT_SERVICE,
];

export default hospitalaryDoseController;
